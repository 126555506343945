<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          color="primary"
          class="btn"
          @click="showCreateUserDialog = true"
        >
          Novo Usuário
        </v-btn>
      </template>
    </component-title>

    <user-list-container />

    <create-user
      v-if="showCreateUserDialog"
      @closeDialog="showCreateUserDialog = false"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreateUser from '@/pages/UserList/CreateUser'
import UserListContainer from './UserListContainer'

export default {
  components: {
    ComponentTitle,
    CreateUser,
    UserListContainer,
  },
  data() {
    return {
      showCreateUserDialog: false,
    }
  },
}
</script>
