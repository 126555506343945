<template>
  <content-loader
    :width="600"
    :height="600"
    :speed="4"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <circle
      cx="20"
      cy="20"
      r="20"
    />
    <rect
      x="70"
      y="8"
      rx="0"
      ry="0"
      width="35%"
      height="4%"
    />
    <rect
      x="70"
      y="10%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="18%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="26%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="34%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="42%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="50%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="58%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
    <rect
      x="70"
      y="66%"
      rx="0"
      ry="0"
      width="80%"
      height="6%"
    />
  </content-loader>
</template>

<script>
import {
  ContentLoader,
} from 'vue-content-loader'

export default {
  components: {
    ContentLoader,
  },
}
</script>
