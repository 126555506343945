<template>
  <v-autocomplete
    v-if="filter.multiple"
    v-model="filter.model"
    filled
    hide-details
    :loading="filter.isLoading"
    :label="filter.label"
    :items="filter.items"
    return-object
    multiple
    clearable
    :disabled="filter.disabled"
    :item-text="filter.itemText || 'name'"
    :item-value="filter.itemValue || 'id'"
  >
    <template #selection="{ index }">
      <div v-if="index === 0">
        <div v-if="shouldShowAllSelected(filter.items, filter.model)">
          Todos
        </div>
        <div v-else>
          {{ formatMultipleAutocompleteText(filter) }}
        </div>
      </div>
    </template>
    <template
      v-if="filter.items.length > 0"
      #prepend-item
    >
      <v-checkbox
        class="tw-p-4 tw-m-0"
        hide-details
        :input-value="shouldShowAllSelected(filter.items, filter.model)"
        @click="handleSelectOrRemoveAll(filter.items, filter.model)"
      >
        <template #label>
          <div class="tw-pl-6">
            <span class="tw-text-[#000000de] tw-font-bold">
              Todos
            </span>
          </div>
        </template>
      </v-checkbox>
    </template>
  </v-autocomplete>

  <v-autocomplete
    v-else
    v-model="filter.model"
    filled
    hide-details
    :clearable="filter.clearable ?? true"
    :loading="filter.isLoading"
    :label="filter.label"
    :items="filter.items"
    return-object
    :disabled="filter.disabled"
    :item-text="filter.itemText || 'name'"
    :item-value="filter.itemValue || 'id'"
  />
</template>

<script>
import { equals, length } from 'ramda'

export default {
  name: 'MultipleAutoComplete',
  props: {
    filterObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filter: {
      get() {
        return this.filterObject
      },
      set(value) {
        this.$emit('update:filter', value)
      },
    },
  },
  methods: {
    shouldShowAllSelected(selectedItems, selectedItemsList) {
      return equals(length(selectedItems), length(selectedItemsList))
    },
    formatMultipleAutocompleteText(filterItem) {
      if (equals(length(filterItem.model), 1)) {
        const filterModel = filterItem.model[0]
        return filterModel[filterItem.itemText || 'name'] || filterModel
      }
      return `${length(filterItem.model)} Selecionadas`
    },
    handleSelectOrRemoveAll(selectedItems, selectedItemsList) {
      if (this.shouldShowAllSelected(selectedItems, selectedItemsList)) {
        this.removeAll()
      } else {
        this.selectAll()
      }
    },
    selectAll() {
      this.filter.model = this.filter.items
    },
    removeAll() {
      this.filter.model = []
    },
  },
}
</script>
