<template>
  <div class="tw-relative">
    <v-badge
      :content="scheduleList.count"
      :value="scheduleList.count || 0"
      color="red"
      overlap
    >
      <v-btn
        color="primary"
        class="tw-rounded-lg tw-p-7"
        @click="open()"
      >
        Notificações
      </v-btn>
    </v-badge>
    <v-navigation-drawer
      v-model="isMenuOpen"
      width="600px"
      :class="`tw-pt-3 tw-pb-36`"
      fixed
      right
      temporary
      :style="{ top: parentOffset + 'px' }"
      @input="isMenuOpen = $event"
    >
      <template>
        <div class="tw-flex tw-flex-col tw-px-4">
          <div>
            <div class="tw-text-xl tw-font-bold tw-flex tw-text-green tw-my-4">
              <v-icon
                class="mr-2"
                color="primary"
              >
                mdi-bell
              </v-icon>
              <span>Notificações</span>
              <v-icon
                class="tw-cursor-pointer tw-ml-auto"
                @click="close()"
              >
                mdi-close
              </v-icon>
            </div>
          </div>
          <div>
            <v-select
              v-model="statusFilter"
              :items="statusOptions"
              label="Status"
              item-text="name"
              item-value="value"
              filled
              :loading="isLoading"
              @change="listSchedules()"
            />
          </div>
          <div class="tw-flex-col tw-space-y-4">
            <div
              v-for="schedule in scheduleList.rows"
              :key="schedule.id"
              class="tw-shadow-sm tw-border-gray-light tw-border-solid
              tw-rounded-md tw-border-1 tw-p-3 tw-flex-col"
            >
              <h4 :class="`tw-text-lg tw-font-bold ${eventTextColor(schedule)}`">
                {{ schedule.date | formatDate }} - {{ schedule.patient |
                  formatDisplayName | trimStringBy(36) }}
              </h4>
              <div class="tw-flex tw-flex-row">
                <span class="tw-whitespace-pre-line tw-font-medium">
                  {{ schedule.message }}
                </span>
                <div
                  class="tw-ml-auto tw-flex tw-items-end"
                >
                  <v-btn
                    color="primary"
                    outlined
                    :loading="isLoadingUpdate && isUpdateScheduleId === schedule.id"
                    @click="() => updateScheduleStatus(schedule)"
                  >
                    {{ schedule.status === scheduleStatus.open ? 'Visualizado' : 'Abrir' }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-mt-4">
            <v-pagination
              v-model="page"
              :length="scheduleList.numberOfPages"
              :total-visible="5"
              @input="listSchedules($event)"
            />
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { equals, isNil } from 'ramda'
import { SCHEDULE } from 'amparo-enums'
import { useSound } from '@vueuse/sound'
import notifySound from '../../assets/notify-sound.mp3'

export default {
  name: 'ExamCollectionNotifications',
  setup() {
    const { play } = useSound(notifySound)

    return {
      notify: play,
    }
  },
  props: {
    parentRef: {
      required: true,
    },
  },
  data() {
    return {
      statusFilter: SCHEDULE.status.open,
      statusOptions: [
        {
          name: 'Em Aberto',
          value: SCHEDULE.status.open,
        },
        {
          name: 'Visualizado',
          value: SCHEDULE.status.finished,
        },
      ],
      isMenuOpen: false,
      page: 1,
      getNotificationIntervale: null,
      isLoadingUpdate: false,
      isUpdateScheduleId: null,
      lastScheduleListCount: null,
      lastScheduleListFilters: {},
      filters: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
      scheduleList: 'schedule/scheduleList',
      isLoading: 'schedule/isLoading',
      schedulePage: 'schedule/page',
    }),
    scheduleStatus() {
      return SCHEDULE.status
    },
    parentOffset() {
      if (!this.parentRef) return 0

      const rect = this.parentRef.getBoundingClientRect()
      return rect.top
    },
  },
  watch: {
    isMenuOpen(newValue) {
      if (newValue === false) {
        this.statusFilter = SCHEDULE.status.open
        this.page = 1
        this.listSchedules()
      }
    },
    scheduleList(newValue) {
      if (newValue.count > this.lastScheduleListCount
        && !isNil(this.lastScheduleListCount)
        && equals(this.filters, this.lastScheduleListFilters)) {
        this.notify()
      }
      this.lastScheduleListCount = newValue.count
      this.lastScheduleListFilters = this.filters
    },
  },
  async activated() {
    this.init()
  },
  async mounted() {
    this.init()
  },
  deactivated() {
    clearInterval(this.getNotificationInterval)
  },
  methods: {
    ...mapActions({
      userLogout: 'authentication/userLogout',
      getAnnouncementList: 'announcement/getAnnouncementList',
      setScheduleFilters: 'schedule/setScheduleFilters',
      setSchedulePagination: 'schedule/setSchedulePagination',
      getSchedules: 'schedule/getSchedules',
      updateSchedule: 'schedule/updateSchedule',
    }),
    init() {
      this.listSchedules()
      this.getNotificationInterval = setInterval(() => {
        this.listSchedules()
      }, 10000)
    },
    async updateScheduleStatus(schedule) {
      this.isLoadingUpdate = true
      this.isUpdateScheduleId = schedule.id
      await this.updateSchedule({
        id: schedule.id,
        attributes: {
          status: schedule.status === SCHEDULE.status.open
            ? SCHEDULE.status.finished : SCHEDULE.status.open,
        },
      })
      this.listSchedules()
      this.isLoadingUpdate = false
    },
    eventTextColor(schedule) {
      if (schedule.message.includes('Novo Agendamento')) {
        return 'tw-text-green-dark'
      }
      if (schedule.message.includes('Alteração de Endereço')) {
        return 'tw-text-sky-700'
      }
      if (schedule.message.includes('Reagendamento')) {
        return 'tw-text-yellow-400'
      }
      if (schedule.message.includes('Cancelamento')) {
        return 'tw-text-red-800'
      }
      return ''
    },
    async listSchedules() {
      const order = this.statusFilter === SCHEDULE.status.open
        ? { orderBy: 'createdAt', isDesc: false }
        : { orderBy: 'updatedAt', isDesc: true }

      this.filters = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(2, 'day').format('YYYY-MM-DD'),
        types: [SCHEDULE.types.otherAdministrative.value],
        status: this.statusFilter,
        page: this.page,
        ...order,
      }
      this.setScheduleFilters(this.filters)
      this.getSchedules()
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    open() {
      this.isMenuOpen = true
    },
    close() {
      this.isMenuOpen = false
    },
  },
}
</script>
