<template>
  <div>
    <div class="tw-grid tw-p-4 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-bg-green-light">
      <v-autocomplete
        v-model="filters.selectedClinic"
        :items="clinics"
        item-text="name"
        item-value="id"
        label="Unidade"
        no-data-text="Digite para buscar"
        clearable
        append-icon=""
        filled
        hide-details
        return-object
        data-testid="agenda-settings__clinics--select"
        @change="setFilters(filters)"
      />

      <v-autocomplete
        v-model="filters.selectedSpeciality"
        :items="specialities"
        item-text="name"
        item-value="id"
        filled
        append-icon=""
        hide-details
        clearable
        label="Especialidade"
        no-data-text="Digite para buscar"
        return-object
        @change="setFilters(filters)"
      />

      <search-professional
        :key="`${professionalId}`"
        :professional-id="professionalId"
        :hide-details="true"
        @input="updateProfessional($event)"
      />

      <v-select
        v-model="filters.selectedStatus"
        :items="status"
        :disabled="!hasAllFiltersFilled"
        item-text="text"
        item-value="value"
        filled
        hide-details
        append-icon=""
        clearable
        label="Status"
        @change="setFilters(filters)"
      />
    </div>

    <div
      v-if="!hasFiltersFilled"
      class="tw-m-6 tw-text-2xl tw-font-light"
    >
      Preencha os campos acima para buscar.
    </div>

    <div
      v-else-if="!areFiltersFulfilled"
      class="tw-mt-6"
    >
      <agenda-settings-full-list
        v-if="!loadingAgenda"
        instance-type="agendas"
        @setFilters="setFilters"
      />
    </div>

    <div
      v-else
      class="tw-mx-6"
    >
      <div class="tw-flex tw-my-0 tw-justify-center">
        <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/4">
          <v-btn
            class="tw-font-bold tw-my-6 tw-w-full"
            color="primary"
            large
            data-testid="agenda-settings__new-agenda--button"
            @click="openItemDialog()"
          >
            Novo Item
          </v-btn>
        </div>
      </div>

      <v-data-table
        v-if="!loadingAgenda"
        hide-default-footer
        disable-pagination
        class="data-table__header"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
        :headers="headers"
        :items="availableAgendas"
      >
        <template v-slot:item="agenda">
          <tr>
            <td>{{ agenda.item.dayOfTheWeek | dayOfTheWeek }}</td>
            <td>{{ agenda.item.startDate | formatDate }}</td>
            <td v-if="agenda.item.endDate">
              {{ agenda.item.endDate | formatDate }}
            </td>
            <td v-else>
              -
            </td>
            <td>{{ agenda.item.startTime | removeSecondsFromTime }}</td>
            <td>{{ agenda.item.endTime | removeSecondsFromTime }}</td>
            <td>{{ agenda.item.interval }} minutos</td>
            <td>{{ agenda.item.reserves.length ? agenda.item.reserves[0].name : '' }}</td>
            <td>{{ agenda.item.maxSlots }}</td>
            <td>{{ agenda.item.maxAppointmentsBySlot }}</td>
            <td>
              <div v-if="!isBlockAgenda(agenda.item)">
                <v-btn
                  icon
                  fab
                  @click="openItemDialog(agenda.item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div>
                <v-btn
                  icon
                  fab
                  @click="toggleArchiveAgendaDialog(agenda.item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>

      <agenda-settings-items-pagination
        class="tw-mb-6"
        @changePage="setPage"
      />
    </div>

    <archive-agenda-dialog
      v-if="isArchiveAgendaDialogOpen"
      @archiveAgenda="archiveAgenda"
      @closeArchiveAgendaDialog="toggleArchiveAgendaDialog"
    />

    <v-dialog
      v-model="showDialogRegisterAgendaPattern"
      fullscreen
      transition="dialog-bottom-transition"
      persistent
      @keydown.esc="showDialogRegisterAgendaPattern = false"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title
            v-if="agendaToEdit"
            class="tw-ml-6"
          >
            Editar configurações de agenda
          </v-toolbar-title>
          <v-toolbar-title v-if="!agendaToEdit">
            Nova configuração de agenda
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showDialogRegisterAgendaPattern = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <agenda-register-item
          v-if="showDialogRegisterAgendaPattern"
          :clinic="filters.selectedClinic"
          :speciality="filters.selectedSpeciality"
          :professional="filters.selectedProfessional"
          :agenda-to-edit="agendaToEdit"
          @getAgenda="listAgenda"
          @closeAgendaDialog="closeAgendaDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  all,
  any,
  clone,
  equals,
  isNil,
  lt,
  pick,
  values,
} from 'ramda'

import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import SearchProfessional from '@/components/Common/SearchProfessional'
import AgendaSettingsItemsPagination from './AgendaSettingsItemsPagination'

export default {
  name: 'AgendaSettingsItems',
  components: {
    AgendaRegisterItem: () => import('./AgendaRegisterItem'),
    SearchProfessional,
    AgendaSettingsItemsPagination,
    ArchiveAgendaDialog: () => import('./ArchiveAgendaDialog'),
    AgendaSettingsFullList: () => import('@/pages/AgendaSettings/AgendaSettingsFullList'),
  },
  data: () => ({
    isArchiveAgendaDialogOpen: false,
    agendaToEdit: null,
    itemToArchive: '',
    showDialogRegisterAgendaPattern: false,
    status: [
      {
        value: 'open',
        text: 'Aberto',
      },
      {
        value: 'finished',
        text: 'Finalizado',
      },
    ],
    headers: [
      {
        text: 'Dia da semana',
        value: 'dayOfTheWeek',
      },
      {
        text: 'Início',
        value: 'startDate',
      },
      {
        text: 'Fim',
        value: 'endDate',
      },
      {
        text: 'Hora início',
        value: 'startTime',
      },
      {
        text: 'Hora fim',
        value: 'endTime',
      },
      {
        text: 'Intervalo',
        value: 'interval',
      },
      {
        text: 'Reserva',
        value: 'reserves.name',
      },
      {
        text: 'Estendido',
        value: 'maxSlots',
      },
      {
        text: 'Máx. de agendamentos por horário',
        value: 'maxAppointmentsBySlot',
      },
      {
        text: 'Ação',
        value: 'action',
        sortable: false,
      },
    ],
    filters: {
      selectedClinic: null,
      selectedSpeciality: null,
      selectedProfessional: null,
      selectedStatus: null,
    },
  }),
  computed: {
    ...mapGetters({
      availableAgendas: 'agenda/availableAgendas',
      clinics: 'clinic/clinics',
      loadingAgenda: 'agenda/loadingAgenda',
      page: 'agenda/agendaPage',
      specialities: 'speciality/specialities',
    }),
    areFiltersFulfilled() {
      return !(any(isNil, values(this.filters)))
    },
    hasFiltersFilled() {
      return !(all(isNil, values(this.filters)))
    },
    hasAllFiltersFilled() {
      return !any(
        equals(null),
        values(pick(['selectedClinic', 'selectedSpeciality', 'selectedProfessional'], this.filters)),
      )
    },
    professionalId() {
      return this.filters?.selectedProfessional?.id
    },
  },
  watch: {
    hasAllFiltersFilled(newValue) {
      if (newValue) {
        this.filters.selectedStatus = 'open'
      }
    },
  },
  methods: {
    ...mapActions({
      getProfessional: 'professional/getProfessional',
      listAgenda: 'agenda/listAgenda',
      setAgendaFiltersData: 'agenda/setAgendaFiltersData',
      setAgendaPage: 'agenda/setAgendaPage',
      setSnackbar: 'snackbar/setSnackbar',
      storeAgenda: 'agenda/storeAgenda',
    }),
    async setPage(page) {
      this.setAgendaPage(page)
      await this.listAgenda()
    },
    isBlockAgenda(agenda) {
      return lt(agenda.endDate, moment().format('YYYY-MM-DD'))
    },
    openItemDialog(agenda) {
      this.showDialogRegisterAgendaPattern = true

      if (isNil(agenda)) {
        this.agendaToEdit = {}
        return
      }

      this.agendaToEdit = agenda
    },
    closeAgendaDialog() {
      this.showDialogRegisterAgendaPattern = false
      this.agendaToEdit = null
    },
    toggleArchiveAgendaDialog(item) {
      this.itemToArchive = item
      this.isArchiveAgendaDialogOpen = !this.isArchiveAgendaDialogOpen
    },
    async setFilters(filters) {
      this.filters = filters
      this.setAgendaFiltersData(clone(this.filters))
      await this.listAgenda()
    },
    async archiveAgenda() {
      try {
        await this.storeAgenda(
          {
            id: this.itemToArchive.id,
            attributes: {
              storedAt: moment().format('YYYY-MM-DD HH:mm'),
            },
          },
        )
        await this.listAgenda()
      } catch (error) {
        let message = 'Falha ao arquivar, tente novamente'
        if (equals(error?.response?.data?.errorCode, 'agenda_delete_invalid')) {
          message = 'Existem consultas registradas em períodos futuros'
        }
        this.setSnackbar({ status: 'error', message })
      }
      this.isArchiveAgendaDialogOpen = false
    },
    updateProfessional(event) {
      this.filters.selectedProfessional = event
      this.setFilters(this.filters)
    },
  },
}
</script>

<style lang="stylus" scoped>
.data-table__header >>> .v-data-table-header
  background-color #f6f6f6 !important
  box-shadow 0px 3px 1px rgba(224, 224, 224, 0.9)
  padding 1rem 0 !important
</style>
