<template>
  <header
    ref="componentTitle"
    class="
      tw-flex
      tw-justify-between
      tw-items-center
      tw-px-12
      tw-h-32
      tw-w-full
      tw-relative
      tw-bg-gray-soft-dark"
  >
    <section>
      <h1
        class="title-h2 tw-text-white"
        data-testid="component-title__title--text"
      >
        {{ $route.meta.description }}
      </h1>
      <v-breadcrumbs
        class="breadcrumbs__item print:tw-hidden"
        :items="breadcrumbs"
      >
        <template slot="divider">
          <v-icon class="breadcrumbs__divider">
            mdi-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </section>

    <section class="tw-hidden lg:tw-inline-block print:tw-hidden">
      <slot name="actions" />
    </section>
  </header>
</template>

<script>
import { isEmpty } from 'ramda'

export default {
  name: 'ComponentTitle',
  props: {
    lastBreadcrumb: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    breadcrumbs() {
      const breadcrumb = [{
        text: 'Início',
        to: '/',
      }]
      for (let index = 0; index < this.$route.matched.length; index += 1) {
        breadcrumb.push(
          {
            text: this.$route.matched[index].meta.description,
            to: this.$route.matched[index].path,
          },
        )
      }
      const lastItem = breadcrumb.pop()
      lastItem.disabled = true
      if (this.$route.params.id && !isEmpty(this.lastBreadcrumb)) {
        lastItem.text = this.lastBreadcrumb
      }
      breadcrumb.push(lastItem)
      return breadcrumb
    },
  },
}
</script>

<style lang='stylus' scoped>

.btn.v-btn
  height 56px !important
  border-radius 8px

.v-breadcrumbs.breadcrumbs__item
  padding 6px 0px

.btn__badge
  position absolute
  right 30px

</style>
