<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          color="primary"
          class="btn"
          data-testid="clinic__new-clinic--button"
          @click="showCreateClinicDialog = true"
        >
          Nova Clínica
        </v-btn>
      </template>
    </component-title>
    <clinic-list-container />

    <create-clinic
      v-if="showCreateClinicDialog"
      @closeDialog="closeCreateClinicDialog"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreateClinic from '@/pages/ClinicList/CreateClinic'
import ClinicListContainer from './ClinicListContainer'

export default {
  components: {
    ComponentTitle,
    ClinicListContainer,
    CreateClinic,
  },
  data() {
    return {
      showCreateClinicDialog: false,
    }
  },
  methods: {
    closeCreateClinicDialog() {
      this.showCreateClinicDialog = false
    },
  },
}
</script>
