<template>
  <div>
    <audit-filter @filter="getPatientList($event)" />
    <audit-list
      @audit="auditPatient($event)"
      @update:page="getPatientList($event)"
    />
  </div>
</template>
<script>
import {
  and,
  equals,
  find,
  not,
  omit,
  path,
  propEq,
} from 'ramda'
import moment from 'moment'
import { mapActions } from 'vuex'
import { AUDIT } from 'amparo-enums'
import AuditFilter from './AuditFilter'
import AuditList from './AuditList'

export default {
  components: {
    AuditFilter,
    AuditList,
  },
  mounted() {
    this.getPatientList({})
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      updatePatientRegistration: 'patientRegistration/updatePatientRegistration',
      listPatientRegistration: 'patientRegistration/listPatientRegistration',
    }),
    async auditPatient({ patient, status }) {
      const {
        responsibleHealthInsurancePlanNumber,
        healthMaintenanceOrganizationId: hmoId,
        id,
      } = patient
      if (equals(status, AUDIT.status.approved.value)) {
        if (await this.notFoundResponsible(responsibleHealthInsurancePlanNumber, hmoId)) {
          this.buidlSnackbar('error', 'Reponsável não encontrado')
          return
        }
        this.createPatient(patient)
      }
      this.sendAudit(id, status)
    },
    async responsibleExists(healthInsurancePlanNumber, hmoId) {
      const response = await this.$http.get('/patient', {
        params: {
          healthInsurancePlanNumber,
        },
      })
      const { patients } = response.data
      return find(propEq(hmoId, 'healthMaintenanceOrganizationId'))(patients)
    },
    sendAudit(patientId, status) {
      this.updatePatientRegistration({ id: patientId, attributes: { status } })
        .then(() => {
          this.buidlSnackbar('success', 'Paciente auditado com sucesso!')
          this.getPatientList({})
        })
        .catch(() => {
          this.buidlSnackbar('error', 'Erro ao auditar paciente')
        })
    },
    createPatient(patient) {
      const patientData = omit([
        'id',
        'status',
        'responsibleHealthInsurancePlanNumber',
        'createdAt',
      ], patient)
      this.$http.post('/patient', {
        ...patientData,
        birthDate: moment(patient.birthDate).format('YYYY-MM-DD'),
        needUpdate: true,
      })
    },
    buidlSnackbar(status, message) {
      this.setSnackbar({
        status,
        message,
      })
    },
    async notFoundResponsible(responsibleHealthInsurancePlanNumber, hmoId) {
      return and(
        not(await this.responsibleExists(responsibleHealthInsurancePlanNumber, hmoId)),
        responsibleHealthInsurancePlanNumber,
      )
    },
    async getPatientList({ filter, page }) {
      this.listPatientRegistration({
        status: path(['status'], filter) || AUDIT.status.todo.value,
        page: page || 1,
        ...filter,
      })
    },
  },
}
</script>
