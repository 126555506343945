<template>
  <v-card
    :class="{
      'attendance': true,
      [`attendance__urgency--${attendance.urgency}`]: !attendance.isClosed,
      'attendance--closed': attendance.isClosed,
    }"
  >
    <v-card-title class="attendance__title">
      {{ attendance.name }}
    </v-card-title>
    <v-card-text class="attendance__content">
      <v-layout class="attendance__columns">
        <v-flex class="columns__info">
          <strong class="info__title">Cpf</strong>
          <span>{{ attendance.cpf | formatCpf }}</span>
        </v-flex>
        <v-flex class="columns__info">
          <strong class="info__title">Nascimento</strong>
          <span>{{ attendance.birthDate | formatDate }}</span>
        </v-flex>
        <v-flex class="columns__info">
          <strong class="info__title">sexo biológico</strong>
          <span>
            {{ attendance.biologicalSex | biologicalSex }}
          </span>
        </v-flex>
      </v-layout>
      <v-layout class="attendance__columns">
        <v-flex class="columns__info">
          <strong class="info__title">Entrada</strong>
          <span>{{ attendance.date | dateWithTime }}</span>
        </v-flex>
        <v-flex class="columns__info">
          <strong class="info__title">E-mail</strong>
          <span v-if="attendance.email">{{ attendance.email }}</span>
          <span v-else>Não informado</span>
        </v-flex>
        <v-flex class="columns__info">
          <strong class="info__title">Telefone</strong>
          <span v-if="attendance.telphone">
            {{ attendance.telphone | formatPhone }}
          </span>
          <span v-else>Não informado</span>
        </v-flex>
      </v-layout>
      <v-layout class="attendance__columns">
        <v-flex class="columns__info">
          <strong class="info__title">Motivo</strong>
          <div v-if="attendance.reason">
            <v-tooltip
              v-if="attendance.reason.length > REASON_MAX_LENGTH"
              top
              max-width="300px"
            >
              <template v-slot:activator="{ on }">
                <p
                  class="wrap-breaks"
                  v-on="on"
                >
                  {{ attendance.reason | trimStringBy(REASON_MAX_LENGTH) }}
                </p>
              </template>
              {{ attendance.reason }}
            </v-tooltip>
            <span v-else>{{ attendance.reason }}</span>
          </div>
          <span v-else>Não informado</span>
        </v-flex>
        <v-flex class="columns__info">
          <strong class="info__title">Urgência</strong>
          <span>{{ parseUrgency[attendance.urgency] }}</span>
        </v-flex>
      </v-layout>
      <v-layout class="attendance__columns">
        <strong class="info__title">Outros</strong>
        <span v-if="isDataEmpty">
          Não possui
        </span>
        <span
          v-for="(value, key) in attendance.data"
          :key="key"
        >
          <span>
            {{ key }}: {{ value }}
          </span>
        </span>
      </v-layout>
      <v-layout class="attendance__columns">
        <v-btn
          v-if="attendanceAttemptsLength > 0 && !attendance.isClosed"
          text
          small
          color="japaneseLaurel"
          @click="openHistoryDialog(attendanceAttempts)"
        >
          {{ attendanceAttemptsLength }}  tentativa{{ attendanceAttemptsLength === 1 ? '' : 's' }}
        </v-btn>
        <v-btn
          v-if="attendance.isClosed"
          text
          small
          color="japaneseLaurel"
          @click="openHistoryDialog(attendance.activityLog)"
        >
          Histórico do atendimento
        </v-btn>
        <v-btn
          v-if="shouldShowReopenButton"
          class="btn btn-primary"
          text
          @click="setReopenAttendance"
        >
          Reabrir atendimento
        </v-btn>
        <v-flex
          v-if="!attendance.isClosed"
        >
          <v-btn
            v-if="isBeingAttendedByUser"
            class="btn btn-danger"
            block
            @click="cancelAttendance"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-else
            class="btn btn-primary"
            block
            :disabled="attendanceIsProgress"
            @click="initAttendance"
          >
            Atender
          </v-btn>
        </v-flex>
        <span
          v-if="attendanceIsProgress && !attendance.isClosed"
          class="attendance-owner"
        >
          Sendo atendido por: {{ owner.name }}
        </span>
      </v-layout>
    </v-card-text>
    <v-expand-transition>
      <v-card
        v-show="isBeingAttendedByUser"
        class="attendance__new-attendance"
      >
        <v-layout class="new-attendance__columns">
          <v-flex class="columns__fields">
            <search-patient
              v-model="selectedPatient"
              v-default-patient="selectedPatient"
              label="Vincular paciente"
              append-icon=""
              filled
              @input="updatePatientId($event)"
            />
          </v-flex>
          <v-flex class="columns__fields">
            <v-btn
              class="btn btn-info"
              block
              large
              @click="showCreatePatientDialog = true"
            >
              Cadastrar paciente
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout class="new-attendance__columns">
          <v-flex class="columns__fields">
            <v-text-field
              v-model="note"
              label="Observações"
              clearable
              hint="Opcional"
              persistent-hint
              filled
            />
          </v-flex>
          <v-flex class="columns__fields">
            <v-btn
              class="btn btn-secondary"
              block
              large
              :disabled="!isAbleToGoToAppointment"
              @click="handleGoToAppointment"
            >
              Agendar consulta
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout class="new-attendance__columns">
          <v-flex class="columns__fields">
            <v-select
              v-model="attendanceStatus"
              label="Status de atendimento"
              :items="attendanceStatusOptions"
              item-text="label"
              item-value="value"
              hide-details
              filled
              @change="resetFailAttendanceReason"
            />
          </v-flex>
          <v-flex
            v-if="attendanceStatus === 'failAttendance'"
            class="columns__fields"
          >
            <v-text-field
              v-model="failAttendanceReason"
              label="Motivo de falha"
              hide-details
              clearable
              filled
            />
          </v-flex>
        </v-layout>
        <v-layout class="new-attendance__columns">
          <v-btn
            class="btn btn-primary"
            large
            :disabled="disableSaveAttendance"
            @click="saveAttendance"
          >
            Salvar
          </v-btn>
        </v-layout>
      </v-card>
    </v-expand-transition>
    <v-dialog
      v-model="attendanceHistoryDialog"
      max-width="60%"
    >
      <v-card v-if="attendanceHistoryDialog">
        <v-card-title class="card-dialog__title">
          Histórico de atendimentos
          <v-spacer />
          <v-btn
            icon
            fab
            @click="attendanceHistoryDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-flex
            v-for="(history, index) in historyToShow"
            :key="index"
            :class="{'history-border': shouldShowHistoryItem(history)}"
          >
            <v-flex
              v-if="shouldShowHistoryItem(history)"
              class="history"
            >
              <v-flex class="history__info">
                <strong class="info__title">
                  Atendido por
                </strong>
                <span>{{ history.userName }}</span>
              </v-flex>
              <v-flex class="history__info">
                <strong class="info__title">
                  Data
                </strong>
                <span>{{ history.changedAt | dateWithBrTime }}</span>
              </v-flex>
              <v-flex class="history__info">
                <strong class="info__title">
                  Status de atendimento
                </strong>
                <span>
                  {{
                    translateAttendanceStatus[history.attendanceStatus]
                  }}
                </span>
              </v-flex>
              <v-flex
                class="history__info"
              >
                <strong class="info__title">
                  Observações
                </strong>
                <span>{{ history.note || 'Não possui.' }}</span>
              </v-flex>
            </v-flex>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCreatePatientDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cadastrar Paciente</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showCreatePatientDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <create-patient
          :virtual-reception-id="attendance.id"
          :default-values="defaultValuesCreatePatient"
          @setCreatedPatient="setAttendedPatientToSelectedPatient"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  always,
  and,
  clone,
  converge,
  equals,
  filter,
  head,
  ifElse,
  includes,
  isEmpty,
  isNil,
  length,
  not,
  or,
  path,
  prop,
  reject,
  T,
} from 'ramda'
import getPatient from '@/mixins/searchers/patient'
import SearchPatient from '@/components/Common/SearchPatient'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    CreatePatient: () => import('@/components/CreatePatient'),
    SearchPatient,
  },
  mixins: [getPatient],
  props: {
    attendance: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      REASON_MAX_LENGTH: 95,
      showCreatePatientDialog: false,
      attendanceHistoryDialog: false,
      historyToShow: [],
      note: null,
      selectedPatient: null,
      attendanceStatus: null,
      failAttendanceReason: null,
      attendanceStatusOptions: [
        {
          label: 'Atendido com sucesso',
          value: 'successAttendance',
        },
        {
          label: 'Atendido sem sucesso',
          value: 'failAttendance',
        },
        {
          label: 'Não atendeu',
          value: 'noContact',
        },
      ],
      translateAttendanceStatus: {
        successAttendance: 'Atendido com sucesso',
        failAttendance: 'Atendido sem sucesso',
        noContact: 'Não atendeu',
        cancelAttendance: 'Atendimento cancelado',
      },
      translateError: {
        'Attendance has already been closed': 'Atendimento já está fechado.',
        'Attendance is already in progress': 'Atendimento já está em progresso.',
        'Attendance has already been successfully attended': 'Atendimento já realizado.',
      },
      parseUrgency: {
        1: 'Verde',
        2: 'Amarelo',
        3: 'Vermelho',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
    defaultValuesCreatePatient() {
      if (this.attendance.patientId) return null
      return reject(isNil, {
        name: this.attendance.name,
        cpf: this.attendance.cpf,
        birthDate: moment(this.attendance.birthDate).format('DD/MM/YYYY'),
        telphone: this.attendance.telphone,
        email: this.attendance.email,
        biologicalSex: this.attendance.biologicalSex,
      })
    },
    attendanceAttempts() {
      return filter(
        ({ attendanceStatus }) => equals(attendanceStatus, 'noContact'),
        this.attendance.activityLog,
      )
    },
    attendanceAttemptsLength() {
      return length(this.attendanceAttempts)
    },
    attendanceIsProgress() {
      return and(
        and(
          not(isEmpty(this.owner)),
          not(equals(
            this.owner.id,
            path(['id'], this.user),
          )),
        ),
        not(this.lastAttendanceIsNoContact),
      )
    },
    isBeingAttendedByUser() {
      return and(
        and(
          and(
            not(isEmpty(this.owner)),
            equals(
              this.owner.id,
              path(['id'], this.user),
            ),
          ),
          not(this.lastAttendanceIsNoContact),
        ),
        not(this.attendance.isClosed),
      )
    },
    lastAttendanceIsNoContact() {
      return equals(
        prop('attendanceStatus', head(this.attendance.activityLog)),
        'noContact',
      )
    },
    disableSaveAttendance() {
      return not(and(
        or(not(this.isNullOrEmpty(this.selectedPatient)), equals(this.attendanceStatus, 'noContact')),
        and(
          not(isNil(this.attendanceStatus)),
          ifElse(
            equals('failAttendance'),
            always(not(this.isNullOrEmpty(this.failAttendanceReason))),
            always(T),
          )(this.attendanceStatus),
        ),
      ))
    },
    owner() {
      return this.attendance.owner || {}
    },
    isAbleToGoToAppointment() {
      return not(this.isNullOrEmpty(this.selectedPatient))
    },
    isDataEmpty() {
      return isEmpty(this.attendance.data)
    },
    shouldShowReopenButton() {
      const reopenableStatus = ['cancelAttendance', 'failAttendance']
      return includes(this.attendance.attendanceStatus, reopenableStatus)
    },
  },
  watch: {
    selectedPatient(patient) {
      if (isNil(patient)) {
        this.emitUpdateList()
        this.handleEraseAttendanceData()
      }
    },
  },
  mounted() {
    if (this.isBeingAttendedByUser) {
      this.setAttendedPatientToSelectedPatient()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      setAttendancePatient: 'virtualReception/setAttendancePatient',
      setIsInAttendance: 'virtualReception/setIsInAttendance',
      setAttendanceId: 'virtualReception/setAttendanceId',
      reopenAttendance: 'virtualReception/reopenAttendance',
      eraseAttendanceData: 'virtualReception/eraseAttendanceData',
      goToAppointment: 'virtualReception/goToAppointment',
      updateAttendance: 'virtualReception/updateAttendance',
    }),
    shouldShowHistoryItem(history) {
      return and(
        history.userName,
        this.translateAttendanceStatus[history.attendanceStatus],
      )
    },
    setAttendedPatientToSelectedPatient(createdPatient) {
      if (or(
        and(
          not(isNil(this.attendance.patient)),
          not(this.attendance.isClosed),
        ),
        createdPatient,
      )) {
        const patientToSet = createdPatient || this.attendance.patient
        if (createdPatient) this.updatePatientId(createdPatient)
        this.selectedPatient = clone(patientToSet)
        this.setAttendancePatient(this.selectedPatient)
        this.showCreatePatientDialog = false
      }
    },
    isNullOrEmpty(value) {
      return or(isEmpty(value), isNil(value))
    },
    resetFailAttendanceReason() {
      this.failAttendanceReason = null
    },
    async initAttendance() {
      try {
        await this.handleUpdateAttendance({
          ownerId: this.user.id,
          attendanceStatus: 'inAttendance',
        })
        this.emitUpdateList()
        this.setIsInAttendance(true)
        this.setAttendanceId(this.attendance.id)
        this.setAttendedPatientToSelectedPatient()
      } catch (error) {
        this.buildError(error)
      }
    },
    async setReopenAttendance() {
      try {
        await this.reopenAttendance({
          attendanceId: this.attendance.id,
        })
        this.emitUpdateList()
      } catch (error) {
        this.buildError(error)
      }
    },
    async saveAttendance() {
      const data = {
        note: this.note,
        attendanceStatus: this.attendanceStatus,
        failAttendanceReason: this.failAttendanceReason,
        patientId: path(['id'], this.selectedPatient),
        ownerId: this.user.id,
      }
      const filteredData = reject(converge(or, [isNil, isEmpty]), data)
      try {
        await this.handleUpdateAttendance(filteredData)
        this.emitUpdateList()
        this.buildSnackbar('success', 'Atendimento realizado com sucesso!')
        this.handleEraseAttendanceData()
      } catch (error) {
        this.buildError(error)
      }
    },
    handleUpdateAttendance(data) {
      return this.updateAttendance({
        data,
        attendanceId: this.attendance.id,
      })
    },
    updatePatientId(patient) {
      this.selectedPatient = patient
      this.handleUpdateAttendance({
        patientId: path(['id'], patient),
        ownerId: this.user.id,
      })
    },
    buildError({ response }) {
      const message = equals(response.status, 403)
        ? 'Acesso negado'
        : this.translateError[response.data.errors.attendance] || 'Erro interno, tente novamente.'
      this.buildSnackbar('error', message)
    },
    openHistoryDialog(list) {
      this.attendanceHistoryDialog = true
      this.historyToShow = list
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    emitUpdateList() {
      this.$emit('updateList')
    },
    async cancelAttendance() {
      try {
        await this.handleUpdateAttendance({
          ownerId: null,
          attendanceStatus: null,
        })
        this.emitUpdateList()
        this.handleEraseAttendanceData()
      } catch (error) {
        this.buildError(error)
      }
    },
    handleEraseAttendanceData() {
      this.eraseAttendanceData()
      this.selectedPatient = null
      this.note = null
      this.attendanceStatus = null
      this.failAttendanceReason = null
    },
    handleGoToAppointment() {
      this.goToAppointment({
        attendanceId: this.attendance.id,
        selectedPatient: this.selectedPatient,
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

urgencyBorder(borderColor)
  border-left 8px solid borderColor
  border-radius 7px

.attendance
  width 90%
  min-height 15.375rem
  display flex
  flex-direction column

.attendance--closed
  background-color lighten(thin-gray, 50%)

.attendance__urgency--3
  urgencyBorder($grenadier)

.attendance__urgency--2
  urgencyBorder($rio-grande)

.attendance__urgency--1
  urgencyBorder($japanese-laurel)

.attendance__title
  font-size 1.5rem
  padding-bottom 0

.attendance__content
  display flex
  flex-wrap nowrap
  justify-content space-between

.attendance__columns
  flex-direction column
  flex 1
  padding-right 3rem

.columns__info
  margin-bottom .7rem
  flex-direction column

.info__title
  text-transform uppercase
  letter-spacing .1rem
  font-size .8rem
  color secondary-color

.attendance__new-attendance
  background-color rgba(light-green, .3)
  display flex
  padding-top 1.5rem
  flex-wrap nowrap
  height 12rem

.new-attendance__columns
  display flex
  flex-direction column
  justify-content space-around
  flex 1
  padding-right 3rem
  padding-left @padding-right
  align-items center

.columns__fields
  width 100%

.history-border
  &:not(:last-child)
    border-bottom 1px solid light-gray

.history
  padding 2rem
  width 100%
  display flex

.history__info
  width 25%
  display flex
  flex-direction column

.attendance-owner
  font-size 1.1rem
  color $japanese-laurel
</style>
