<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    persistent
    max-width="1400"
  >
    <v-card>
      <v-card-title class="card-dialog__title">
        Equipe de Saúde
        <v-spacer />
        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="tw-grid tw-grid-cols-12 tw-gap-x-4">
          <v-text-field
            v-model="careTeam.name"
            class="tw-col-span-10"
            filled
            clearable
            label="Nome da equipe"
            :error="$v.careTeam.name.$error"
            @blur="$v.careTeam.name.$touch()"
          />
          <v-checkbox
            v-model="careTeam.isTele"
            class="tw-col-span-2"
            label="Equipe Tele"
            color="green"
          />
          <v-autocomplete
            v-model="careTeam.clinicId"
            class="tw-col-span-4"
            :items="clinics"
            clear-icon=""
            filled
            clearable
            label="Clínica"
            item-text="name"
            item-value="id"
            :error="$v.careTeam.clinicId.$error"
            @blur="$v.careTeam.clinicId.$touch()"
          />
          <v-text-field
            v-maska="['(##) ####-####', '(##) #####-####']"
            :value="applyTelephoneMask(careTeam.telephone)"
            class="tw-col-span-4"
            filled
            clearable
            label="Telefone de contato"
            :error-messages="$v.careTeam.telephone.$error ? 'Número inválido' : ''"
            :error="$v.careTeam.telephone.$error"
            @click:clear="inputTelephone($event.target.value)"
            @maska="inputTelephone($event.target.dataset.maskRawValue)"
            @blur="$v.careTeam.telephone.$touch()"
          />
          <v-text-field
            v-model="careTeam.maxScore"
            class="tw-col-span-4"
            type="number"
            label="Limite de pontos"
            :disabled="!hasAccessToMaxScore"
            filled
            clearable
            :error-messages="$v.careTeam.maxScore.$error ? 'Número inválido' : ''"
            :error="$v.careTeam.maxScore.$error"
            @blur="$v.careTeam.maxScore.$touch()"
          />
          <v-autocomplete
            v-model="selectedProfessionals"
            class="tw-col-span-12"
            :items="professionals"
            :search-input.sync="searchProfessional"
            :disabled="hasSelectedClinic"
            label="Selecione os profissionais"
            clear-icon=""
            append-icon=""
            filled
            clearable
            hide-details
            multiple
            item-text="name"
            return-object
            no-data-text="Digite para buscar"
          >
            <template #prepend-item>
              <v-checkbox
                class="tw-ml-4 tw--mb-2"
                :input-value="shouldShowAllProfessionalsSelected()"
                @click="handleSelectOrRemoveAllProfessionals()"
              >
                <template
                  #label
                >
                  <div
                    class="tw-pl-3"
                  >
                    <span
                      class="tw-font-bold tw-text-[#000000de]"
                    >
                      Todos
                    </span>
                  </div>
                </template>
              </v-checkbox>
            </template>
            <template v-slot:item="{ item, attrs }">
              <v-list-item-content
                class="tw-ml-0"
              >
                <v-list-item-title
                  class="tw-mb-0 tw-pb-0"
                >
                  <v-checkbox
                    class="tw-my-0 tw-py-0 selected-professionals--checkbox"
                    :input-value="attrs.inputValue"
                  >
                    <template
                      #label
                    >
                      <div
                        class="tw-ml-3"
                      >
                        <span>{{ item.name }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="tw-ml-11 tw--mt-4"
                >
                  {{ getSpecialitiesLabel(item.specialities) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <div class="tw-col-span-12 tw-mt-6">
            <div>
              PROFISSIONAIS SELECIONADOS
            </div>
            <v-data-table
              :headers="table.header"
              :items="selectedProfessionals"
              hide-default-header
              no-data-text="Nenhum profissional selecionado"
              class="tw-border-t-[1px] tw-w-full tw-h-[35vh] tw-overflow-auto"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.name }}
                    <v-chip
                      v-if="!isAps(item)"
                      class="tw-text-white tw-ml-2"
                      color="amparo-green"
                      small
                    >
                      Profissional indicado
                    </v-chip>
                  </td>
                  <td>{{ getSpecialitiesLabel(item.specialities) }}</td>
                  <td>
                    <v-icon
                      small
                      @click="removeProfessional(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <div class="tw-col-span-12 tw-pt-6">
            <v-btn
              block
              large
              color="primary"
              class="btn tw-font-bold"
              :loading="loading"
              :disabled="loading"
              @click.stop="sendTeam()"
            >
              {{ update ? 'SALVAR' : 'CRIAR' }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  always,
  cond,
  propEq,
  reject,
  isEmpty,
  map,
  not,
  findIndex,
  join,
  length,
  gt,
  equals,
  T,
} from 'ramda'
import { debounce } from 'lodash'
import {
  required,
  minLength,
  maxLength,
  integer,
  minValue,
} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import isNilOrEmpty from '@/utils/dataValidators'
import { mask } from 'maska'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
    editingTeam: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      careTeam: {
        name: null,
        telephone: null,
        clinicId: null,
        isTele: false,
        professionals: [],
        maxScore: 10000,
      },
      update: false,
      selectedProfessionals: [],
      searchProfessional: null,
      table: {
        header: [
          {
            text: 'Nome',
            value: 'name',
          },
          {
            text: 'Cargo',
            value: 'profession',
          },
          {
            text: 'Remover',
            value: 'action',
            align: 'center',
          },
        ],
      },
    }
  },
  validations() {
    const careTeamValidations = {
      name: { required },
      telephone: {
        minLength: minLength(10),
        maxLength: maxLength(11),
      },
      clinicId: { required },
      maxScore: {
        integer,
        minValue: minValue(0),
      },
    }

    return { careTeam: careTeamValidations }
  },
  computed: {
    ...mapGetters({
      loading: 'careTeam/loading',
      clinics: 'clinic/clinics',
      professionals: 'professional/professionals',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    hasSelectedClinic() {
      return isEmpty(this.careTeam.clinicId)
    },
    hasAccessToMaxScore() {
      return this.userHasAccessToFunctionality.careTeamScore
    },
  },
  watch: {
    searchProfessional: debounce(function searchProfessional(name) {
      if (not(name)) return

      this.listProfessional({ name })
    }, 600),
    'careTeam.clinicId': async function updateProfessionals(clinicId) {
      if (not(clinicId)) return
      await this.listProfessional({ clinicId })
    },
    editingTeam: {
      handler() {
        this.loadEditingCareTeam()
      },
      deep: false,
    },
  },
  async created() {
    this.loadEditingCareTeam()
  },
  methods: {
    ...mapActions({
      createCareTeam: 'careTeam/createCareTeam',
      updateCareTeam: 'careTeam/updateCareTeam',
      updateMaxScoreCareTeam: 'careTeam/updateMaxScoreCareTeam',
      listProfessional: 'professional/listProfessional',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    removeProfessional(professional) {
      this.selectedProfessionals = reject(propEq(professional.id, 'id'), this.selectedProfessionals)
    },
    getSpecialitiesLabel(specialities) {
      return join(' | ', map(speciality => speciality.name, specialities))
    },
    getErrorMessage(error) {
      const errorMessage = cond([
        [
          equals('care_team_name_is_already_in_use'),
          always('Nome já em uso, por favor, escolha outro'),
        ],
        [
          equals('care_team_name_is_already_in_use_by_an_archived_care_team'),
          always('Nome já em uso por equipe arquivada, por favor, escolha outro'),
        ],
        [
          T,
          always('Erro interno'),
        ],
      ])(error?.response?.data?.errorCode)

      return errorMessage
    },
    async sendTeam() {
      const {
        clinicId,
        name,
        telephone,
        isTele,
      } = this.careTeam
      const professionals = map(professional => professional.id, this.selectedProfessionals)
      const attributes = {
        professionals,
        clinicId,
        name,
        isTele,
        telephone,
      }
      this.$v.$touch()
      if (this.$v.$error) {
        this.buildSnackbar('error', 'Verifique os campos em vermelho.')
        return
      }
      try {
        if (this.update) {
          await this.updateCareTeam({ id: this.editingTeam.id, attributes })
        } else {
          const { data: careTeam } = await this.createCareTeam(attributes)
          if (careTeam) this.editingTeam.id = careTeam.id
        }
        if (this.hasAccessToMaxScore) {
          await this.updateMaxScoreCareTeam({
            id: this.editingTeam.id,
            maxScore: Number(this.careTeam.maxScore) * 100,
          })
        }
      } catch (error) {
        this.buildSnackbar('error', this.getErrorMessage(error))
        return
      }
      this.buildSnackbar('success', this.update
        ? 'Equipe de saúde alterada com sucesso!'
        : 'Equipe de saúde criada com sucesso!')
      this.clearFields()
      this.$emit('update')
      this.closeDialog()
    },
    loadEditingCareTeam() {
      this.update = not(isNilOrEmpty(this.editingTeam))
      if (this.update) {
        const {
          professionals,
          name,
          telephone,
          clinic,
          isTele,
          maxScore,
        } = this.editingTeam
        this.selectedProfessionals = professionals
        this.careTeam.name = name
        this.careTeam.telephone = telephone
        this.careTeam.clinicId = clinic.id
        this.careTeam.isTele = isTele
        this.careTeam.maxScore = maxScore / 100
      }
    },
    clearFields() {
      this.selectedProfessionals = []
      this.careTeam = {
        name: null,
        telephone: null,
        clinicId: null,
        maxScore: 10000,
      }
      this.searchProfessional = null
    },
    isAps({ specialities }) {
      return findIndex(propEq(true, 'isAps'))(specialities) !== -1
    },
    applyTelephoneMask(value) {
      if (!value) return null
      return mask(value, gt(length(value), 10) ? '(##) #####-####' : '(##) ####-####')
    },
    inputTelephone(telephone) {
      this.careTeam.telephone = isEmpty(telephone) ? null : telephone
    },
    shouldShowAllProfessionalsSelected() {
      return equals(
        this.selectedProfessionals.length,
        this.professionals.length,
      )
    },
    handleSelectOrRemoveAllProfessionals() {
      if (this.shouldShowAllProfessionalsSelected()) {
        this.removeAllSelectedProfessionals()
      } else {
        this.selectAllProfessionals()
      }
    },
    selectAllProfessionals() {
      this.selectedProfessionals = this.professionals
    },
    removeAllSelectedProfessionals() {
      this.selectedProfessionals = []
    },
  },
}
</script>
<style lang="stylus" scoped>
  .selected-professionals--checkbox
    >>> .v-input__control
      > .v-input__slot
        > .v-input--selection-controls__input
          padding-top 1rem !important
</style>
