<template>
  <v-container
    :class="{
      'container--fluid': true,
      'px-12': $vuetify.breakpoint.smAndUp,
      'grid-list-lg': true
    }"
  >
    <v-form ref="form">
      <v-layout
        row
        wrap
      >
        <v-flex xs8>
          <v-text-field
            v-model="permissionName"
            label="Nome"
            filled
            :error="$v.permissionName.$error"
            @blur="$v.permissionName.$touch()"
          />
        </v-flex>
        <v-flex
          xs2
        >
          <v-text-field
            v-model="permissionSessionTimeoutInMinutes"
            type="number"
            label="Duração da Sessão"
            suffix="minutos"
            filled
            :error="$v.permissionSessionTimeoutInMinutes.$error"
            :error-messages="$v.permissionSessionTimeoutInMinutes.$error ? 'Número inválido' : ''"
            @blur="$v.permissionSessionTimeoutInMinutes.$touch()"
          />
        </v-flex>
        <v-flex
          xs2
        >
          <v-checkbox
            v-model="isExternalUser"
            color="primary"
            label="Usuário Externo"
            hide-details
          />
        </v-flex>
      </v-layout>
      <v-divider />
      <h1 class="title-h3">
        Módulos do Sistema
      </h1>
      <v-layout
        row
        wrap
        mb-6
      >
        <v-flex
          v-for="item of functionalitiesModules"
          :key="item.name"
          xs6
        >
          <v-tooltip right>
            <template #activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-model="selectedModules[item.id]"
                  color="primary"
                  :label="item.name"
                  :disabled="item.isRestricted"
                  hide-details
                />
              </div>
            </template>
            <div
              v-for="(routes, index) in getPermissionRoutesFormatted(item.routes)"
              :key="index"
            >
              {{ routes }}
            </div>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-divider />
      <h1 class="title-h3">
        Gestão do Sistema
      </h1>
      <v-layout
        row
        wrap
        mb-6
      >
        <v-flex
          v-for="item of managementModules"
          :key="item.name"
          xs6
        >
          <v-tooltip right>
            <template #activator="{ on }">
              <div v-on="on">
                <v-checkbox
                  v-model="selectedModules[item.id]"
                  color="primary"
                  :label="item.name"
                  :disabled="item.isRestricted"
                  hide-details
                />
              </div>
            </template>
            <div
              v-for="(routes, index) in getPermissionRoutesFormatted(item.routes)"
              :key="index"
            >
              {{ routes }}
            </div>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-divider />
      <h1 class="title-h3">
        Entidades do Sistema
      </h1>
      <v-layout
        row
        wrap
        mb-6
      >
        <v-flex
          v-for="(item, key) in entities"
          :key="key"
          xs4
        >
          <v-autocomplete
            v-model="item.value"
            :items="item.list"
            :label="item.label"
            item-text="name"
            item-value="id"
            :no-data-text="notFoundMessage"
            placeholder="Nenhum"
            multiple
            filled
          >
            <template #selection="{ index }">
              <v-flex v-if="index === 0">
                <span v-if="item.value.length === item.list.length">
                  Todos
                </span>
                <span v-else>
                  ({{ item.value.length }}/{{ item.list.length }}) Selecionadas
                </span>
              </v-flex>
            </template>
            <template #prepend-item>
              <v-checkbox
                class="ml-4 mb-n2"
                :input-value="item.value.length === item.list.length"
                @click="handleSelectOrRemoveAll(key, item.value.length, item.list.length)"
              >
                <template
                  #label
                >
                  <div
                    class="pl-6"
                  >
                    <span
                      class="auto-complete__prepend-item--checkbox"
                    >
                      Todos
                    </span>
                  </div>
                </template>
              </v-checkbox>
            </template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-divider />
      <v-flex mt-4>
        <v-btn
          v-if="!editPermission"
          block
          large
          color="primary"
          class="btn font-weight-bold"
          :loading="isLoadingPermission"
          :disabled="isLoadingPermission"
          @click="handleSavePermission()"
        >
          Cadastrar Permissão
        </v-btn>
        <v-btn
          v-else
          block
          large
          color="primary"
          class="btn font-weight-bold"
          :loading="isLoadingPermission"
          :disabled="isLoadingPermission"
          @click="handleSavePermission()"
        >
          Salvar Alterações
        </v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import {
  append,
  ascend,
  clone,
  equals,
  filter,
  identity,
  includes,
  intersection,
  isEmpty,
  join,
  map,
  prop,
  propEq,
  sort,
} from 'ramda'
import {
  integer,
  required,
  minLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import scheduleEnums from '@/enums/schedule'

export default {
  name: 'CreatePermission',
  props: {
    editPermission: Boolean,
    permissionData: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
        policy: {
          dataAccess: {
            clinicId: [],
            hmoId: [],
            specilityId: [],
            enterpriseId: [],
          },
          routes: [],
          userInformation: {},
        },
        modules: [],
      }),
    },
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      entities: {
        clinic: {
          label: 'Clínicas',
          list: [],
          value: [],
        },
        hmo: {
          label: 'HMOs',
          list: [],
          value: [],
        },
        speciality: {
          label: 'Especialidades',
          list: [],
          value: [],
        },
        enterprise: {
          label: 'Empresas',
          list: [],
          value: [],
        },
        permission: {
          label: 'Permissões para atribuir em novos usuários',
          list: [],
          value: [],
        },
        schedule: {
          label: 'Tarefas',
          list: [
            {
              name: 'Tarefas Assistenciais',
              id: 'assistance',
            },
            {
              name: 'Tarefas Administrativas',
              id: 'administrative',
            },
          ],
          value: [],
        },
      },
      permissionName: '',
      permissionSessionTimeoutInMinutes: 15,
      isExternalUser: false,
      policyNames: null,
      selectedModules: {},
    }
  },
  validations() {
    const permissionValidations = {
      permissionName: {
        required,
        minLength: minLength(3),
      },
      permissionSessionTimeoutInMinutes: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(43800),
      },
    }
    return { ...permissionValidations }
  },
  computed: {
    ...mapGetters({
      isLoadingPermission: 'authentication/loadingPermission',
      isLoadingModules: 'authentication/loadingModules',
      modules: 'authentication/modules',
    }),
    functionalitiesModules() {
      return filter(
        propEq('functionality', 'type'),
        this.modules,
      )
    },
    managementModules() {
      return filter(
        propEq('system-configuration', 'type'),
        this.modules,
      )
    },
    selectedModulesArray() {
      const modules = []
      for (const module in this.selectedModules) {
        if (this.selectedModules[module]) {
          modules.push(module)
        }
      }
      return modules
    },
  },
  async mounted() {
    this.getClinics()
    this.getHMO()
    this.getSpeciality()
    this.getEnterprise()
    this.getPermission()
    await this.listModules()
    if (this.editPermission) {
      this.fillIsExternalUser()
      this.fillSelectedModules()
      this.fillPermissionDataAccess()
    }
  },
  deactivated() {
    this.resetFields()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      listSpeciality: 'speciality/listSpeciality',
      listEnterprise: 'enterprise/listEnterprise',
      setSnackbar: 'snackbar/setSnackbar',
      createPermission: 'authentication/createPermission',
      updatePermission: 'authentication/updatePermission',
      listPermission: 'authentication/listPermission',
      listModules: 'authentication/listModules',
    }),
    getPermissionRoutesFormatted(routes) {
      const routesFormatted = map(
        route => `${route.route} - [${join(', ', route.methods)}]`,
        routes,
      )
      const sortedRoutes = sort(ascend(identity), routesFormatted)

      return sortedRoutes
    },
    handleSelectOrRemoveAll(key, selectedItems, selectedItemsList) {
      if (equals(selectedItems, selectedItemsList)) {
        this.removeAll(key)
      } else {
        this.selectAll(key)
      }
    },
    selectAll(item) {
      this.entities[item].value = map(prop('id'), this.entities[item].list)
    },
    removeAll(item) {
      this.entities[item].value = []
    },
    showSnackbar(status, message) {
      this.setSnackbar({
        status,
        message,
      })
    },
    fillPermissionDataAccess() {
      this.permissionName = this.permissionData.name
      this.permissionSessionTimeoutInMinutes = this.permissionData.sessionTimeoutInMinutes
      const { dataAccess } = this.permissionData.policy
      this.policyNames = this.permissionData.policyNames

      this.entities.clinic.value = dataAccess.clinicId
      this.entities.hmo.value = dataAccess.hmoId
      this.entities.speciality.value = dataAccess.specialityId
      this.entities.enterprise.value = dataAccess.enterpriseId
      this.entities.permission.value = dataAccess.permissionId
      const hasAssistanceSchedule = !isEmpty(
        intersection(dataAccess.scheduleType, scheduleEnums.assistanceTypes),
      )
      const hasAdministrativeSchedule = !isEmpty(
        intersection(dataAccess.scheduleType, scheduleEnums.administrativeTypes),
      )
      if (hasAssistanceSchedule) {
        this.entities.schedule.value = append('assistance', this.entities.schedule.value)
      }
      if (hasAdministrativeSchedule) {
        this.entities.schedule.value = append('administrative', this.entities.schedule.value)
      }
    },
    fillSelectedModules() {
      const permission = this.permissionData
      for (const { id } of permission.modules) {
        this.selectedModules[id] = true
      }
    },
    fillIsExternalUser() {
      this.isExternalUser = this.permissionData.policy.userInformation.isExternalUser
    },
    async handleSavePermission() {
      this.$v.$touch()
      if (this.$v.$error) {
        const msg = 'Verifique os campos em vermelho.'
        this.showSnackbar('error', msg)
        return
      }

      try {
        if (this.editPermission) {
          await this.updatePermission({
            id: this.permissionData.id,
            attributes: this.buildPermissionAttributes(),
          })
          this.$emit('exitEditDialog')
        } else {
          await this.createPermission(this.buildPermissionAttributes())
          this.$emit('closeCreatePermissionDialog')
          this.resetFields()
        }
        this.showSnackbar('success', 'Permissão salva com sucesso!')
        this.listPermission()
      } catch (error) {
        this.showErrorSnackBar()
      }
    },
    buildPermissionAttributes() {
      const scheduleType = []
      if (includes('assistance', this.entities.schedule.value)) {
        scheduleType.push(...scheduleEnums.assistanceTypes)
      }
      if (includes('administrative', this.entities.schedule.value)) {
        scheduleType.push(...scheduleEnums.administrativeTypes)
      }

      return {
        name: this.permissionName,
        policy: {
          routes: this.permissionData.policy.routes,
          dataAccess: {
            clinicId: this.entities.clinic.value,
            hmoId: this.entities.hmo.value,
            specialityId: this.entities.speciality.value,
            enterpriseId: this.entities.enterprise.value,
            permissionId: this.entities.permission.value,
            scheduleType,
          },
          userInformation: {
            isExternalUser: this.isExternalUser,
          },
        },
        sessionTimeoutInMinutes: this.permissionSessionTimeoutInMinutes,
        moduleIds: this.selectedModulesArray,
      }
    },
    showErrorSnackBar() {
      const message = 'Erro ao cadastrar permissão.'
      this.showSnackbar('error', message)
      this.isSaving = false
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$v.$reset()
    },
    async getClinics() {
      this.entities.clinic.list = [
        ...(await this.listClinic()),
        { id: null, name: 'Nenhuma' },
      ]
    },
    async getHMO() {
      this.entities.hmo.list = [
        ...(await this.listHmo()),
        { id: null, name: 'Nenhum' },
      ]
    },
    async getSpeciality() {
      this.entities.speciality.list = await this.listSpeciality()
    },
    async getEnterprise() {
      this.entities.enterprise.list = clone(await this.listEnterprise())
      this.entities.enterprise.list.push({
        id: null,
        name: 'Nenhuma',
      })
    },
    async getPermission() {
      this.entities.permission.list = await this.listPermission()
    },
  },
}
</script>

<style lang='stylus' scoped>
.v-btn--small
  height 36px

.v-btn--large
  height 56px
  border-radius 8px

h1
  margin-top 24px
  margin-bottom 16px

.auto-complete__prepend-item--checkbox
  font-weight 700
  color #000000de

</style>
