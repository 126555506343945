<template>
  <div>
    <component-title>
      <template #actions>
        <json-csv
          :data="formatJsonToCsv(list.items, list.header)"
          :name="'atendimentos.csv'"
          label="Exportar Relatório"
        />
      </template>
    </component-title>
    <report-filters @filter="loadList($event)" />
    <report-list
      :headers="list.header"
      :items="list.items"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import moment from 'moment'
import JsonCsv from '@/components/Common/JsonCsv'
import ReportFilters from './ReportsAttendanceFilter'
import ReportList from './ReportsAttendanceList'

export default {
  name: 'ReportAttendance',
  components: {
    ComponentTitle,
    ReportFilters,
    ReportList,
    JsonCsv,
  },
  data: () => ({
    list: {
      header: [
        { text: 'Nome do Paciente', value: 'patientName' },
        { text: 'Data do Atendimento', value: 'attendanceDate' },
        { text: 'Nº Carteirinha', value: 'healthInsurancePlanNumber' },
        { text: 'Data de Nascimento', value: 'birthDate' },
        { text: 'Sexo', value: 'biologicalSex' },
        { text: 'Profissional', value: 'professionalName' },
        { text: 'Especialidade', value: 'specialityName' },
        { text: 'Plano de Saúde', value: 'healthInsurancePlan' },
        { text: 'Clínica', value: 'clinicName' },
      ],
      items: [],
    },
  }),
  methods: {
    async loadList(event) {
      this.list.items = await this.formatTable(await this.$http.get('/report/attendance', {
        params: {
          end: event.range.end,
          start: event.range.start,
          professionalId: event.professionalId,
        },
      }).then(res => res.data.appointments))
    },
    async formatTable(data) {
      const bodyTable = []
      if (Array.isArray(data)) {
        data.forEach((row) => {
          bodyTable.push({
            patientName: this.$filters.formatDisplayName(row.patient),
            attendanceDate: moment(row.date).format('DD/MM/YYYY'),
            healthInsurancePlanNumber: row.patient.healthInsurancePlanNumber,
            birthDate: moment(row.patient.birthDate).format('DD/MM/YYYY'),
            biologicalSex: row.patient.biologicalSex,
            professionalName: row.professional.name,
            specialityName: row.speciality.name,
            healthInsurancePlan: row.patient.healthMaintenanceOrganization.name,
            clinicName: row.clinic.name,
          })
        })
      }
      return bodyTable
    },
    formatJsonToCsv(items, headers) {
      const jsonReport = []
      if (items.length) {
        items.forEach((item) => {
          const objItem = {}
          headers.forEach((header) => {
            objItem[header.text] = item[header.value]
          })
          jsonReport.push(objItem)
        })
      }
      return jsonReport
    },
  },
}
</script>
