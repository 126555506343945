<template>
  <v-dialog
    max-width="1125"
    persistent
    transition="dialog-bottom-transition"
    :value="true"
  >
    <v-card>
      <v-container
        class="px-6 py-12"
        fluid
      >
        <v-row>
          <v-col
            class="hmo-title__container"
          >
            <h2>{{ handleModalTitleName() }}</h2>
          </v-col>

          <v-col
            cols="1"
            class="d-flex justify-end"
          >
            <v-btn
              icon
              @click="closeModal"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="mt-0"
        >
          <v-col>
            <v-form ref="form">
              <v-row>
                <v-col
                  class="hmo-sub-section__title__container"
                >
                  <h4>Dados do plano de saúde</h4>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  cols="4"
                >
                  <v-text-field
                    v-model="hmo.name"
                    label="Nome"
                    clearable
                    filled
                    data-testid="hmo__name--field"
                    :error="$v.hmo.name.$error"
                    @blur="$v.hmo.name.$touch()"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field
                    v-model="hmo.nickName"
                    label="Apelido"
                    clearable
                    filled
                    data-testid="hmo__nick-name--field"
                    :error="$v.hmo.nickName.$error"
                    @blur="$v.hmo.nickName.$touch()"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-select
                    v-model="hmo.type"
                    label="Tipo"
                    filled
                    data-testid="hmo__type--select"
                    :items="formOptions.types"
                    :no-data-text="notFoundMessage"
                    :error="$v.hmo.type.$error"
                    @blur="$v.hmo.type.$touch()"
                  />
                </v-col>
              </v-row>

              <div v-if="isWorkSpaceAmparo">
                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Unidades elegíveis</h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="hmo.coreAccreditation.clinics"
                      :disabled="isAgendaClinicsPermissionsNull"
                      :items="clinics"
                      item-text="name"
                      item-value="id"
                      label="Unidades Agenda"
                      no-data-text="Digite para buscar"
                      append-icon=""
                      clearable
                      filled
                      multiple
                    >
                      <template
                        #selection="{ index }"
                      >
                        <v-flex v-if="index === 0">
                          <span
                            v-if="shouldShowAllSelected(
                              hmo.coreAccreditation.clinics, clinics
                            )"
                          >
                            Todos
                          </span>
                          <span v-else>
                            {{ handleAutocompleteText(hmo.coreAccreditation.clinics, clinics, 4) }}
                          </span>
                        </v-flex>
                      </template>
                      <template #prepend-item>
                        <v-checkbox
                          class="ml-4 mb-n2"
                          :input-value="shouldShowAllSelected(
                            hmo.coreAccreditation.clinics, clinics
                          )"
                          @click="handleSelectOrRemoveAllAccreditationValues(
                            hmo.coreAccreditation.clinics,
                            clinics,
                            ['coreAccreditation', 'clinics'],
                          )"
                        >
                          <template
                            #label
                          >
                            <div
                              class="pl-6"
                            >
                              <span
                                class="auto-complete__prepend-item--checkbox"
                              >
                                Todos
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </template>
                    </v-autocomplete>
                    <v-checkbox
                      v-model="isAgendaClinicsPermissionsNull"
                      class="mt-0 pt-0 pl-3"
                      label="Habilitar todos"
                      color="green"
                      data-testid="hmo__core-accreditation-clinics-all--checkbox"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="hmo.portalAccreditation.clinics"
                      :items="clinics"
                      :disabled="isPortalClinicsPermissionsNull"
                      item-text="name"
                      item-value="id"
                      label="Unidades Portal"
                      no-data-text="Digite para buscar"
                      append-icon=""
                      clearable
                      filled
                      multiple
                    >
                      <template
                        #selection="{ index }"
                      >
                        <v-flex v-if="index === 0">
                          <span
                            v-if="shouldShowAllSelected(
                              hmo.portalAccreditation.clinics, clinics
                            )"
                          >
                            Todos
                          </span>
                          <span v-else>
                            {{
                              handleAutocompleteText(hmo.portalAccreditation.clinics, clinics, 4)
                            }}
                          </span>
                        </v-flex>
                      </template>
                      <template #prepend-item>
                        <v-checkbox
                          class="ml-4 mb-n2"
                          :input-value="shouldShowAllSelected(
                            hmo.portalAccreditation.clinics, clinics
                          )"
                          @click="handleSelectOrRemoveAllAccreditationValues(
                            hmo.portalAccreditation.clinics,
                            clinics,
                            ['portalAccreditation', 'clinics'],
                          )"
                        >
                          <template
                            #label
                          >
                            <div
                              class="pl-6"
                            >
                              <span
                                class="auto-complete__prepend-item--checkbox"
                              >
                                Todos
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </template>
                    </v-autocomplete>
                    <v-checkbox
                      v-model="isPortalClinicsPermissionsNull"
                      class="mt-0 pt-0 pl-3"
                      label="Habilitar todos"
                      color="green"
                      data-testid="hmo__portal-accreditation-clinics-all--checkbox"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>
                      Especialidades habilitadas para envio de link de consulta
                    </h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0 pt-0"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="hmo.videoAccreditation.specialities"
                      :items="allSpecialities"
                      :disabled="isSpecialityVideoPermissionsNull"
                      item-text="name"
                      item-value="id"
                      label="Buscar especialidade"
                      no-data-text="Digite para buscar"
                      append-icon=""
                      clearable
                      filled
                      multiple
                    >
                      <template
                        #selection="{ index }"
                      >
                        <v-flex v-if="index === 0">
                          <span
                            v-if="shouldShowAllSelected(
                              hmo.videoAccreditation.specialities, allSpecialities
                            )"
                          >
                            Todos
                          </span>
                          <span v-else>
                            {{ handleAutocompleteText(
                              hmo.videoAccreditation.specialities, allSpecialities, 7
                            ) }}
                          </span>
                        </v-flex>
                      </template>
                      <template #prepend-item>
                        <v-checkbox
                          class="ml-4 mb-n2"
                          :input-value="shouldShowAllSelected(
                            hmo.videoAccreditation.specialities, allSpecialities
                          )"
                          @click="handleSelectOrRemoveAllAccreditationValues(
                            hmo.videoAccreditation.specialities,
                            allSpecialities,
                            ['videoAccreditation', 'specialities'],
                          )"
                        >
                          <template
                            #label
                          >
                            <div
                              class="pl-6"
                            >
                              <span
                                class="auto-complete__prepend-item--checkbox"
                              >
                                Todos
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </template>
                    </v-autocomplete>
                    <v-checkbox
                      v-model="isSpecialityVideoPermissionsNull"
                      class="mt-0 pt-0 pl-3"
                      label="Habilitar todos"
                      color="green"
                      data-testid="hmo__video-accreditation-specialities-all--checkbox"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>
                      Profissionais elegíveis
                    </h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0 pt-0"
                >
                  <v-col>
                    <v-autocomplete
                      v-model="hmo.coreAccreditation.professionals"
                      :items="sortedProfessionals"
                      :disabled="isProfessionalPermissionsNull"
                      item-text="name"
                      item-value="id"
                      label="Profissionais Agenda"
                      no-data-text="Digite para buscar"
                      append-icon=""
                      clearable
                      filled
                      multiple
                    >
                      <template
                        #selection="{ index }"
                      >
                        <v-flex v-if="index === 0">
                          <span
                            v-if="shouldShowAllSelected(
                              hmo.coreAccreditation.professionals, professionals
                            )"
                          >
                            Todos
                          </span>
                          <span v-else>
                            {{ handleAutocompleteText(
                              hmo.coreAccreditation.professionals, professionals, 7
                            ) }}
                          </span>
                        </v-flex>
                      </template>
                      <template #prepend-item>
                        <v-checkbox
                          class="ml-4 mb-n2"
                          :input-value="shouldShowAllSelected(
                            hmo.coreAccreditation.professionals, professionals
                          )"
                          @click="handleSelectOrRemoveAllAccreditationValues(
                            hmo.coreAccreditation.professionals,
                            professionals,
                            ['coreAccreditation', 'professionals'],
                          )"
                        >
                          <template
                            #label
                          >
                            <div
                              class="pl-6"
                            >
                              <span
                                class="auto-complete__prepend-item--checkbox"
                              >
                                Todos
                              </span>
                            </div>
                          </template>
                        </v-checkbox>
                      </template>
                    </v-autocomplete>
                    <v-checkbox
                      v-model="isProfessionalPermissionsNull"
                      class="mt-0 pt-0 pl-3"
                      label="Habilitar todos"
                      color="green"
                      data-testid="hmo__core-accreditation-professionals-all--checkbox"
                    />
                  </v-col>
                </v-row>
              </div>

              <v-row>
                <v-col
                  class="hmo-sub-section__title__container"
                >
                  <h4>Aviso Personalizado</h4>
                </v-col>
              </v-row>

              <v-row
                class="mt-0 pt-0"
              >
                <v-col>
                  <v-textarea
                    v-model="hmo.warningMessage"
                    auto-grow
                    clearable
                    filled
                    label="Descrição"
                    rows="4"
                    :error="$v.hmo.warningMessage.$error"
                    @blur="$v.hmo.warningMessage.$touch()"
                  />
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="hmo-sub-section__title__container"
                >
                  <h4>Plano</h4>
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col>
                  <v-select
                    v-model="hmo.packageType"
                    label="Plano"
                    :items="packageTypes"
                    item-text="label"
                    item-value="value"
                    :no-data-text="notFoundMessage"
                    filled
                    :error="$v.hmo.packageType.$error"
                    @blur="$v.hmo.packageType.$touch()"
                  />
                </v-col>
              </v-row>

              <v-row
                class="mt-0"
              >
                <v-col
                  class="hmo-sub-section__title__container"
                >
                  <h4>Outros dados</h4>
                  <h5>Opcional</h5>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="hmo.attendanceTelephone"
                    v-maska="'(##) ####-####'"
                    label="Telefone da Central Atendimento"
                    hint="Opcional"
                    persistent-hint
                    clearable
                    filled
                    :error-messages="telephoneCustomMessageError"
                    :error="$v.hmo.attendanceTelephone.$error"
                    @blur="$v.hmo.attendanceTelephone.$touch()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="hmo.administrativeTelephone"
                    v-maska="'(##) ####-####'"
                    label="Telefone Administrativo"
                    hint="Opcional"
                    persistent-hint
                    clearable
                    filled
                    :error-messages="telephoneCustomMessageError"
                    :error="$v.hmo.administrativeTelephone.$error"
                    @blur="$v.hmo.administrativeTelephone.$touch()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="hmo.planNumberLength"
                    v-maska="'##'"
                    label="Nº de caracteres da carteirinha"
                    hint="Opcional"
                    persistent-hint
                    clearable
                    filled
                    :error-messages="hmoPlanMessageCustomMessageError"
                    :error="$v.hmo.planNumberLength.$error"
                    @blur="$v.hmo.planNumberLength.$touch()"
                  />
                </v-col>
              </v-row>

              <v-row v-if="isWorkSpaceAmparo">
                <v-col
                  class="hmo-sub-section__title__container d-flex"
                >
                  <h4>Calendário de Faturamento</h4>
                  <v-tooltip
                    right
                    max-width="450"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="cursor--pointer amparo-light-green--text ml-2"
                        size="20"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>
                      As faturas serão criadas automaticamente pelo sistema
                      com 7 dias de antecedência
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  cols="4"
                >
                  <day-picker
                    v-model="hmo.billDays"
                    label="Selecionar datas"
                  />
                </v-col>
              </v-row>

              <div>
                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container d-flex"
                  >
                    <h4>Possui APS (Atenção Primária à Saúde)</h4>
                    <v-tooltip
                      right
                      max-width="450"
                    >
                      <template #activator="{ on }">
                        <v-icon
                          class="cursor--pointer amparo-light-green--text ml-2"
                          size="20"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>
                        Habilita comunicações no Meu Amparo e no consultório virtual
                        a fim de engajar o paciente
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col>
                    <v-radio-group
                      v-model="hmo.hasAps"
                      class="mt-0"
                      dense
                      mandatory
                      row
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>

              <div>
                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Pode logar no Meu Amparo</h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="mt-0"
                  >
                    <v-radio-group
                      v-model="hmo.allowPortalLogin"
                      class="mt-0"
                      row
                      dense
                      mandatory
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>

              <div v-if="isWorkSpaceAmparo && hmo.allowPortalLogin">
                <v-row>
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Possui autocadastro no Meu Amparo</h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="mt-0"
                  >
                    <v-radio-group
                      v-model="hmo.selfRegistration"
                      class="mt-0"
                      row
                      dense
                      mandatory
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>

              <div v-if="isWorkSpaceAmparo">
                <v-row
                  v-if="!isNonePackageHmo"
                  class="mt-0"
                >
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Pode receber comunicações via amparo.care</h4>
                  </v-col>
                </v-row>

                <v-row
                  v-if="!isNonePackageHmo"
                  class="mt-0"
                >
                  <v-col
                    class="mt-0"
                  >
                    <v-radio-group
                      v-model="hmo.isCommunicationAllowed"
                      class="mt-0"
                      row
                      dense
                      mandatory
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Possui consulta de elegibilidade</h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="mt-0"
                  >
                    <v-radio-group
                      v-model="hmo.hasEligibilityCheck"
                      class="mt-0"
                      row
                      dense
                      mandatory
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="hmo-sub-section__title__container"
                  >
                    <h4>Exige autorização da Operadora?</h4>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-0"
                >
                  <v-col
                    class="mt-0"
                  >
                    <v-radio-group
                      v-model="hmo.hasAuthorization"
                      class="mt-0"
                      row
                      dense
                      mandatory
                    >
                      <v-radio
                        color="black"
                        label="Sim"
                        :value="true"
                      />
                      <v-radio
                        color="black"
                        label="Não"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>

              <v-row
                justify="end"
              >
                <v-col
                  cols="4"
                  class="d-flex justify-end"
                >
                  <v-btn
                    class="btn-dialog mr-3"
                    text
                    outlined
                    @click="closeModal"
                  >
                    <span class="cancel-btn--text">Cancelar</span>
                  </v-btn>

                  <v-btn
                    v-if="!editHmo"
                    color="primary"
                    class="btn font-weight-bold"
                    data-testid="hmo__create--button"
                    :loading="loading"
                    :disabled="loading"
                    @click="handleSaveHmo(hmo)"
                  >
                    Cadastrar Plano de Saúde
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    class="btn font-weight-bold"
                    data-testid="hmo__update--button"
                    :loading="loading"
                    :disabled="loading"
                    @click="handleSaveHmo(hmo)"
                  >
                    Salvar Alterações
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <change-hmo-package-type-warning
      v-if="changeHmoPackageTypeWarningIsOpen"
      @close="closeChangeHmoPackageTypeWarningIsOpen"
    />
  </v-dialog>
</template>

<script>
import {
  HMO,
  SPECIALITY,
} from 'amparo-enums'
import {
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import axios from '@/lib/axios'
import { mapActions, mapGetters } from 'vuex'
import {
  addIndex,
  any,
  assocPath,
  clone,
  dissoc,
  equals,
  find,
  includes,
  isEmpty,
  isNil,
  length,
  map,
  mergeRight,
  omit,
  partition,
  path,
  prop,
  reduce,
  reject,
  values,
} from 'ramda'

export default {
  name: 'CreateHmo',
  components: {
    ChangeHmoPackageTypeWarning: () => import('./ChangeHmoPackageTypeWarning'),
    DayPicker: () => import('@/components/Common/DayPicker'),
  },
  props: {
    editHmo: Boolean,
    hmoData: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
        nickName: '',
        type: '',
        attendanceTelephone: '',
        administrativeTelephone: '',
        validationRules: null,
        warningMessage: null,
        allowPortalLogin: true,
        selfRegistration: true,
        isCommunicationAllowed: false,
        hasAps: false,
        packageType: path(['package', 'amparoTotal', 'value'], HMO),
        hasAuthorization: false,
        billDays: [],
      }),
    },
    hmoListFilter: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      isProfessionalPermissionsNull: false,
      isSpecialityVideoPermissionsNull: false,
      isAgendaClinicsPermissionsNull: false,
      isPortalClinicsPermissionsNull: false,
      professionals: [],
      hmo: {
        name: '',
        nickName: '',
        type: '',
        portalAccreditation: {
          clinics: [],
        },
        coreAccreditation: {
          clinics: [],
          professionals: [],
        },
        videoAccreditation: {
          specialities: [],
        },
        warningMessage: '',
        packageType: path(['package', 'amparoTotal', 'value'], HMO),
        attendanceTelephone: '',
        administrativeTelephone: '',
        planNumberLength: '',
        allowPortalLogin: true,
        selfRegistration: true,
        isCommunicationAllowed: false,
        hasEligibilityCheck: false,
        hasAuthorization: false,
        billDays: [],
      },
      existCmIntegration: false,
      formOptions: {
        types: [
          'Tele',
          'Físico',
          'Tele+Físico',
          'Sem agendamento',
        ],
      },
      packageTypes: values(dissoc('amparoTotalAndConnected', HMO.package)),
      changeHmoPackageTypeWarningIsOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      loading: 'healthMaintenanceOrganization/loading',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      clinics: 'clinic/clinics',
      allSpecialities: 'speciality/specialities',
    }),
    hmoPlanMessageCustomMessageError() {
      if (this.$v.hmo.planNumberLength.$error) return 'O número deve ser maior que 0'

      return ''
    },
    telephoneCustomMessageError() {
      if (
        this.$v.hmo.administrativeTelephone.$error
        || this.$v.hmo.attendanceTelephone.$error
      ) return 'Número inválido'

      return ''
    },
    isEditing() {
      return !isNil(this.hmoData.id)
    },
    sortedProfessionals() {
      const [selectedProfessionals, notSelectedProfessionals] = partition(
        ({ id }) => includes(id, this.hmo?.coreAccreditation?.professionals),
        this.professionals,
      )

      return [
        ...selectedProfessionals,
        ...notSelectedProfessionals,
      ]
    },
    isNonePackageHmo() {
      return equals('none', this.hmo.packageType)
    },
  },
  validations() {
    const hmoValidations = {
      hmo: {
        name: {
          required,
          minLength: minLength(2),
        },
        nickName: {
          required,
          minLength: minLength(2),
        },
        type: {
          required,
        },
        packageType: {
          required,
        },
        warningMessage: {
          maxLength: maxLength(500),
        },
        attendanceTelephone: {
          minLength: minLength(8),
        },
        administrativeTelephone: {
          minLength: minLength(8),
        },
        planNumberLength: {
          isDifferentFromZero: planNumberLength => planNumberLength !== '0',
        },
        hasEligibilityCheck: {
          required,
        },
        hasAuthorization: {
          required,
        },
      },
    }
    return { ...hmoValidations }
  },
  watch: {
    isProfessionalPermissionsNull() {
      this.hmo.coreAccreditation.professionals = map(prop('id'), this.professionals)
    },
    isAgendaClinicsPermissionsNull() {
      this.hmo.coreAccreditation.clinics = map(prop('id'), this.clinics)
    },
    isPortalClinicsPermissionsNull() {
      this.hmo.portalAccreditation.clinics = map(prop('id'), this.clinics)
    },
    isSpecialityVideoPermissionsNull() {
      this.hmo.videoAccreditation.specialities = map(prop('id'), this.allSpecialities)
    },
    'hmo.allowPortalLogin': {
      handler(newValue, oldValue) {
        if (equals(oldValue, true) && equals(newValue, false)) {
          this.hmo.selfRegistration = false
        }
      },
    },
  },
  mounted() {
    if (this.editHmo) {
      this.fillHmo()
    } else {
      this.isSpecialityVideoPermissionsNull = true
      this.hmo.selfRegistration = this.isWorkSpaceAmparo
    }

    this.listClinic()
    this.getProfessional()
    this.listSpeciality()
  },
  deactivated() {
    this.resetFields()
  },
  methods: {
    ...mapActions({
      createHmo: 'healthMaintenanceOrganization/createHmo',
      updateHmo: 'healthMaintenanceOrganization/updateHmo',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      listClinic: 'clinic/listClinic',
      listSpeciality: 'speciality/listSpeciality',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    handleAutocompleteText(valuesList, namesObjectList, maxWords) {
      if (length(valuesList) <= maxWords) {
        const reduceIndexed = addIndex(reduce)
        return reduceIndexed((acc, currId, index) => {
          const object = find(item => (
            item.id === currId
          ), namesObjectList)
          if (isNil(object)) return acc
          if (index === 0) return object.name

          return `${acc}, ${object.name}`
        }, '', valuesList)
      }
      return `${length(valuesList)} itens selecionados`
    },
    shouldShowAllSelected(selectedItems, selectedItemsList) {
      return equals(length(selectedItems), length(selectedItemsList))
    },
    rejectNurseProfessionals(professionals) {
      const nurseSpecialities = [SPECIALITY.familyNurse, SPECIALITY.nursingTechnician]
      return reject(
        ({ specialities }) => any(
          speciality => includes(speciality.id, nurseSpecialities),
          specialities,
        ),
        professionals,
      )
    },
    async getProfessional() {
      await axios.get('professional/list_all')
        .then(({ data: { professionals } }) => {
          this.professionals = this.rejectNurseProfessionals(professionals)
        })
    },
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    handleAccreditationSelectValues(accreditationPath, selectedValues) {
      this.hmo = assocPath(accreditationPath, map(prop('id'), selectedValues), this.hmo)
    },
    handleSelectOrRemoveAllAccreditationValues(
      selectedItems,
      selectedItemsList,
      accreditationPath,
    ) {
      const value = this.shouldShowAllSelected(selectedItems, selectedItemsList)
        ? []
        : selectedItemsList

      this.handleAccreditationSelectValues(accreditationPath, value)
    },
    fillHmo() {
      this.hmo.id = this.hmoData.id
      this.hmo.name = this.hmoData.name
      this.hmo.nickName = this.hmoData.nickName
      this.hmo.type = this.hmoData.type
      this.hmo.warningMessage = this.hmoData.warningMessage
      this.hmo.packageType = this.hmoData.packageType
      this.hmo.allowPortalLogin = this.hmoData.allowPortalLogin
      this.hmo.selfRegistration = this.hmoData.selfRegistration
      this.hmo.isCommunicationAllowed = this.hmoData.isCommunicationAllowed
      this.hmo.hasEligibilityCheck = this.hmoData.hasEligibilityCheck
      this.hmo.hasAps = this.hmoData.hasAps
      this.hmo.billDays = this.hmoData.billDays
      this.hmo.hasAuthorization = this.hmoData.hasAuthorization
        ? this.hmoData.hasAuthorization : false

      if (this.hmoData.portalAccreditation) {
        this.hmo.portalAccreditation = clone(this.hmoData.portalAccreditation)
      }

      if (isNil(this.hmoData.portalAccreditation.clinics)) {
        this.isPortalClinicsPermissionsNull = true
      } else {
        this.hmo.portalAccreditation.clinics = clone(this.hmoData.portalAccreditation.clinics)
      }

      if (this.hmoData.coreAccreditation.clinics) {
        this.hmo.coreAccreditation.clinics = clone(this.hmoData.coreAccreditation.clinics)
      } else {
        this.isAgendaClinicsPermissionsNull = true
      }

      if (this.hmoData.coreAccreditation.professionals) {
        this.hmo.coreAccreditation.professionals = clone(
          this.hmoData.coreAccreditation.professionals,
        )
      } else {
        this.isProfessionalPermissionsNull = true
      }

      if (this.hmoData.videoAccreditation.specialities) {
        this.hmo.videoAccreditation.specialities = clone(
          this.hmoData.videoAccreditation.specialities,
        )
      } else {
        this.isSpecialityVideoPermissionsNull = true
      }

      this.hmo.attendanceTelephone = this.hmoData.attendanceTelephone
      this.hmo.administrativeTelephone = this.hmoData.administrativeTelephone
      const healthInsurancePlanNumberLength = path(['validationRules', 'healthInsurancePlanNumberLength'], this.hmoData)

      if (!isNil(healthInsurancePlanNumberLength)) {
        this.hmo.planNumberLength = healthInsurancePlanNumberLength
      }
    },
    onlyNumber(value) {
      return value ? value.replace(/[\D]/g, '') : null
    },
    hmoHasCodeField(id) {
      return id === HMO.sulAmerica
    },
    generateValidationRules(planNumberLength) {
      const oldValidationRules = isNil(this.hmoData)
        ? null
        : this.hmoData.validationRules

      return mergeRight(
        oldValidationRules,
        {
          healthInsurancePlanNumberLength: isEmpty(planNumberLength)
            ? null
            : parseInt(planNumberLength, 10),
        },
      )
    },
    buildHmo(hmo) {
      const hmoFormatted = {
        ...hmo,
        attendanceTelephone: this.onlyNumber(hmo.attendanceTelephone),
        administrativeTelephone: this.onlyNumber(hmo.administrativeTelephone),
        validationRules: this.generateValidationRules(hmo.planNumberLength),
        warningMessage: isEmpty(hmo.warningMessage) ? null : hmo.warningMessage,
      }

      return omit(
        [
          'planNumberLength',
        ],
        hmoFormatted,
      )
    },
    closeChangeHmoPackageTypeWarningIsOpen({ update }) {
      this.changeHmoPackageTypeWarningIsOpen = false
      if (update) this.handleSaveHmo(this.hmo, true)
    },
    shouldShowChangeHmoPackageTypeWarning(treatedAttributes, bypassChangeHmoPackageTypeWarning) {
      return includes(treatedAttributes.packageType, ['none', 'amparoConnected'])
        && !equals(treatedAttributes.packageType, this.hmoData.packageType)
        && !bypassChangeHmoPackageTypeWarning
    },
    async handleSaveHmo(attributesParams, bypassChangeHmoPackageTypeWarning) {
      this.$v.$touch()
      const attributes = clone(attributesParams)

      if (this.isPortalClinicsPermissionsNull) {
        attributes.portalAccreditation.clinics = null
      }

      if (this.isAgendaClinicsPermissionsNull) {
        attributes.coreAccreditation.clinics = null
      }

      if (this.isProfessionalPermissionsNull) {
        attributes.coreAccreditation.professionals = null
      }

      if (this.isSpecialityVideoPermissionsNull) {
        attributes.videoAccreditation.specialities = null
      }

      if (!this.$v.$error) {
        const treatedAttributes = omit(['id'], attributes)
        try {
          if (this.isEditing) {
            const shouldShowPackageTypeWarning = this.shouldShowChangeHmoPackageTypeWarning(
              treatedAttributes,
              bypassChangeHmoPackageTypeWarning,
            )

            if (shouldShowPackageTypeWarning) {
              this.changeHmoPackageTypeWarningIsOpen = true
              return
            }

            await this.updateHmo({
              id: this.hmoData.id,
              attributes: this.buildHmo(treatedAttributes),
            })
            this.$emit('closeCreateHmoDialog', { updateHmoData: true })
          } else {
            await this.createHmo(this.buildHmo(treatedAttributes))
            this.$emit('closeCreateHmoDialog')
          }
          this.showSnackbar('success', 'Plano de saúde salvo com sucesso!')
          this.listHmo(this.hmoListFilter)
        } catch (error) {
          this.showErrorSnackBar(error)
        }
      }
    },
    showErrorSnackBar(error) {
      const firstErrorMessage = error?.response?.data?.errors?.errors?.[0]?.message

      if (equals('nick_name must be unique', firstErrorMessage)) {
        this.showSnackbar('error', 'Já existe uma Operadora com este nome')
      } else {
        const message = this.isEditing
          ? 'Erro ao editar plano de saúde.'
          : 'Erro ao cadastrar plano de saúde.'
        this.showSnackbar('error', message)
      }
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.listClinic()
      this.$v.$reset()
    },
    closeModal() {
      this.$emit('closeCreateHmoDialog')
    },
    handleModalTitleName() {
      return this.editHmo ? 'Dados de Operadora' : 'Cadastrar Nova Operadora'
    },
  },
}
</script>

<style lang='stylus' scoped>
.hmo-title__container > h2
  font-size 2rem
  font-weight 500

.hmo-sub-section__title__container > h4
  font-size 1.125rem
  font-weight 600
  color #323338CC

.hmo-sub-section__title__container > h5
  font-size 0.875rem
  font-weight 600
  color #323338CC

.cancel-btn--text
  font-size 0.875rem
  font-weight 700
  color #323338CC

.auto-complete__prepend-item--checkbox
  font-weight 700
  color #000000de
</style>
