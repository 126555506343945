<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por nome"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="getClinics()"
          @click:clear="getClinics()"
        />
        <v-autocomplete
          v-model="filters.state"
          :items="formOptions.states"
          label="Buscar por estado"
          clearable
          append-icon
          filled
          hide-no-data
          hide-details
          @change="getClinics()"
          @click:clear="getClinics()"
        />
        <v-text-field
          v-model="filters.city"
          label="Buscar por cidade"
          clearable
          append-icon
          filled
          hide-no-data
          hide-details
          @change="getClinics()"
          @click:clear="getClinics()"
        />

        <v-btn
          class="btn-filter--height tw-font-semibold"
          color="dark-green"
          dark
          block
          large
          @click="getClinics()"
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          Buscar Clínica
        </v-btn>
      </div>
    </div>

    <div class="tw-px-12 tw-my-4">
      <div
        v-if="isClinicsEmpty"
      >
        <h2 class="tw-tracking-tighter tw-text-6xl tw-font-extralight tw-text-[#00000061]">
          Nenhum resultado encontrado.
        </h2>
      </div>
      <template v-else>
        <div
          v-for="(clinic, index) in clinics"
          :key="index"
          class="
            tw-grid
            tw-grid-cols-4
            tw-p-5 tw-mb-3
            tw-rounded-lg
            tw-border-solid
            tw-border-[1px]
            tw-border-gray-thin"
        >
          <div class="tw-flex tw-flex-col tw-flex-wrap">
            <div class="tw-text-[#000000de] tw-text-2xl">
              {{ clinic.name }}

              <v-chip
                v-if="clinic.storedAt"
                class="tw-max-w-28 tw-rounded-lg"
              >
                Arquivado
              </v-chip>
            </div>

            <div class="tw-text-base tw-leading-8 tw-tracking-wider tw-text-[#00000099]">
              {{ clinic.address.city }} - {{ clinic.address.state }}
            </div>
          </div>

          <div class="tw-text-base tw-leading-8 tw-tracking-wider tw-text-[#00000099]">
            <div>
              {{ clinic.address.street }}
            </div>

            <div>
              Número: {{ clinic.address.number }}
              <span v-if="clinic.address.complement">
                - {{ clinic.address.complement }}
              </span>
            </div>

            <div>
              CEP: {{ clinic.address.zipCode | formatZipCode }}
            </div>

            <div>
              Cidade: {{ clinic.address.city }}
            </div>
          </div>

          <div class="tw-text-base tw-leading-8 tw-tracking-wider tw-text-[#00000099]">
            <div>
              Abertura: {{ clinic.startTime | removeSecondsFromTime }}
            </div>

            <div>
              Fechamento: {{ clinic.endTime | removeSecondsFromTime }}
            </div>
          </div>

          <div class="tw-flex tw-flex-row tw-justify-end tw-gap-x-2">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  :disabled="clinicIsArchived(clinic)"
                  @click="editDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip
              v-if="!clinic.storedAt"
              top
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="setShowArchiveDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-package-down</v-icon>
                </v-btn>
              </template>
              <span>Arquivar</span>
            </v-tooltip>
            <v-tooltip
              v-else
              top
            >
              <template #activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="setShowArchiveDialog(clinic)"
                  v-on="on"
                >
                  <v-icon>mdi-package-up</v-icon>
                </v-btn>
              </template>
              <span>Restaurar</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </div>

    <create-clinic
      v-if="showEditClinicDialog"
      edit-clinic
      :clinic-data="editedClinic"
      @closeDialog="closeEditDialog"
    />

    <clinic-archive-dialog
      v-if="showArchiveDialog"
      :clinic-data="editedClinic"
      @closeDialog="closeArchiveDialog"
    />
  </div>
</template>

<script>
import {
  clone,
  isEmpty,
  isNil,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ClinicList',
  components: {
    CreateClinic: () => import('./CreateClinic'),
    ClinicArchiveDialog: () => import('./ClinicArchiveDialog'),
  },
  data() {
    return {
      editedClinic: {},
      showArchiveDialog: false,
      showEditClinicDialog: false,
      filters: {
        includeStored: true,
      },
      formOptions: {
        states: [
          'AC',
          'AL',
          'AM',
          'AP',
          'BA',
          'CE',
          'DF',
          'ES',
          'GO',
          'MA',
          'MT',
          'MS',
          'MG',
          'PA',
          'PB',
          'PR',
          'PE',
          'PI',
          'RJ',
          'RN',
          'RO',
          'RS',
          'RR',
          'SC',
          'SE',
          'SP',
          'TO',
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
    }),
    isClinicsEmpty() {
      return isEmpty(this.clinics)
    },
  },
  mounted() {
    this.getClinics()
  },
  methods: {
    ...mapActions({
      listClinic: 'clinic/listClinic',
    }),
    clinicIsArchived({ storedAt }) {
      return !isNil(storedAt)
    },
    getClinics() {
      this.listClinic(this.filters)
    },
    editDialog(clinicData) {
      this.editedClinic = clone(clinicData)
      this.showEditClinicDialog = true
    },
    closeEditDialog() {
      this.editedClinic = {}
      this.showEditClinicDialog = false
    },
    setShowArchiveDialog(clinic) {
      this.editedClinic = clone(clinic)
      this.showArchiveDialog = true
    },
    closeArchiveDialog() {
      this.editedClinic = {}
      this.showArchiveDialog = false
      this.getClinics()
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
