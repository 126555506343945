import axios from '@/lib/axios'
import {
  includes,
  isNil,
  path,
  reject,
} from 'ramda'
import { ELIGIBILITY } from 'amparo-enums'

const actions = {
  async getEligibility({ dispatch }, {
    hmoId,
    cardNumber,
    patientId,
    orderId,
  }) {
    const {
      eligible,
      success,
      cmResponseError,
      notEligible,
      serviceUnavailable,
    } = ELIGIBILITY.status

    const patientEligibility = await axios.get('/eligibility', {
      params: {
        hmoId,
        cardNumber,
        patientId,
        orderId,
      },
      timeout: 120000,
    })
      .then(({ data }) => data)
      .catch(({ response }) => {
        if (isNil(response)) {
          dispatch(
            'snackbar/setSnackbar',
            {
              status: 'error',
              message: 'Não foi possível concluir a solicitação, tente novamente mais tarde.',
            },
            { root: true },
          )
          return {
            errorCode: serviceUnavailable,
          }
        }
        return path(['data'], response)
      })

    if (includes(patientEligibility.status, [eligible, success])) {
      return {
        status: eligible,
        success: 'Elegibilidade validada',
        icon: 'mdi-check-circle-outline',
        ...patientEligibility,
      }
    }

    const errorMessage = includes(patientEligibility.errorCode, [cmResponseError, notEligible])
      ? 'Elegibilidade não validada'
      : 'Serviço indisponível'

    return {
      error: errorMessage,
      status: patientEligibility.errorCode,
      icon: 'mdi-alert-circle-outline',
      ...patientEligibility,
    }
  },
  async updateEligibility(_, {
    patientId,
    orderId,
    hmoId,
    cardNumber,
    status,
  }) {
    const params = reject(isNil, {
      orderId,
      hmoId,
      cardNumber,
      status,
    })
    return axios.patch(`/eligibility/${patientId}`, params)
  },
}

export default {
  namespaced: true,
  actions,
}
