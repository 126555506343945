<template>
  <div>
    <div class="tw-bg-green-light tw-pl-5">
      <h2
        class="tw-text-3xl tw-font-medium tw-text-gray tw-pt-4 tw-pl-5"
        data-testid="agenda-register-item__title--text"
      >
        Defina as configurações do bloqueio
      </h2>
      <div class="tw-flex tw-flex-row tw-mt-0">
        <v-breadcrumbs :items="titleItems">
          <template slot="divider">
            <v-icon color="primary">
              mdi-chevron-right
            </v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="tw-grid tw-grid-cols-1 tw-p-5 lg:tw-grid-cols-4 tw-gap-4">
      <v-menu
        v-model="menuStartDay"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formatedStartDate"
            label="Data inicial"
            append-icon="mdi-calendar"
            filled
            readonly
            :error="$v.startDate.$error"
            @blur="$v.startDate.$touch()"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="startDate"
          locale="pt-br"
          no-title
          scrollable
          @input="menu = false"
        />
      </v-menu>

      <v-menu
        v-model="menuEndDay"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formatedEndDate"
            label="Data final"
            append-icon="mdi-calendar"
            filled
            readonly
            clearable
            :error="$v.endDate.$error"
            @blur="$v.endDate.$touch()"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="endDate"
          locale="pt-br"
          no-title
          scrollable
          @input="menu = false"
        />
      </v-menu>

      <v-select
        v-model="startTime"
        :items="startWorkHours"
        label="Hora início"
        filled
        :error="$v.startTime.$error"
        @focus="buildstartWorkHoursList"
        @blur="$v.startTime.$touch()"
      />

      <v-select
        v-model="endTime"
        :items="buildEndWorkHourList"
        label="Hora fim"
        no-data-text="Preencha os campos anteriores"
        filled
        :error="$v.endTime.$error"
        @blur="$v.endTime.$touch()"
      />

      <v-select
        v-model="reason"
        :items="reasonItems"
        label="Razão"
        filled
        item-text="label"
        item-value="value"
        :error="$v.reason.$error"
        @blur="$v.reason.$touch()"
        @change="clearOtherReason"
      />

      <v-text-field
        v-if="isOtherReasonSelected"
        v-model="otherReason"
        label="Descreva a razão"
        filled
        counter
        maxlength="50"
        :error="$v.otherReason.$error"
        @blur="$v.otherReason.$touch()"
      />
    </div>

    <div
      class="tw-mt-3 tw-mx-5"
    >
      <v-checkbox
        v-model="isPaid"
        label="Bloqueio Remunerado"
        color="green"
      />
    </div>

    <div class="tw-flex tw-fixed tw-bottom-6 tw-right-6 tw-gap-4">
      <v-btn
        color="secondary"
        class="tw-font-bold"
        text
        outlined
        @click="handleCloseBlockDialog()"
      >
        Cancelar
      </v-btn>

      <v-btn
        color="primary"
        class="tw-font-bold"
        :loading="isLoading"
        @click="saveAgendaBlockPattern()"
      >
        Salvar
      </v-btn>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import moment from 'moment'
import AgendaRegisterMixin from '@/mixins/AgendaRegisterMixin'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { equals, omit, values } from 'ramda'
import { BLOCK } from 'amparo-enums'
import isNilOrEmpty from '@/utils/dataValidators'

export default {
  mixins: [AgendaRegisterMixin],
  props: ['clinic', 'speciality', 'professional', 'editData'],
  data() {
    return {
      reasonItems: values(BLOCK.reasons),
      isPaid: false,
      isLoading: false,
      titleItems: [
        {
          text: this.clinic.name,
        },
        {
          text: this.speciality.name,
        },
        {
          text: this.professional.name,
        },
      ],
    }
  },
  validations() {
    const agendaPatternValidations = {
      startDate: {
        required,
      },
      endDate: {
        required,
        isSameOrAfterStartDate: () => this.isSameOrAfterStartDate(),
      },
      startTime: { required },
      endTime: { required },
      interval: { required },
      reason: { required, maxLength: maxLength(50) },
      otherReason: { requiredIf: requiredIf(() => this.isOtherReasonSelected) },
    }
    return { ...agendaPatternValidations }
  },
  computed: {
    isOtherReasonSelected() {
      return equals(this.reason, BLOCK.reasons.other.value)
    },
    isEditing() {
      return !isNilOrEmpty(this.editData)
    },
  },
  mounted() {
    this.buildstartWorkHoursList()
    if (this.isEditing) this.fillBlockToEdit()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      createBlock: 'agenda/createBlock',
      updateBlock: 'agenda/updateBlock',
    }),
    fillBlockToEdit() {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
        reason,
        isPaid,
        otherReason,
      } = this.editData

      this.startDate = moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.endDate = moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.startTime = moment(startTime, 'HH:mm:ss').format('HH:mm')
      this.endTime = moment(endTime, 'HH:mm:ss').format('HH:mm')
      this.reason = reason
      this.otherReason = otherReason
      this.isPaid = isPaid
    },
    clearOtherReason() {
      this.otherReason = null
    },
    getAgendaBlocks() {
      this.$emit('getAgendaBlocks')
    },
    handleCloseBlockDialog() {
      this.resetFields()
      this.$emit('closeBlockDialog')
    },
    buildAgendaBlock() {
      const agenda = {
        startDate: moment(this.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        endDate: moment(this.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        startTime: this.startTime,
        endTime: this.endTime,
        professionalId: this.professional.id,
        specialityId: this.speciality.id,
        clinicId: this.clinic.id,
        reason: this.reason,
        isPaid: this.isPaid,
      }
      if (this.isOtherReasonSelected) {
        agenda.otherReason = this.otherReason
      }

      return this.isEditing
        ? omit(['professionalId', 'specialityId', 'clinicId'], agenda)
        : agenda
    },
    saveAgendaBlockPattern: debounce(async function saveAgendaBlockPattern() {
      this.$v.$touch()
      if (this.$v.$error) return
      const block = this.buildAgendaBlock()
      try {
        if (this.isEditing) {
          await this.updateBlock({
            id: this.editData.id,
            attributes: block,
          })
        } else {
          await this.createBlock(block)
        }
        await this.getAgendaBlocks()
        await this.resetFields()
        await this.handleCloseBlockDialog()
      } catch (error) {
        const status = 'error'
        const message = this.setErrorMessage(error.response.data)
        this.setSnackbar({ status, message })
      } finally {
        this.isLoading = false
      }
    }, 500),
  },
}
</script>
