<template>
  <div class="tw-flex">
    <div
      v-if="isLoadingLastEncounter"
      class="tw-mx-2"
    >
      <v-btn
        large
        :loading="isLoadingLastEncounter"
        :disabled="true"
        class="btn btn-primary"
      />
    </div>

    <div
      v-if="lastOpenEncounter && !isLoadingLastEncounter"
      class="tw-mx-2"
    >
      <v-btn
        v-if="!isEncounterAnnotation(lastOpenEncounter)"
        large
        class="btn btn-primary"
        @click="redirectToEncounter({
          patientId: lastOpenEncounter.patientId,
          encounterId: lastOpenEncounter.id,
        })"
      >
        <v-icon
          class="tw-pr-2"
        >
          mdi-medical-bag
        </v-icon>
        Continuar atendimento
      </v-btn>
      <v-btn
        v-else
        large
        class="btn btn-info"
        @click="redirectToEncounter({
          patientId: lastOpenEncounter.patientId,
          encounterId: lastOpenEncounter.id,
        })"
      >
        <v-icon
          class="tw-pr-2"
        >
          mdi-clipboard-text-outline
        </v-icon>
        Continuar anotação
      </v-btn>
    </div>

    <div
      v-if="!lastOpenEncounter && !isLoadingLastEncounter"
      class="tw-mx-2"
    >
      <v-btn
        large
        :loading="isLoading"
        :disabled="isPatientArchived"
        class="btn btn-primary"
        @click="newEncounter()"
      >
        <v-icon
          class="tw-pr-2"
        >
          mdi-medical-bag
        </v-icon>
        Novo atendimento
      </v-btn>
    </div>

    <div
      v-if="!lastOpenEncounter && isWorkSpaceAmparo && !isLoadingLastEncounter"
      class="tw-ml-4"
    >
      <v-tooltip
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            :disabled="isPatientArchived"
            class="btn btn-info"
            v-bind="attrs"
            v-on="on"
            @click="annotationPopup = true"
          >
            <v-icon left>
              mdi-clipboard-text-outline
            </v-icon>
            Anotação
          </v-btn>
        </template>
        <span>
          <v-icon
            class="tw-pr-2"
            dark
          >
            mdi-alert
          </v-icon>

          Use a anotação para registrar atividades administrativas,
          erratas e monitoramentos que não configuram uma consulta.
        </span>
      </v-tooltip>
    </div>

    <emr-settings-dialog
      :show-dialog="showSettingsDialog"
      :is-annotation="isAnnotation"
      @update:showDialog="toggleSettingsDialog()"
      @update:encounterOptions="handleEncounterOptions"
    />

    <v-dialog
      v-model="annotationPopup"
      max-width="600"
    >
      <v-card class="tw-pt-4">
        <v-card-title class="tw-font-bold tw-text-lg tw-mb-4">
          Qual a necessidade da anotação?
        </v-card-title>

        <v-card-text class="tw-flex">
          <v-select
            v-model="selectedAnnotationReason"
            :items="annotationReasons"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="label"
            item-value="value"
            label="Tipo de atendimento"
            filled
            hide-details
            class="popup__fields"
          />
        </v-card-text>

        <v-card-actions class="tw-px-6 tw-pb-6">
          <v-btn
            large
            block
            class="btn btn-primary"
            :loading="isLoading || isLoadingCreateEncounter"
            :disabled="!selectedAnnotationReason"
            @click="newEncounter('ANNOTATION')"
          >
            Nova anotação
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  isNil,
  not,
  values,
  has,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'
import encounterAnnotationReason from '@/enums/encounterAnnotationReason'
import { APPOINTMENT } from 'amparo-enums'
import EmrSettingsDialog from '@/pages/Emr/EmrSettingsDialog'

const { ENCOUNTER_ANNOTATION_REASON } = encounterAnnotationReason

export default {
  components: {
    EmrSettingsDialog,
  },
  props: [
    'encounterAppointmentOptions',
  ],
  data() {
    return {
      annotationPopup: false,
      selectedAnnotationReason: null,
      annotationReasons: values(ENCOUNTER_ANNOTATION_REASON),
      showSettingsDialog: false,
      selectedClinic: null,
      selectedAppointment: null,
      specialityList: [],
      selectedSpeciality: null,
      isLoading: false,
      lastOpenEncounter: null,
      lastOpenEncounterAnnotation: null,
      isLoadingLastEncounter: false,
      specialitiesWithEncounterForms: [],
      disableClinicSelect: false,
      disableSpecialitySelect: false,
      shouldShowEncounterFormSelect: true,
      selectedEncounterForm: null,
      encounterParams: null,
      isLoadingCreateEncounter: false,
    }
  },
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      patient: 'emr/patient',
      user: 'authentication/user',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
    }),
    isPatientArchived() {
      return not(isNil(this.patient.storedAt))
    },
    isAnnotation() {
      return !isNil(this.selectedAnnotationReason)
    },
    professionalId() {
      return this.user.professionalId
    },
    patientId() {
      return this.$route.params.id
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    lastWeek() {
      return moment().add(-1, 'week').format('YYYY-MM-DD')
    },
  },
  watch: {
    settingsPopup(newValue) {
      if (!newValue) {
        this.handleClearEncounterAppointmentOptions()
        return
      }

      this.updateList(
        'disableClinicSelect',
        this.clinics,
        'selectedClinic',
      )
      this.updateList(
        'disableSpecialitySelect',
        this.specialityList,
        'selectedSpeciality',
      )
    },
  },
  mounted() {
    this.listAppointment({
      professional: { id: this.professionalId },
      patient: { id: this.patientId },
      startDate: this.lastWeek,
      endDate: this.today,
      status: [
        APPOINTMENT.status.scheduled.value,
        APPOINTMENT.status.confirmed.value,
        APPOINTMENT.status.received.value,
      ],
      includeClinic: true,
    })
    this.isLoadingLastEncounter = true
    this.getLastOpenEncounter()
      .then((res) => {
        this.lastOpenEncounter = res
      })
      .finally(() => {
        this.isLoadingLastEncounter = false
      })
  },
  methods: {
    ...mapActions({
      clearEncounterAppointmentOptions: 'emr/clearEncounterAppointmentOptions',
      listClinic: 'clinic/listClinic',
      listSpeciality: 'speciality/listSpeciality',
      listProfessional: 'professional/listProfessional',
      listAppointment: 'agenda/listAppointment',
      setIsNewEncounter: 'emr/setIsNewEncounter',
      getLastOpenEncounter: 'emr/getLastOpenEncounter',
      getCareLineSuggestedExams: 'emr/getCareLineSuggestedExams',
    }),
    toggleSettingsDialog() {
      this.showSettingsDialog = !this.showSettingsDialog
    },
    isEncounterAnnotation(encounter) {
      return encounter.encounterFormName === 'ANNOTATION'
    },
    handleClearEncounterAppointmentOptions() {
      this.selectedClinic = null
      this.selectedSpeciality = null
      this.selectedAppointment = null
      this.selectedEncounterForm = null
      this.clearEncounterAppointmentOptions()
    },
    async redirectToEncounter({ patientId, encounterId }) {
      this.$router.push(`/emr/${patientId}/encounter/${encounterId}`)
    },
    setProfessionalPreferences({ professionalId }) {
      this.$http.get('/emr/professional_preferences', {
        params: { professionalId },
      })
        .then((res) => {
          localStorage.setItem('_professionalPreferences', JSON.stringify(res.data.professionalPreferences))
        })
        .catch(() => {
          localStorage.setItem('_professionalPreferences', JSON.stringify({}))
        })
    },
    async postEncounter(params) {
      this.isLoading = true
      this.$http.post('/emr/encounter', params)
        .then((res) => {
          this.setIsNewEncounter(true)
          this.redirectToEncounter({
            patientId: params.patientId,
            encounterId: res.data.encounter.id,
          })
        })
        .finally(() => {
          this.handleClearEncounterAppointmentOptions()
          this.isLoadingCreateEncounter = false
          this.isLoading = false
        })
    },
    isAppointmentsOptionsValid(apOptions, {
      professionalId,
      patientId,
    }) {
      return apOptions.patientId === patientId
        && apOptions.professionalId === professionalId
    },
    handleEncounterOptions(options) {
      this.selectedClinic = options?.clinicId
      this.selectedSpeciality = options?.specialityId
      this.selectedEncounterForm = options?.encounterFormId
      this.selectedAppointment = options?.appointmentData

      this.newEncounter()
    },
    async getEncounterParams() {
      const loggedProfessional = this.user.professionalId

      const suggestedExams = await this.getCareLineSuggestedExams({
        patientId: this.$route.params.id,
      })

      const params = {
        patientId: this.$route.params.id,
        professionalId: loggedProfessional,
        suggestedExams,
      }
      const apOptions = this.encounterAppointmentOptions || {}

      if (this.isAppointmentsOptionsValid(apOptions, params)) {
        if (apOptions.appointmentId) {
          params.clinicId = apOptions.clinicId
          params.appointmentId = apOptions.appointmentId
        }
        if (apOptions.immediateCareId) {
          params.immediateCareId = apOptions.immediateCareId
        }
        params.specialityId = apOptions.specialityId
      }
      if (this.selectedClinic) params.clinicId = this.selectedClinic
      if (this.selectedSpeciality) params.specialityId = this.selectedSpeciality
      if (this.selectedAnnotationReason) params.annotationReason = this.selectedAnnotationReason
      if (this.selectedAppointment) {
        params.clinicId = this.selectedAppointment.clinicId
        params.appointmentId = this.selectedAppointment.id
        params.specialityId = this.selectedAppointment.specialityId
      }
      if (this.selectedEncounterForm) params.encounterFormId = this.selectedEncounterForm

      return params
    },
    async newEncounter() {
      this.isLoadingCreateEncounter = true
      const params = await this.getEncounterParams()

      const hasAllEncounterParams = has('patientId', params)
        && has('professionalId', params)
        && has('specialityId', params)
        && has('clinicId', params)

      if (not(hasAllEncounterParams)) {
        this.toggleSettingsDialog()
        this.isLoadingCreateEncounter = false
        return
      }

      this.setProfessionalPreferences(params)
      this.postEncounter(params)
    },
  },
}
</script>
