<template>
  <div class="tw-px-12 tw-mt-4">
    <div class="tw-flex tw-flex-row tw-justify-between tw-items-center">
      <div class="tw-flex tw-flex-row tw-gap-x-4">
        <span
          class="tw-text-primary tw-cursor-pointer tw-underline"
          @click="openCareLineDiseaseTimeline"
        >
          Ver Histórico de Diagnósticos
        </span>
        <span
          class="tw-text-primary tw-cursor-pointer tw-underline"
          @click="openCareLineAgeTimeline"
        >
          Ver Histórico de Faixas Etárias
        </span>
      </div>
      <div class="tw-flex tw-flex-row tw-gap-x-4">
        <v-tooltip
          bottom
          :disabled="!isMultimobidityCareLine"
        >
          <template #activator="{ on }">
            <div v-on="on">
              <v-btn
                class="btn btn-primary"
                large
                :disabled="!careLineAgesIsEmpty || isMultimobidityCareLine"
                @click="openCreateCareLineDiseaseDialog"
              >
                Cadastrar diagnóstico
              </v-btn>
            </div>
          </template>
          <span>Esta linha de cuidado já inclui todos os diagnósticos</span>
        </v-tooltip>
        <v-btn
          class="btn btn-primary"
          large
          :disabled="!careLineDiseasesIsEmpty"
          @click="openCreateCareLineAgeDialog"
        >
          Cadastrar faixa etária
        </v-btn>
      </div>
    </div>

    <list-care-line-disease
      v-if="!careLineDiseasesIsEmpty"
      class="tw-mt-5"
    />

    <list-care-line-age
      v-else-if="!careLineAgesIsEmpty"
      class="tw-mt-5"
    />

    <h2
      v-else
      class="tw-mt-5"
    >
      {{ notFoundMessage }}
    </h2>

    <create-care-line-disease
      v-if="isOpenCreateCareLineDiseaseDialog"
      @close="closeCreateCareLineDiseaseDialog"
    />

    <create-care-line-age
      v-if="isOpenCreateCareLineAgeDialog"
      @closeDialog="closeCreateCareLineAgeDialog"
    />

    <v-dialog
      v-model="showCareLineAgeTimeline"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Histórico de Alterações
          <v-spacer />
          <v-btn
            icon
            @click="closeCareLineAgeTimeline"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <care-line-timeline
          v-if="showCareLineAgeTimeline"
          :care-line-activity-log="careLineAgeActivityLog"
          care-line-instance="careLineAge"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCareLineDiseaseTimeline"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <v-card>
        <v-card-title class="card-dialog__title">
          Histórico de Alterações
          <v-spacer />
          <v-btn
            icon
            @click="closeCareLineDiseaseTimeline"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <care-line-timeline
          v-if="showCareLineDiseaseTimeline"
          :care-line-activity-log="careLineDiseaseActivityLog"
          care-line-instance="careLineDisease"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  isEmpty,
} from 'ramda'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import { CARE_LINE } from 'amparo-enums'
import ListCareLineAge from './ListCareLineAge'
import ListCareLineDisease from './ListCareLineDisease'

export default {
  components: {
    ListCareLineDisease,
    ListCareLineAge,
    CareLineTimeline: () => import('@/components/CareLineTimeline'),
    CreateCareLineAge: () => import('./CreateCareLineAge'),
    CreateCareLineDisease: () => import('./CreateCareLineDisease'),
  },
  data() {
    return {
      isOpenCreateCareLineDiseaseDialog: false,
      isOpenCreateCareLineAgeDialog: false,
      notFoundMessage: 'A Linha não possui faixas etárias ou diagnósticos cadastrados para atribuição automática de pacientes.',
      showCareLineAgeTimeline: false,
      showCareLineDiseaseTimeline: false,
    }
  },
  computed: {
    ...mapGetters({
      careLine: 'careLine/careLine',
      careLineAges: 'careLine/careLineAges',
      careLineDiseases: 'careLine/careLineDiseases',
    }),
    careLineDiseasesIsEmpty() {
      return isEmpty(this.careLineDiseases)
    },
    careLineAgesIsEmpty() {
      return isEmpty(this.careLineAges)
    },
    careLineAgeActivityLog() {
      return this.careLine.ageActivityLog
    },
    careLineDiseaseActivityLog() {
      return this.careLine.diseaseActivityLog
    },
    isMultimobidityCareLine() {
      return this.careLine.id === CARE_LINE.multimorbidity
    },
  },
  methods: {
    ...mapActions({
      getCareLine: 'careLine/getCareLine',
    }),
    openCreateCareLineDiseaseDialog() {
      this.isOpenCreateCareLineDiseaseDialog = true
    },
    closeCreateCareLineDiseaseDialog() {
      this.isOpenCreateCareLineDiseaseDialog = false
    },
    openCreateCareLineAgeDialog() {
      this.isOpenCreateCareLineAgeDialog = true
    },
    closeCreateCareLineAgeDialog() {
      this.isOpenCreateCareLineAgeDialog = false
    },
    async openCareLineAgeTimeline() {
      await this.getCareLine(this.careLine.id)
      this.showCareLineAgeTimeline = true
    },
    closeCareLineAgeTimeline() {
      this.showCareLineAgeTimeline = false
    },
    async openCareLineDiseaseTimeline() {
      await this.getCareLine(this.careLine.id)
      this.showCareLineDiseaseTimeline = true
    },
    closeCareLineDiseaseTimeline() {
      this.showCareLineDiseaseTimeline = false
    },
  },
}
</script>
