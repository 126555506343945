<template>
  <div>
    <div
      :class="{
        'filters-container tw-py-7 tw-px-11': true,
        'filters-container--fixed tw-px-8': isInsideEncounter,
      }"
    >
      <div class="tw-grid tw-grid-cols-2 tw-gap-6">
        <div>
          <v-autocomplete
            v-model="filters.professionalId"
            :items="patientsProfessionalList"
            item-text="name"
            item-value="id"
            label="Profissional"
            append-icon
            no-data-text="Não encontrado"
            attach
            clearable
            filled
            hide-details
            @change="filterEncountersByDoctor"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
                <v-list-item-subtitle>{{ item.professionName }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div>
          <v-menu
            v-model="menuDate"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            attach
            :nudge-right="40"
            max-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                :value="formatFilterDateToDDMMYYYY"
                readonly
                label="Data da consulta"
                append-icon="mdi-calendar"
                clearable
                filled
                hide-details
                v-on="on"
                @click:clear="
                  filters.date = null;
                  filterEncountersByDate()
                "
              />
            </template>
            <v-date-picker
              v-model="filters.date"
              :events="daysWithEncounters"
              event-color="green"
              locale="pt-br"
              color="primary"
              no-title
              @change="filterEncountersByDate"
            />
          </v-menu>
        </div>
      </div>
    </div>

    <div>
      <emr-encounter-list
        v-if="isInsideEncounter"
        :encounters="encounters"
        :encounters-empty-message="encountersEmptyMessage"
        :pagination="pagination"
        @changePage="setPage"
      />

      <emr-timeline-list
        v-else
        :is-loading="!hasLoadEncounters"
        :encounters="encounters"
        :encounters-empty-message="encountersEmptyMessage"
        :pagination="pagination"
        @changePage="setPage"
      />
    </div>

    <emr-new-care-line-patient-dialog
      v-if="newCareLinePatientModalIsOpen"
      @closeDialog="closeNewCareLinePatientModal"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  all,
  defaultTo,
  filter,
  isEmpty,
  isNil,
  map,
  not,
  path,
  propEq,
  values,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'
import EmrEncounterList from './EmrEncounterList'
import EmrTimelineList from './EmrTimelineList'
import EmrNewCareLinePatientDialog from '../EmrNewCareLinePatientDialog'

export default {
  components: {
    EmrEncounterList,
    EmrTimelineList,
    EmrNewCareLinePatientDialog,
  },
  props: {
    isInsideEncounter: {
      type: Boolean,
    },
  },
  data() {
    return {
      filters: {
        date: null,
        professionalId: null,
      },
      pagination: {
        page: 1,
        descending: true,
      },
      encounters: [],
      hasLoadEncounters: false,
      menuDate: false,
      newCareLinePatientModalIsOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      emrEncounters: 'emr/encounters',
      patient: 'emr/patient',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    ...mapState({
      showNewCareLinePatientModalAfterEncounter:
        ({ emr }) => emr.showNewCareLinePatientModalAfterEncounter,
    }),
    formatFilterDateToDDMMYYYY() {
      return this.filters.date
        ? moment(this.filters.date).format('DD/MM/YYYY')
        : ''
    },
    daysWithEncounters() {
      return map(this.getFormatedDate, defaultTo([], this.encounters))
    },
    patientsProfessionalList() {
      const encountersProfessionals = map(
        encounter => ({
          id: encounter.professional.id,
          name: encounter.professional.name,
          professionName: encounter.professional.profession.name,
        }),
        defaultTo([], this.encounters),
      )

      return encountersProfessionals
    },
    filtersAreNull() {
      return all(isNil, values(this.filters))
    },
    encountersEmptyMessage() {
      return this.filtersAreNull
        ? 'Paciente não possui consultas'
        : 'Não há consultas para esta busca.'
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.loadEncounters()
      },
    },
    showNewCareLinePatientModalAfterEncounter(newValue) {
      if (newValue) {
        this.newCareLinePatientModalIsOpen = true
      }
    },
  },
  created() {
    this.loadEncounters()
  },
  activated() {
    this.cleanFilters()
    this.loadEncounters()
  },
  deactivated() {
    this.cleanFilters()
    this.resetPagination()
    this.clearAutoSchedule()
  },
  methods: {
    ...mapActions({
      getEmrPaginateEncounters: 'emr/getEmrPaginateEncounters',
      setAutoSchedule: 'schedule/setAutoSchedule',
      setEncountersFilters: 'emr/setEncountersFilters',
      setEncountersPagination: 'emr/setEncountersPagination',
    }),
    closeNewCareLinePatientModal() {
      this.newCareLinePatientModalIsOpen = false
    },
    setPage(page) {
      this.pagination.page = page
    },
    clearAutoSchedule() {
      this.setAutoSchedule({
        encounter: {},
        isShowing: false,
      })
    },
    getFormatedDate({ date }) {
      return moment(date).format('YYYY-MM-DD')
    },
    resetEncountersList() {
      this.encountersToShow = [...this.encounters]
    },
    filterEncountersByDoctor() {
      this.setEncountersFilters(this.filters)
      this.loadEncounters()
    },
    filterEncountersByDate() {
      if (not(isNilOrEmpty(this.filters.date))) {
        this.filters.date = moment(this.filters.date).format('YYYY-MM-DD')
      }
      this.setEncountersFilters(this.filters)
      this.loadEncounters()
    },
    async loadEncounters() {
      this.setEncountersPagination(this.pagination)
      if (not(isEmpty(this.patient))) {
        this.hasLoadEncounters = false
        await this.getEmrPaginateEncounters(this.patient.id)
        this.encounters = filter(
          propEq('closed', 'status'),
          path(['rows'], this.emrEncounters),
        )
        this.resetEncountersList()
        this.hasLoadEncounters = true
      }
    },
    cleanFilters() {
      this.filters = {
        date: null,
        professionalId: null,
      }
      this.setEncountersFilters(this.filters)
    },
    resetPagination() {
      this.pagination = {
        page: 1,
        descending: true,
      }
      this.setEncountersPagination(this.pagination)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.filters-container--fixed
  position -webkit-sticky
  position sticky
  top 0
  z-index 3
  background-color white

.filters
  height 8rem
  padding 0 2rem

.filters__field
  margin-top 2rem

</style>
