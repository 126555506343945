<template>
  <div class="tw-px-12 tw-pt-10">
    <v-data-table
      class="user-feedback-table"
      :headers="headers"
      :items="userFeedbacks"
      :items-per-page="30"
      :page.sync="page"
      item-key="id"
      no-data-text="Sem itens para exibir"
      hide-default-footer
    >
      <template #item="userFeedback">
        <tr>
          <td>
            {{ userFeedback.item.user.name }}
          </td>
          <td>
            {{ userFeedback.item.createdAt | formatDate }}
          </td>
          <td>
            <v-tooltip
              top
            >
              <template #activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  @click="openViewUserFeedbackModal(userFeedback.item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>
                Visualizar Feedback
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div class="tw-grid tw-grid-cols-3 tw-items-center tw-mt-6">
      <v-pagination
        v-model="page"
        class="tw-col-start-2"
        :length="userFeedbacksNumberOfPages"
        :total-visible="10"
      />

      <div>
        Total: {{ userFeedbacksCount }}
      </div>
    </div>

    <view-user-feedback-modal
      v-if="shouldShowViewUserFeedbackModal"
      :user-feedback="viewUserFeedbackModalData"
      @close="closeViewUserFeedbackModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserFeedbackListContainer',
  components: {
    ViewUserFeedbackModal: () => import('./ViewUserFeedbackModal'),
  },
  data: () => ({
    page: 1,
    pagination: {},
    headers: [
      {
        text: 'Usuário',
        value: 'userFeedback.user.name',
        sortable: false,
      },
      {
        text: 'Data',
        value: 'userFeedback.createdAt',
        sortable: false,
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
    ],
    viewUserFeedbackModalData: null,
    shouldShowViewUserFeedbackModal: false,
  }),
  computed: {
    ...mapState({
      userFeedbacks: ({ userFeedback }) => userFeedback.userFeedbacks,
      userFeedbackPage: ({ userFeedback }) => userFeedback.userFeedbackPage,
      userFeedbacksCount: ({ userFeedback }) => userFeedback.userFeedbacksCount,
      userFeedbacksNumberOfPages: ({ userFeedback }) => userFeedback.userFeedbacksNumberOfPages,
      isLoading: ({ userFeedback }) => userFeedback.isLoading,
    }),
  },
  watch: {
    page(newValue) {
      if (newValue) {
        this.setUserFeedbackPage(this.page)
        this.listUserFeedback()
      }
    },
  },
  mounted() {
    this.listUserFeedback()
  },
  methods: {
    ...mapActions({
      listUserFeedback: 'userFeedback/listUserFeedback',
      setUserFeedbackPage: 'userFeedback/setUserFeedbackPage',
    }),
    openViewUserFeedbackModal(userFeedback) {
      this.viewUserFeedbackModalData = userFeedback
      this.shouldShowViewUserFeedbackModal = true
    },
    closeViewUserFeedbackModal() {
      this.shouldShowViewUserFeedbackModal = false
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/elements.styl'

.user-feedback-table >>> thead > tr
  background-color #f5f5f5
  box-shadow 0px 1px 8px rgba(0,0,0, .12)

.user-feedback-table >>> thead > tr > th
  border none !important
  font-size 1.1rem !important
  font-weight 500
  padding 8px 16px !important

.user-feedback-table >>> tbody > tr
  margin-top 16px
  box-shadow 0px 1px 2px rgba(0,0,0,.12) !important

.user-feedback-table >>> tbody > tr > td
  font-size 1rem !important
  font-weight 500
  padding 16px !important
</style>
