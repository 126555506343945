<template>
  <div>
    <component-title class="tw-bg-green-deep">
      <template #actions>
        <v-btn
          v-if="hasAccessToCreateHmo"
          color="primary"
          class="btn"
          data-testid="new-hmo--button"
          @click="showCreateHmoDialog = true"
        >
          Nova Operadora
        </v-btn>
      </template>
    </component-title>
    <contract-list-container />

    <create-hmo
      v-if="showCreateHmoDialog"
      @closeCreateHmoDialog="closeCreateHmoDialog"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreateHmo from '@/pages/HmoList/CreateHmo'
import { mapGetters } from 'vuex'
import ContractListContainer from './ContractListContainer'

export default {
  components: {
    ComponentTitle,
    ContractListContainer,
    CreateHmo,
  },
  data() {
    return {
      showCreateHmoDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    hasAccessToCreateHmo() {
      return this.userHasAccessToFunctionality.listHmo
    },
  },
  methods: {
    closeCreateHmoDialog() {
      this.showCreateHmoDialog = false
    },
  },
}
</script>
