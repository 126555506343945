<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          class="btn mr-4"
          color="primary"
          outlined
          @click="redirectToUnassignedOrders()"
        >
          Visualizar OS avulsas
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-btn
          color="primary"
          class="btn"
          @click="showCreateBillDialog = true"
        >
          Nova fatura
        </v-btn>
      </template>
    </component-title>

    <bill-list />

    <create-bill-dialog
      :show-dialog="showCreateBillDialog"
      @update:showDialog="setShowCreateBillDialog()"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import { mapActions } from 'vuex'
import moment from 'moment'
import CreateBillDialog from './CreateBillDialog'
import BillList from './BillList'

export default {
  components: {
    BillList,
    ComponentTitle,
    CreateBillDialog,
  },
  data() {
    return {
      showCreateBillDialog: false,
    }
  },
  async mounted() {
    await this.listHmo()
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
    }),
    setShowCreateBillDialog() {
      this.showCreateBillDialog = !this.showCreateBillDialog
    },
    redirectToUnassignedOrders() {
      const start = moment().subtract(1, 'month').format('YYYY-MM-DD')
      const end = moment().add(2, 'months').format('YYYY-MM-DD')


      this.$router.push({
        name: 'financesPage',
        query: {
          billId: 'null',
          startDate: start,
          endDate: end,
        },
      })
    },
  },
}
</script>
