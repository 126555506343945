<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          color="primary"
          class="btn"
          @click="showCreatePermissionDialog = true"
        >
          Nova Permissão
        </v-btn>
      </template>
    </component-title>
    <permission-list-container />

    <v-dialog
      v-model="showCreatePermissionDialog"
      transition="dialog-bottom-transition"
      persistent
      fullscreen
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <div class="tw-flex tw-items-center tw-w-full tw-justify-between tw-ml-8">
            <v-toolbar-title>Cadastrar Permissão</v-toolbar-title>

            <v-btn
              icon
              dark
              @click="showCreatePermissionDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>

        <create-permission
          @closeCreatePermissionDialog="closeCreatePermissionDialog"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreatePermission from '@/pages/PermissionList/CreatePermission'
import PermissionListContainer from './PermissionListContainer'

export default {
  components: {
    ComponentTitle,
    CreatePermission,
    PermissionListContainer,
  },
  data() {
    return {
      showCreatePermissionDialog: false,
    }
  },
  methods: {
    closeCreatePermissionDialog() {
      this.showCreatePermissionDialog = false
    },
  },
}
</script>
