export default {
  BIOLOGICAL_SEX: {
    MALE: {
      title: 'Masculino',
      value: 'M',
    },
    FEMALE: {
      title: 'Feminino',
      value: 'F',
    },
  },
  DOCUMENT_TYPES: {
    CRM: 'CRM',
    CRN: 'CRN',
    ABQ: 'ABQ',
    COREN: 'COREN',
    CRP: 'CRP',
    CRFA: 'CRFA',
    CRO: 'CRO',
    CREFITO: 'CREFITO',
    CREFONO: 'CREFONO',
    CFF: 'CFF',
    CRF: 'CRF',
  },
  STATES: {
    AC: 'AC',
    AL: 'AL',
    AM: 'AM',
    AP: 'AP',
    BA: 'BA',
    CE: 'CE',
    DF: 'DF',
    ES: 'ES',
    GO: 'GO',
    MA: 'MA',
    MG: 'MG',
    MS: 'MS',
    MT: 'MT',
    PA: 'PA',
    PB: 'PB',
    PE: 'PE',
    PI: 'PI',
    PR: 'PR',
    RJ: 'RJ',
    RN: 'RN',
    RO: 'RO',
    RR: 'RR',
    RS: 'RS',
    SC: 'SC',
    SE: 'SE',
    SP: 'SP',
    TO: 'TO',
  },
  IS_RQE: {
    TRUE: {
      LABEL: 'Sim',
      VALUE: true,
    },
    FALSE: {
      LABEL: 'Não',
      VALUE: false,
    },
  },
}
