<template>
  <v-timeline-item
    fill-dot
    color="eveningSea"
    large
    class="timeline-container"
    :class="{
      'timeline__patient-initals-fixed': isInsideEncounter,
    }"
  >
    <template v-slot:icon>
      <span class="tw-text-white">{{ patientName | initials }}</span>
    </template>

    <div class="tw-ml-8">
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mt-1">
        <div
          class="
            tw-flex
            tw-items-center
            tw-text-4xl
            tw-text-primary
            tw-font-medium
          "
        >
          <div v-if="isInsideEncounter">
            {{ patient | formatDisplayName }}
            <v-switch
              v-if="shouldShowImmediateCareVoucher"
              v-model="immediateCareVoucherSwitch"
              class="tw-mt-3 tw-ml-2"
              label="Amparo Agora"
              color="green"
              @change="handleUpdateImmediateCareVoucher"
            />
          </div>

          <router-link
            v-else
            class="tw-no-underline"
            :to="{ name: 'patientProfile', params: { id: patient.id }}"
          >
            {{ patient | formatDisplayName }}
          </router-link>
        </div>

        <div class="tw-flex tw-justify-end tw-items-center">
          <slot />

          <div
            v-if="isInsideEncounter"
            class="tw-font-bold"
          >
            <span class="tw-text-gray-soft tw-mr-2">Tempo da consulta:</span>
            <span class="tw-text-mine-shaft">{{ encounterTime }}</span>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-end tw-mt-4">
        <v-btn
          class="tw--mr-4"
          text
          :color="sensitiveDataButtonColor"
          @click="openUpdatePatientSensitiveDataDialog"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          <span class="tw-underline">
            {{ sensitiveDataButtonText }}
          </span>
        </v-btn>
      </div>

      <div
        class="
          tw-flex
          tw-flex-wrap
          tw-gap-x-8
          tw-gap-y-4
          tw-mt-8
          tw-mb-6
          tw-text-nowrap
        "
      >
        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">{{ patient | getIdentifierDocumentLabel }}:</span>
          <span class="tw-text-mine-shaft">{{ patient | getIdentifierDocument }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Sexo biológico:</span>
          <span class="tw-text-mine-shaft">{{ patient.biologicalSex | biologicalSex }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Idade:</span>
          <span class="tw-text-mine-shaft">{{ patient.birthDate | formatAge }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Raça/Cor autodeclarada:</span>
          <span class="tw-text-mine-shaft">{{ patient.race | formatPatientRace }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Religião:</span>
          <span class="tw-text-mine-shaft">{{ patient.religion || '*' }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Estado Civil:</span>
          <span class="tw-text-mine-shaft">{{ patient.maritalStatus | formatMaritalStatus }}</span>
        </div>

        <div
          v-if="patient.isForeign"
          class="tw-font-bold"
        >
          <span class="tw-text-gray-soft tw-mr-2">Nacionalidade:</span>
          <span class="tw-text-mine-shaft">{{ patient.nationality || '*' }}</span>
        </div>

        <div
          v-if="patient.score"
          class="tw-font-bold"
        >
          <span class="tw-text-gray-soft tw-mr-2">Pontuação</span>
          <span class="tw-text-mine-shaft">{{ patient.score/100 }}</span>
        </div>

        <div
          v-if="patient.careTeam"
          class="tw-font-bold"
        >
          <span class="tw-text-gray-soft tw-mr-2">Equipe Assistencial:</span>
          <span class="tw-text-mine-shaft">{{ patient.careTeam.name }}</span>
        </div>

        <div class="tw-font-bold">
          <span class="tw-text-gray-soft tw-mr-2">Código do Prontuário:</span>
          <span class="tw-text-mine-shaft">{{ patient.id }}</span>
        </div>

        <div
          v-if="hasActivationJourney"
          class="tw-font-bold"
        >
          <span class="tw-text-primary tw-mr-2">
            Jornada de Ativação
          </span>
          <span class="tw-text-mine-shaft">
            <v-tooltip top>
              Status Atual - Início em
              {{ activationJourney.createdAt | convertUtcToBrDate }}
              <template v-slot:activator="{ on }">
                <span
                  class="tw-underline tw-cursor-pointer"
                  v-on="on"
                  @click="openActivationJourneyDialog"
                >
                  {{ activationJourney.journeyStatus | formatActivationJourneyStatus }}
                </span>
              </template>
            </v-tooltip>
          </span>
        </div>
      </div>

      <div
        v-if="shouldShowPatientDoctorNotes"
        class="tw-mb-6"
      >
        <div class="tw-font-bold tw-text-gray-soft tw-mb-2">
          Sobre o paciente
          <a
            class="tw-text-primary tw-underline tw-ml-1 tw-font-medium"
            @click="handlePatientDoctorNotes"
          >
            <span v-if="isEditingPatientDoctorNotes">(salvar)</span>
            <span v-else>(editar)</span>
          </a>
        </div>

        <v-textarea
          v-if="isEditingPatientDoctorNotes"
          v-model="patientDoctorNotes"
          rows="1"
          counter
          filled
          auto-grow
          :rules="doctorNotesFieldRules"
        >
          <template v-slot:counter>
            <span class="tw-text-xs tw-font-normal">
              {{ patientDoctorNotes.length }} / 1000
            </span>
          </template>
        </v-textarea>

        <div
          v-else
          class="tw-font-normal tw-text-gray-dark"
        >
          {{ patientDoctorNotes || 'Não há informações sobre o paciente' }}
        </div>
      </div>

      <div
        v-if="suggestedExamsData.length > 0"
        class="tw-mb-6"
      >
        <span class="tw-text-gray-soft tw-mr-2 tw-font-bold">
          Coleta de exames:
        </span>

        <div
          v-if="isInsideEncounter"
          class="tw-mb-4"
        >
          <a
            v-if="justificationForEmptyRequiredExams"
            class="tw-flex tw-items-center tw-text-danger"
            @click="setJustificationForEmptyRequiredExams(null)"
          >
            <v-icon
              size="16"
              color="error"
              left
            >
              mdi-message-bulleted-off
            </v-icon>
            <span class="tw-underline tw-text-xs tw-font-normal">
              Cancelar justificativa
            </span>
          </a>

          <a
            v-else
            class="tw-flex tw-items-center"
            @click="openJustifyMenu($event)"
          >
            <v-icon
              size="16"
              color="medium-grey"
              left
            >
              mdi-message-bulleted-off
            </v-icon>
            <span class="tw-text-gray-soft tw-underline tw-text-xs tw-font-normal">
              Não preencher campos
            </span>
          </a>

          <emr-field-justify-menu
            title="Por quê os campos não podem ser preenchidos?"
            :show-justify-menu="showJustifyEmptyEmrFieldsMenu"
            :mouse-position-x="mousePositionX"
            :mouse-position-y="mousePositionY"
            @close="closeJustifyEmptyEmrFieldsMenu"
            @save-justification="saveJustificationForEmptyFields"
          />
        </div>

        <div class="tw-flex tw-flex-wrap tw-mt-2 tw-gap-x-8">
          <div
            v-for="(exam, index) of suggestedExamsData"
            :key="index"
          >
            <span class="tw-text-gray-soft tw-mr-2 tw-font-bold tw-capitalize">
              {{ exam.label }}:
              <span :class="getExamDateColorClass(exam)">
                {{ exam.requiredDate }}
              </span>
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="!isPatientHmoPackageTypeNone"
        class="tw-mb-6"
      >
        <patient-medical-follow-up-accept-info-chip
          :patient="patient"
        />
      </div>

      <div v-if="shouldShowPatientTags">
        <v-divider />

        <emr-patient-tags
          v-if="shouldShowPatientTags"
          class="tw-py-4"
        />
      </div>

      <emr-form-warning />

      <v-divider />
    </div>

    <emr-update-patient-sensitive-data
      v-if="isOpenUpdatePatientSensitiveDataDialog"
      @close="closeUpdatePatientSensitiveDataDialog"
    />

    <activation-journey-modal
      v-if="isOpenActivationJourneyModal"
      @close="closeActivationJourneyModal"
    />

    <emr-add-on-activation-journey
      v-if="isOpenAddOnActivationJourney"
      @closeDialog="closeAddOnActivationJourneyDialog"
    />
  </v-timeline-item>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  any,
  equals,
  isEmpty,
  isNil,
  not,
  path,
  pick,
  values,
  cond,
  always,
  gte,
  T,
} from 'ramda'
import { HMO } from 'amparo-enums'

import PatientMedicalFollowUpAcceptInfoChip from '@/components/Common/PatientMedicalFollowUpAcceptInfoChip'
import EmrFormWarning from './EmrFormWarning'
import EmrPatientTags from './EmrPatientTags'

export default {
  components: {
    EmrPatientTags,
    EmrFormWarning,
    PatientMedicalFollowUpAcceptInfoChip,
    EmrUpdatePatientSensitiveData: () => import('@/pages/Emr/EmrUpdatePatientSensitiveData'),
    EmrAddOnActivationJourney: () => import('@/pages/Emr/EmrAddOnActivationJourney'),
    EmrFieldJustifyMenu: () => import('@/components/Common/EmrFieldJustifyMenu'),
    ActivationJourneyModal: () => import(
      '@/pages/PatientProfile/ActivationJourneyModal'
    ),
  },
  props: {
    isInsideEncounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      immediateCareVoucherData: null,
      immediateCareVoucherSwitch: false,
      isOpenUpdatePatientSensitiveDataDialog: false,
      isOpenActivationJourneyModal: false,
      isOpenAddOnActivationJourney: false,
      showJustifyEmptyEmrFieldsMenu: false,
      mousePositionX: 0,
      mousePositionY: 0,
      isEditingPatientDoctorNotes: false,
      patientDoctorNotes: '',
      doctorNotesFieldRules: [v => v.length <= 1000 || 'Max 1000 caracteres'],
    }
  },
  computed: {
    ...mapState({
      justificationForEmptyRequiredExams: ({ emr }) => emr.justificationForEmptyRequiredExams,
    }),
    ...mapGetters({
      encounterTime: 'emr/encounterTime',
      patient: 'emr/patient',
      user: 'authentication/user',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
      suggestedExamsData: 'emr/suggestedExamsData',
      activationJourney: 'patient/activationJourney',
      hasActivationJourney: 'patient/hasActivationJourney',
      isPatientHmoPackageTypeNone: 'emr/isPatientHmoPackageTypeNone',
    }),
    shouldShowPatientTags() {
      return this.userHasAccessToFunctionality.careLineTags
    },
    shouldShowPatientDoctorNotes() {
      return this.userHasAccessToFunctionality.emrPatientDoctorNotes
    },
    patientName() {
      return this.$filters.formatDisplayName(this.patient)
    },
    shouldShowImmediateCareVoucher() {
      return equals(path(['healthMaintenanceOrganization', 'id'], this.patient), HMO.rita)
        && not(equals('done', path(['status'], this.immediateCareVoucherData)))
    },
    sensitiveDataIsFullfiled() {
      const sensitiveData = pick(
        [
          'race',
          'religion',
          'maritalStatus',
        ],
        this.patient,
      )

      return !(
        any(isNil, values(sensitiveData))
        || (this.patient.isForeign && isNil(this.patient.nationality))
      )
    },
    sensitiveDataButtonColor() {
      return this.sensitiveDataIsFullfiled ? 'primary' : 'danger'
    },
    sensitiveDataButtonText() {
      return this.sensitiveDataIsFullfiled
        ? 'Editar dados do Paciente'
        : 'Preencher Campos Obrigatórios'
    },
  },
  watch: {
    'patient.resume': function setDoctorNotes() {
      this.patientDoctorNotes = path(['patient', 'resume', 'doctorNotes'], this) || ''
    },
  },
  async created() {
    await this.loadPatient()
    this.patientDoctorNotes = path(['patient', 'resume', 'doctorNotes'], this) || ''
    await this.getCareLineSuggestedExams({ patientId: this.$route.params.id })
  },
  async activated() {
    await this.loadPatient()
    if (this.isInsideEncounter) {
      this.getEncounterTime()
      this.setImmediateCareVoucher(this.patient.id)
    }
  },
  mounted() {
    this.updatePatientSpecialConditions({
      id: this.patient.id,
    })
  },
  methods: {
    ...mapActions({
      getEncounterTime: 'emr/getEncounterTime',
      getImmediateCareVoucher: 'patientImmediateCareVoucher/getPatientImmediateCareVoucher',
      createImmediateCareVoucher: 'patientImmediateCareVoucher/createPatientImmediateCareVoucher',
      updateImmediateCareVoucher: 'patientImmediateCareVoucher/updatePatientImmediateCareVoucher',
      updatePatientSpecialConditions: 'patient/updatePatientSpecialConditions',
      setJustificationForEmptyRequiredExams: 'emr/setJustificationForEmptyRequiredExams',
      setEmrPatient: 'emr/setEmrPatient',
      setSnackbar: 'snackbar/setSnackbar',
      getCareLineSuggestedExams: 'emr/getCareLineSuggestedExams',
    }),
    getExamDateColorClass({ daysLeftToRequired }) {
      return cond([
        [gte(0), always('tech-red--text')],
        [gte(10), always('tech-yellow--text')],
        [T, always('amparo-gray--text')],
      ])(daysLeftToRequired)
    },
    closeJustifyEmptyEmrFieldsMenu() {
      this.showJustifyEmptyEmrFieldsMenu = false
    },
    openJustifyMenu(event) {
      this.mousePositionX = event.clientX
      this.mousePositionY = event.clientY
      this.showJustifyEmptyEmrFieldsMenu = true
    },
    saveJustificationForEmptyFields(justification) {
      this.setJustificationForEmptyRequiredExams(justification)
      this.closeJustifyEmptyEmrFieldsMenu()
    },
    async setImmediateCareVoucher() {
      this.immediateCareVoucherData = await this.getImmediateCareVoucher(this.patient.id)
      const immediateCareVoucherStatus = path(['status'], this.immediateCareVoucherData)
      this.immediateCareVoucherSwitch = not(
        isNil(immediateCareVoucherStatus)
        || equals('canceled', immediateCareVoucherStatus),
      )
    },
    async handleUpdateImmediateCareVoucher() {
      if (this.immediateCareVoucherSwitch && isNil(this.immediateCareVoucherData)) {
        await this.createImmediateCareVoucher(this.patient.id)
      } else {
        await this.updateImmediateCareVoucher({
          patientId: this.patient.id,
          status: this.immediateCareVoucherSwitch ? 'pending' : 'canceled',
        })
      }
      await this.setImmediateCareVoucher()
    },
    async loadPatient() {
      if (isEmpty(this.patient)) {
        await this.setEmrPatient(this.$route.params.id)
      }
    },
    openUpdatePatientSensitiveDataDialog() {
      this.isOpenUpdatePatientSensitiveDataDialog = true
    },
    closeUpdatePatientSensitiveDataDialog() {
      this.isOpenUpdatePatientSensitiveDataDialog = false
    },
    openActivationJourneyModal() {
      this.isOpenActivationJourneyModal = true
    },
    closeActivationJourneyModal() {
      this.isOpenActivationJourneyModal = false
    },
    openAddOnActivationJourneyDialog() {
      this.isOpenAddOnActivationJourney = true
    },
    closeAddOnActivationJourneyDialog() {
      this.isOpenAddOnActivationJourney = false
    },
    openActivationJourneyDialog() {
      if (!equals(this.activationJourney.status, 'finished')) {
        this.openActivationJourneyModal()
      } else {
        this.openAddOnActivationJourneyDialog()
      }
    },
    handlePatientDoctorNotes() {
      if (this.isEditingPatientDoctorNotes) {
        this.$http.patch(`emr/patient_medical_resume/doctor_notes/${this.patient.id}`, {
          doctorNotes: this.patientDoctorNotes,
        })
          .then(() => {
            this.isEditingPatientDoctorNotes = false
          })
          .catch(() => {
            this.setSnackbar({
              status: 'error',
              message: 'Não foi possível concluir a solicitação, tente novamente mais tarde',
            })
          })

        return
      }

      this.isEditingPatientDoctorNotes = true
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'

.timeline__patient-medical-team
  margin-right 10px

.timeline__patient-initals-fixed >>> .v-timeline-item__inner-dot
  position fixed

.timeline-container >>> .v-timeline-item__divider > .v-timeline-item__dot
  width 53px

.-container
  display flex
  flex-wrap wrap
  column-gap 4rem
  row-gap 1rem
  margin-top 2rem
  margin-bottom 1rem

.
  &--label
    color $gray
  &--value
    color $mine-shaft
    margin-left .7rem

</style>
