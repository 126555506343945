<template>
  <div>
    <div class="tw-grid tw-p-4 tw-gap-4 lg:tw-grid-cols-3 tw-bg-green-light">
      <v-autocomplete
        v-model="filters.selectedClinic"
        :items="clinics"
        item-text="name"
        item-value="id"
        label="Unidade"
        no-data-text="Digite para buscar"
        append-icon=""
        hide-details
        clearable
        filled
        return-object
        @change="setFilters(filters)"
      />

      <v-autocomplete
        v-model="filters.selectedSpeciality"
        :items="specialities"
        item-text="name"
        item-value="id"
        filled
        clearable
        label="Especialidade"
        hide-details
        no-data-text="Digite para buscar"
        append-icon=""
        return-object
        @change="setFilters(filters)"
      />

      <search-professional
        :key="`${clinicId + professionalId}`"
        :clinic-id="clinicId"
        :professional-id="professionalId"
        :hide-details="true"
        @input="updateProfessional($event)"
      />
    </div>

    <div
      v-if="!hasFiltersFilled"
      class="tw-m-6 tw-text-2xl tw-font-light"
    >
      Preencha os campos acima para buscar.
    </div>

    <div
      v-else-if="!areFiltersFulfilled"
      class="tw-mt-6"
    >
      <agenda-settings-full-list
        v-if="!loadingRestriction"
        instance-type="restrictions"
        @setFilters="setFilters"
      />
    </div>

    <div
      v-else
      class="tw-mx-6"
    >
      <div class="tw-flex tw-my-0 tw-justify-center">
        <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/4">
          <v-btn
            class="font-weight-bold my-6"
            color="primary"
            block
            large
            @click="openRestrictionDialog()"
          >
            Nova Restrição
          </v-btn>
        </div>
      </div>

      <loading-agenda-settings
        v-if="loadingRestriction"
      />

      <v-data-table
        v-else
        :headers="headers"
        :items="formattedRestrictions"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.restriction="{ value }">
          <div class="py-4">
            <span
              v-for="(item, index) in value"
              :key="index"
            >
              {{ item }}
              <br>
            </span>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            text
            icon
            @click="openEditDialog(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
            @click="openArchiveDialog(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.history="{ item }">
          <v-icon
            v-if="item.activityLog"
            @click="openHistoryDialog(item.activityLog)"
          >
            mdi-clock-time-eight-outline
          </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="archiveDialog"
      max-width="460"
    >
      <v-card>
        <div class="tw-flex tw-justify-center">
          <v-card-text>
            Deseja realmente remover esta restrição?
          </v-card-text>
        </div>
        <v-card-actions class="tw-justify-end">
          <v-btn
            color="green darken-1"
            class="ml-auto"
            text
            @click="archiveDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="error"
            class="tw-px-6"
            @click="archiveRestriction()"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialogRegisterRestrictionPattern"
      fullscreen
      transition="dialog-bottom-transition"
      @keydown.esc="closeRestrictionDialog"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Nova restrição de agenda</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="closeRestrictionDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <agenda-register-restriction-pattern
          v-if="showDialogRegisterRestrictionPattern"
          :key="componentKey"
          :clinic="filters.selectedClinic"
          :speciality="filters.selectedSpeciality"
          :professional="filters.selectedProfessional"
          :edit="itemToEdit"
          @closeRestrictionDialog="closeRestrictionDialog"
          @getRestrictions="getRestrictions"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showHistoryDialog"
      max-width="700px"
    >
      <v-card class="pa-4 rounded-md">
        <v-card-title>
          Histórico de Alterações
          <v-btn
            class="ml-auto"
            icon
            @click="closeHistoryDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(history, index) in restrictionHistory"
              :key="`restriction-${index}`"
              class="pa-0"
            >
              <v-list-item-content>
                <v-list-item-title class="mb-2">
                  {{ history.changedAt | dateWithBrTime }}
                  -
                  {{ history.changedBy.name }}
                </v-list-item-title>
                <div v-if="formatRestrictionColumn(history).length === 0">
                  Sem restrições
                </div>
                <div
                  v-for="({label, text}, textIndex) in formatHistoryRestriction(history)"
                  v-else
                  :key="`restriction-item-${textIndex}`"
                  class="mb-1"
                >
                  <span class="font-weight-medium">{{ label }}: </span> {{ text }}
                  <br>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  all,
  any,
  clone,
  isEmpty,
  isNil,
  not,
  values,
  path,
  map,
  join,
  propEq,
  find,
  length,
  reject,
} from 'ramda'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import SearchProfessional from '@/components/Common/SearchProfessional'
import { APPOINTMENT } from 'amparo-enums'
import AgendaRegisterRestrictionPattern from './AgendaRegisterRestrictionPattern'

export default {
  name: 'AgendaSettingsRestriction',
  components: {
    AgendaRegisterRestrictionPattern,
    SearchProfessional,
    LoadingAgendaSettings: () => import('@/pages/AgendaSettings/LoadingAgendaSettings'),
    AgendaSettingsFullList: () => import('@/pages/AgendaSettings/AgendaSettingsFullList'),
  },
  data: () => ({
    componentKey: 0,
    editDialog: false,
    archiveDialog: false,
    itemToEdit: null,
    itemToDelete: {},
    showDialogRegisterRestrictionPattern: false,
    showHistoryDialog: false,
    restrictionHistory: null,
    headers: [
      {
        value: 'history',
      },
      {
        text: 'Início',
        value: 'startDate',
      },
      {
        text: 'Fim',
        value: 'endDate',
      },
      {
        text: 'Hora início',
        value: 'startTime',
      },
      {
        text: 'Hora fim',
        value: 'endTime',
      },
      {
        text: 'Restrição',
        value: 'restriction',
      },
      {
        text: 'Intervalo',
        value: 'slotInterval',
      },
      {
        text: 'Ação',
        value: 'action',
        sortable: false,
      },
    ],
    filters: {
      selectedClinic: null,
      selectedSpeciality: null,
      selectedProfessional: null,
    },
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      specialities: 'speciality/specialities',
      professionals: 'professional/professionals',
      availableRestrictions: 'agenda/availableRestrictions',
      loadingRestriction: 'agenda/loadingRestriction',
      hmoList: 'healthMaintenanceOrganization/hmos',
      professionList: 'profession/professions',
    }),
    areFiltersFulfilled() {
      return not(any(isNil, values(this.filters)))
    },
    hasFiltersFilled() {
      return not(all(isNil, values(this.filters)))
    },
    clinicId() {
      return path(['selectedClinic', 'id'], this.filters)
    },
    professionalId() {
      return path(['selectedProfessional', 'id'], this.filters)
    },
    appointmentTypeList() {
      return values(APPOINTMENT.types)
    },
    formattedRestrictions() {
      return map(
        this.formatRestrictionData,
        this.availableRestrictions,
      )
    },
  },
  async mounted() {
    await this.listHmo()
    await this.listProfession()
  },
  methods: {
    ...mapActions({
      listRestriction: 'agenda/listRestriction',
      updateRestriction: 'agenda/updateRestriction',
      setRestrictionFiltersData: 'agenda/setRestrictionFiltersData',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      listProfession: 'profession/listProfession',
    }),
    formatHistoryRestriction({
      appointmentType,
      hmos,
      scheduleByPatient,
      scheduleByOwner,
      userProfessions,
    }) {
      const formatListText = (defaultList, list) => {
        if (length(defaultList) === length(list) || list === null) return 'Todos permitidos'
        if (isEmpty(list) || list === undefined) return 'Nenhum permitido'

        return `${join(', ', map(item => item.name || item, list))}`
      }

      const isAllowed = condition => (condition
        ? 'Permitido'
        : 'Não permitido')

      return [
        {
          label: 'Modalidade de Consulta',
          text: formatListText(this.appointmentTypeList, appointmentType),
        },
        {
          label: 'Planos de Saúde Permitidos',
          text: formatListText(this.hmoList, hmos),
        },
        {
          label: 'Profissões Permitidas',
          text: formatListText(this.professionList, userProfessions),
        },
        {
          label: 'Agendamento pelo Meu Amparo',
          text: isAllowed(scheduleByPatient),
        },
        {
          label: 'Agendamento pelo profissional da agenda',
          text: isAllowed(scheduleByOwner),
        },
      ]
    },
    formatRestrictionColumn(column) {
      const {
        appointmentType,
        hmos,
        scheduleByPatient,
        scheduleByOwner,
      } = column

      const appointmentTypeText = this.formatRestrictionText(
        this.appointmentTypeList,
        appointmentType,
        'Modalidade de Consulta',
      )

      const hmoText = this.formatRestrictionText(
        this.hmoList,
        hmos,
        'Planos de Saúde Permitidos',
      )

      const scheduleByPatientText = !scheduleByPatient
        ? 'Agendamento pelo Meu Amparo: Não permitido'
        : null

      const scheduleByOwnerText = !scheduleByOwner
        ? 'Agendamento pelo profissional da agenda: Não permitido'
        : null

      return reject(
        isNil,
        [
          appointmentTypeText,
          hmoText,
          scheduleByPatientText,
          scheduleByOwnerText,
        ],
      )
    },
    formatRestrictionData(restriction) {
      const {
        startDate,
        endDate,
        startTime,
        endTime,
      } = restriction

      return {
        ...restriction,
        startDate: this.$filters.formatDate(startDate),
        endDate: this.$filters.formatDate(endDate),
        startTime: this.$filters.removeSecondsFromTime(startTime),
        endTime: this.$filters.removeSecondsFromTime(endTime),
        restriction: this.formatRestrictionColumn(restriction),
      }
    },
    formatRestrictionText(defaultList, list, label) {
      if (length(defaultList) === length(list)) return ''

      if (list === null) return `${label}: Todos permitidos`
      if (isEmpty(list) || list === undefined) return `${label}: Nenhum permitido`

      return `${label}: ${join(', ', map(item => item.name || item, list))}`
    },
    openRestrictionDialog() {
      this.showDialogRegisterRestrictionPattern = true
    },
    closeRestrictionDialog() {
      this.showDialogRegisterRestrictionPattern = false
      this.itemToEdit = null
    },
    openHistoryDialog(history) {
      this.showHistoryDialog = true
      this.restrictionHistory = history
    },
    closeHistoryDialog() {
      this.showHistoryDialog = false
      this.restrictionHistory = null
    },
    async setFilters(filters) {
      this.filters = filters
      this.setRestrictionFiltersData(clone(this.filters))
      await this.getRestrictions()
    },
    async getRestrictions() {
      if (this.hasFiltersFilled) {
        this.listRestriction()
      }
    },
    openEditDialog({ id }) {
      this.itemToEdit = clone(
        find(propEq(id, 'id'))(this.availableRestrictions),
      )
      this.openRestrictionDialog()
    },
    openArchiveDialog(itemToDetele) {
      this.itemToDelete = itemToDetele
      this.archiveDialog = true
    },
    async archiveRestriction() {
      const attributes = { storedAt: moment().format('YYYY-MM-DD HH:mm') }
      await this.updateRestriction({
        id: this.itemToDelete.id,
        attributes,
      })
      await this.getRestrictions()
      this.archiveDialog = false
    },
    updateProfessional(event) {
      this.filters.selectedProfessional = event
      this.setFilters(this.filters)
      this.listRestriction()
    },
  },
}
</script>
