<template>
  <v-flex
    v-if="attendanceList.length > 0"
    column
    align-center
  >
    <virtual-reception-attendance
      v-for="attendance of attendanceList"
      :key="attendance.id"
      :attendance="attendance"
      :is-closed="isClosed"
      class="attendance__container"
      @updateList="updateList"
    />
  </v-flex>
  <v-flex
    v-else
    class="components__default-message"
    ml-4
  >
    Não há atendimentos.
  </v-flex>
</template>

<script>
import VirtualReceptionAttendance from './VirtualReceptionAttendance'

export default {
  components: {
    VirtualReceptionAttendance,
  },
  props: {
    attendanceList: {
      type: Array,
      required: false,
      default: () => [],
    },
    isClosed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateList() {
      this.$emit('updateList')
    },
  },
}
</script>

<style lang="stylus" scoped>
.attendance__container
  margin-top .7rem
  margin-bottom @margin-top

</style>
