<template>
  <div>
    <v-data-table
      :key="key"
      :headers="headers"
      :items="careLineAges"
      hide-default-footer
      disable-pagination
      :search="search"
      no-data-text="Nenhum diagnóstico ativo"
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.initialAge }}</td>
          <td>{{ item.endAge }}</td>
          <td>
            <v-btn
              icon
              dark
              @click="showDeleteDialog(item.id)"
            >
              <v-icon color="error">
                mdi-close-circle
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="450"
    >
      <v-card class="tw-p-3">
        <v-card-title class="tw-text-2xl">
          Deseja realmente excluir?
        </v-card-title>
        <v-card-text>
          <div class="tw-grid tw-grid-cols-2 tw-gap-x-4 tw-mt-4">
            <v-btn
              class="btn btn-primary"
              block
              @click="closeDeleteDialog"
            >
              Cancelar
            </v-btn>
            <v-btn
              block
              color="error"
              @click="deleteActiveAge"
            >
              Excluir
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { inc } from 'ramda'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Nome da Faixa',
          value: 'name',
        },
        {
          text: 'Idade Inicial',
          value: 'initialAge',
        },
        {
          text: 'Idade Final',
          value: 'endAge',
        },
        {
          text: 'Excluir',
          value: '',
          sortable: false,
          width: '5%',
        },
      ],
      key: 0,
      deleteDialog: false,
      ageToDelete: null,
      refreshInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      careLineAges: 'careLine/careLineAges',
    }),
  },
  mounted() {
    this.setRefreshListInterval()
  },
  beforeDestroy() {
    this.clearRefreshInterval()
  },
  deactivated() {
    this.clearRefreshInterval()
  },
  methods: {
    ...mapActions({
      listCareLineAge: 'careLine/listCareLineAge',
      deleteCareLineAge: 'careLine/deleteCareLineAge',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    setRefreshListInterval() {
      const REFRESH_TIME = 30000
      this.refreshInterval = setInterval(() => {
        this.listCareLineAge()
      }, REFRESH_TIME)
    },
    clearRefreshInterval() {
      clearInterval(this.refreshInterval)
    },
    assignmentStatusIcon({ assignmentStatus }) {
      const icons = {
        succeded: 'mdi-check',
        processing: 'mdi-progress-clock',
        failed: 'mdi-alert-circle-outline',
        queue: 'mdi-progress-clock',
      }
      return icons[assignmentStatus] || ''
    },
    assignmentStatusMessage({ assignmentStatus }) {
      const messages = {
        succeded: 'Concluído',
        processing: 'Atribuindo pacientes à linha de cuidado',
        failed: 'Erro ao atribuir pacientes à linha de cuidado, tente novamente',
        queue: 'Aguardando para atribuir pacientes à linha de cuidado',
      }
      return messages[assignmentStatus] || ''
    },
    assignmentStatusColor({ assignmentStatus }) {
      const colors = {
        succeded: 'primary',
        processing: 'warning',
        failed: 'error',
        queue: 'warning',
      }
      return colors[assignmentStatus] || ''
    },
    showDeleteDialog(id) {
      this.deleteDialog = true
      this.diagnosticToDelete = id
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    async deleteActiveAge() {
      try {
        await this.deleteCareLineAge(this.diagnosticToDelete)
        await this.listCareLineAge()
        this.setSnackbar({
          status: 'success',
          message: 'Faixa etária excluida com sucesso',
        })
        this.closeDeleteDialog()
      } catch (error) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao deletar faixa etária',
        })
      }
    },
    resetTable() {
      this.key = inc(this.key)
    },
  },
}
</script>
