<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          color="primary"
          class="btn"
          @click="showCreateItemDialog = true"
        >
          Novo Item
        </v-btn>
      </template>
    </component-title>

    <item-list-container />

    <create-item
      v-if="showCreateItemDialog"
      @closeDialog="showCreateItemDialog = false"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreateItem from '@/pages/ItemList/CreateItem'
import ItemListContainer from './ItemListContainer'

export default {
  components: {
    ComponentTitle,
    CreateItem,
    ItemListContainer,
  },
  data() {
    return {
      showCreateItemDialog: false,
    }
  },
}
</script>

<style>
</style>
