<template>
  <main class="tw-px-12 tw-py-8">
    <section class="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-2">
      <div>
        <p class="tw-mb-8 tw-text-base tw-font-normal">
          Você pode importar pacientes simultâneos a partir de um ou vários arquivos CSV.
          <strong>Fique atento ao padrão</strong> do seu arquivo para que a importação
          seja feita com sucesso.
        </p>

        <v-btn
          color="primary"
          class="tw-mb-8 tw-capitalize tw-text-base tw-tracking-normal tw-font-semibold"
          outlined
          small
          @click="downloadExampleFile"
        >
          Download do CSV padrão
          <v-icon right>
            mdi-download
          </v-icon>
        </v-btn>

        <section class="tw-grid tw-grid-cols-3 tw-gap-x-6">
          <v-autocomplete
            v-model="hmoSelected"
            :items="hmos"
            item-text="name"
            return-object
            label="Operadora"
            no-data-text="Digite para buscar"
            append-icon=""
            filled
            :error="$v.hmoSelected.$error"
            @blur="$v.hmoSelected.$touch()"
            @input="changePlanList"
          />

          <v-autocomplete
            v-model="healthProductSelected"
            :items="healthProductList"
            item-text="name"
            return-object
            label="Plano"
            no-data-text="Escolha a operadora"
            append-icon=""
            filled
            :error="$v.healthProductSelected.$error"
            @blur="$v.healthProductSelected.$touch()"
          />

          <div>
            <span class="tw-mb-2">
              Ação a ser realizada:
            </span>
            <v-radio-group
              v-model="selectedAction"
              class="mt-0"
              row
            >
              <v-radio
                v-for="action in actionList"
                :key="action.id"
                :label="action.name"
                :value="action.id"
                :error="$v.selectedAction.$error"
                color="primary"
                @blur="$v.selectedAction.$touch()"
              />
            </v-radio-group>
          </div>
        </section>
      </div>

      <div>
        <vue-dropzone
          id="myDropzone"
          ref="myDropzone"
          class="tw-py-10 tw-px-8"
          :options="dropzoneOptions"
          :use-custom-slot="true"
          @vdropzone-sending="validateParams"
          @vdropzone-queue-complete="listFileQueues"
        >
          <figure class="tw-mb-6">
            <v-icon
              class="dropzone-custom-title__icon"
              size="50px"
              color="light-green"
            >
              mdi-file-upload-outline
            </v-icon>
          </figure>
          <p class="dropzone-custom-message">
            Preencha os campos e escolha ou arraste os dados dos pacientes para importar.
          </p>
        </vue-dropzone>
      </div>
    </section>

    <section class="tw-mt-6 tw-mb-4">
      <h2 class="tw-mb-6">
        Arquivos Importados
      </h2>

      <v-data-table
        :headers="headers"
        :items="queues"
        class="patient-import-table table--striped"
        no-data-text="O plano não possui nenhum arquivo de importação de pacientes"
        disable-sort
        hide-default-footer
      >
        <template v-slot:item="queue">
          <tr>
            <td>
              {{ queue.item.originalFilename }}
            </td>
            <td>
              {{ formatProgressCount(
                queue.item.importSuccessCount,
                queue.item.importErrorCount,
                queue.item.importTotalCount,
              ) }}
            </td>
            <td>
              {{ formatAction(queue.item.operation) }}
            </td>
            <td :class="rateClass(queue.item)">
              <span>
                {{ formatRate(queue.item.importSuccessCount, queue.item.importTotalCount) }}
              </span>
            </td>
            <td>
              {{ queue.item.createdAt | formatDate }}
            </td>
            <td>
              {{ queue.item.user?.name }}
            </td>
            <td>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click="openShowPatientImportListModal(queue.item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </section>


    <section
      v-if="numberOfPages > 1"
      class="tw-flex tw-justify-center"
    >
      <v-pagination
        v-model="page"
        :length="numberOfPages"
        :total-visible="10"
        @input="changePage(page)"
        @next="changePage(page + 1)"
        @previous="changePage(page - 1)"
      />
    </section>

    <patient-import-list
      v-if="shouldShowPatientImportListModal"
      :patient-import-data="patientImportListModalItem"
      @closeDialog="closeShowPatientImportListModal"
    />
  </main>
</template>

<script>
import {
  isNil,
  length,
  head,
  equals,
} from 'ramda'
import vue2Dropzone from 'vue2-dropzone'
import {
  required,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import PatientImportList from './PatientImportList'

export default {
  name: 'PatientImportContainer',
  components: {
    VueDropzone: vue2Dropzone,
    PatientImportList,
  },
  data() {
    return {
      hmoSelected: null,
      healthProductSelected: null,
      healthProductList: [],
      selectedAction: 'insert',
      headers: [
        {
          text: 'Arquivo',
        },
        {
          text: 'Progresso da importação',
        },
        {
          text: 'Ação',
        },
        {
          text: 'Taxa de sucesso',
        },
        {
          text: 'Data do carregamento',
        },
        {
          text: 'Responsável pelo carregamento',
        },
        {
          text: '',
        },
      ],
      actionList: [
        {
          name: 'Adicionar',
          id: 'insert',
        },
        {
          name: 'Arquivar',
          id: 'delete',
        },
      ],
      updateFilesInterval: null,
      shouldShowPatientImportListModal: false,
      patientImportListModalItem: {},
      page: 1,
    }
  },
  computed: {
    ...mapGetters({
      hmos: 'healthMaintenanceOrganization/hmos',
      queues: 'importFileQueue/queues',
      numberOfPages: 'importFileQueue/numberOfPages',
      token: 'authentication/token',
    }),
    dropzoneOptions() {
      return {
        url: new URL('/importer/patient', process.env.VUE_APP_ROOT_API).href,
        acceptedFiles: 'text/csv',
        thumbnailWidth: 500,
        thumbnailHeight: 50,
        addRemoveLinks: true,
        parallelUploads: 10,
        uploadMultiple: true,
        dictDefaultMessage: 'Preencha os campos e escolha ou arraste os dados dos pacientes para importar',
        dictFileTooBig: 'Arquivo muito grande ({{filesize}}MiB). tamanho máximo: {{maxFilesize}}MiB.',
        dictInvalidFileType: 'Você não pode enviar arquivos deste tipo.',
        dictResponseError: 'Servidor respondeu com o código {{statusCode}}.',
        dictCancelUpload: 'Cancelar envio',
        dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o envio ?',
        dictRemoveFile: 'Remover Arquivo',
        dictMaxFilesExceeded: 'Você não pode enviar mais arquivos.',
        headers: { Authorization: `Bearer ${this.token}` },
        autoProcessQueue: true,
        paramName: 'attachments',
      }
    },
    rateClass() {
      return (item) => {
        const isUnsuccessful = this.formatRate(item.importSuccessCount, item.importTotalCount) !== '100.00%'
        return {
          'tw-text-danger': isUnsuccessful,
        }
      }
    },
  },
  watch: {
    hmoSelected() {
      this.listFileQueues()
    },
    healthProductSelected() {
      this.listFileQueues()
    },
  },
  async activated() {
    await this.listFileQueues()
    await this.listHmo()

    this.updateFilesInterval = setInterval(() => {
      this.listFileQueues()
    }, 15000)
  },
  deactivated() {
    clearInterval(this.updateFilesInterval)
  },
  validations() {
    return {
      hmoSelected: {
        required,
      },
      healthProductSelected: {
        required,
      },
      selectedAction: {
        required,
      },
    }
  },
  methods: {
    ...mapActions({
      listHmo: 'healthMaintenanceOrganization/listHmo',
      listQueues: 'importFileQueue/listQueues',
      getExampleFile: 'importFileQueue/getExampleFile',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    changePage(page) {
      this.page = page
      this.listFileQueues()
    },
    async listFileQueues() {
      await this.listQueues({
        healthMaintenanceOrganizationId: this.hmoSelected?.id,
        healthProductId: this.healthProductSelected?.id,
        page: this.page,
      })
    },
    changePlanList() {
      this.healthProductSelected = null
      this.healthProductList = this.hmoSelected.healthProducts

      if (length(this.hmoSelected.healthProducts) === 1) {
        this.healthProductSelected = head(this.hmoSelected.healthProducts)
      }
    },
    formatRate(eventRate, total) {
      if (isNil(eventRate) || total === 0 || isNil(total)) return 'Sem dados'
      return `${(100 * eventRate / total).toFixed(2)}%`
    },
    formatAction(operation) {
      if (equals('insert', operation)) return 'Inserção'
      return 'Arquivamento'
    },
    formatProgressCount(successCount, errorCount, totalCount) {
      if (isNil(totalCount)) return 'Sem dados - status'
      return `${successCount + errorCount} / ${totalCount}`
    },
    showSnackbar(status, message) {
      this.setSnackbar({
        status,
        message,
      })
    },
    validateParams(file, xhr, formData) {
      this.$v.$touch()
      if (this.$v.$error) {
        const msg = 'Verifique os campos em vermelho.'
        this.showSnackbar('error', msg)
        this.$refs.myDropzone.removeFile(file)
        return
      }

      formData.append('hmoId', this.hmoSelected.id)
      formData.append('healthProductId', this.healthProductSelected.id)
      formData.append('action', this.selectedAction)
    },
    async downloadExampleFile() {
      const fileResponse = await this.getExampleFile()

      const blob = new Blob([`\uFEFF${fileResponse}`], {
        type: 'text/csv;charset=utf-8',
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'example.csv'
      link.click()
    },
    openShowPatientImportListModal(item) {
      this.shouldShowPatientImportListModal = true
      this.patientImportListModalItem = item
    },
    closeShowPatientImportListModal() {
      this.shouldShowPatientImportListModal = false
      this.patientImportListModalItem = {}
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.header-container
  max-width 100%

.dropzone
  min-height 200px
  border 1px dashed primary-color
  border-radius 1rem
  >>> .dz-preview
    z-index 1
  >>> .dz-message
    margin 0

.dropzone-custom-message
  color amparo-green-tech
  font-weight 600

.patient-import-table >>> thead > tr
  background-color #3233380D
  box-shadow 0px 3px 1px 0px #E0E0E0E5
  font-size 0.9rem !important

.patient-import-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  padding 16px !important

.patient-import-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.patient-import-table >>> tbody > tr > td
  color #323338CC
  padding 16px !important
  font-weight 400
</style>
