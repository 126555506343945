<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">
        <v-text-field
          v-model="filters.name"
          label="Buscar por nome"
          filled
          clearable
          hide-details
          no-data-text="Digite para buscar"
          @input="listCareTeam(filters)"
        />
        <v-autocomplete
          v-model="filters.clinicId"
          :items="clinics"
          label="Buscar por clínica"
          filled
          clearable
          hide-details
          item-text="name"
          item-value="id"
          no-data-text="Digite para buscar"
          @change="listCareTeam(filters)"
        />
      </div>
    </div>
    <div class="tw-px-12 tw-mt-6">
      <v-card
        v-for="team in careTeams"
        :key="team.id"
        outlined
        class="tw-mb-6 tw-p-5"
      >
        <v-card-text class="tw-grid tw-grid-cols-12">
          <div class="tw-col-span-4 tw-flex tw-flex-col tw-gap-y-2">
            <div class="tw-text-2xl tw-text-black tw-font-medium">
              {{ team.name }}
            </div>
            <div class="tw-text-lg tw-text-[#000000de] tw-font-medium">
              Unidade: {{ team.clinic.name }}
            </div>
          </div>
          <div class="tw-col-span-2">
            <div>
              {{ team.score/100 }} pontos
            </div>
          </div>
          <div class="tw-col-span-4 tw-flex tw-flex-col">
            <div
              v-for="professional in team.professionals"
              :key="professional.id"
            >
              {{ professional.name }} - {{ professional.profession.name }}
            </div>
          </div>
          <div class="tw-col-span-2 tw-justify-self-end">
            <v-btn
              icon
              @click.stop="openCareTeamEditDialog(team)"
            >
              <v-icon>mdi-account-edit</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="updateMigrationDialogShow(team)"
            >
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click.stop="openDeleteCareTeamDialog(team)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <migrate-patients-care-team
      v-if="isShowingMigrationDialog"
      :editing-team="editingTeam"
      :show="isShowingMigrationDialog"
      @close="updateMigrationDialogShow()"
    />

    <create-care-team
      v-if="isCareTeamEditDialogOpen"
      :editing-team="editingTeam"
      @update="listCareTeam(filters)"
      @closeDialog="closeCareTeamEditDialog"
    />

    <delete-care-team
      v-if="showDeleteCareTeamDialog"
      :editing-team="editingTeam"
      @updateData="listCareTeam(filters)"
      @closeDialog="closeDeleteCareTeamDialog"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CareTeamList',
  components: {
    CreateCareTeam: () => import('./CreateCareTeam'),
    MigratePatientsCareTeam: () => import('./MigratePatientsCareTeam'),
    DeleteCareTeam: () => import('./DeleteCareTeam'),
  },
  data() {
    return {
      filters: {
        name: null,
        clinicId: null,
      },
      totalPatients: 0,
      professionalFilter: null,
      isCareTeamEditDialogOpen: false,
      isShowingMigrationDialog: false,
      showDeleteCareTeamDialog: false,
      showHasPatientDialog: false,
      editingTeam: {},
      storedTeamList: [],
    }
  },
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      careTeams: 'careTeam/careTeams',
    }),
  },
  async activated() {
    await this.listCareTeam()
    this.listClinic()
  },
  methods: {
    ...mapActions({
      listCareTeam: 'careTeam/listCareTeam',
      listClinic: 'clinic/listClinic',
    }),
    async updateMigrationDialogShow(team = {}) {
      this.showHasPatientDialog = false

      if (!this.isShowingMigrationDialog) {
        this.editingTeam = team
      }

      Vue.nextTick(() => {
        this.isShowingMigrationDialog = !this.isShowingMigrationDialog
      })

      await this.listCareTeam()
    },
    openCareTeamEditDialog(team) {
      this.editingTeam = team
      Vue.nextTick(() => {
        this.isCareTeamEditDialogOpen = true
      })
    },
    closeCareTeamEditDialog() {
      this.isCareTeamEditDialogOpen = false
      this.editingTeam = null
    },
    openDeleteCareTeamDialog(team) {
      this.editingTeam = team
      this.showDeleteCareTeamDialog = true
    },
    closeDeleteCareTeamDialog() {
      this.editingTeam = {}
      this.showDeleteCareTeamDialog = false
    },
  },
}
</script>
