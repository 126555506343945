<template>
  <div>
    <div class="tw-flex tw-items-center tw-justify-between">
      <div class="tw-flex tw-flex-wrap">
        <span v-if="isEmptyPatientTags">
          Paciente não possui tags
        </span>
        <div
          v-for="patientTag of patientTags"
          :key="patientTag.id"
        >
          <list-care-line-tag-options
            :tag="patientTag"
            @clickTag="openEditTag"
          />
        </div>
      </div>

      <v-btn
        color="gray"
        small
        dark
        @click="openDialog"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Adicionar tag
      </v-btn>
    </div>

    <v-dialog
      v-model="isOpen"
      max-width="600"
    >
      <v-card>
        <v-card-title class="tw-flex tw-justify-between tw-mb-6">
          <div>
            <v-icon v-if="!isEditing">
              mdi-plus
            </v-icon>
            {{ cardTitle }}
          </div>

          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="isOpen">
          <v-autocomplete
            v-model="tag"
            label="Tag"
            item-text="name"
            :items="availableTags"
            return-object
            filled
            hide-details
            :disabled="isEditing"
            @change="setOptionId(null)"
          />
          <div
            v-if="tag.id"
            class="tw-mt-2"
          >
            Tag pertencente à linha de cuidado
            <strong>{{ tag.careLine.name }}</strong>
          </div>
          <v-radio-group
            v-if="tag.id"
            v-model="optionId"
            row
            hide-details
          >
            <v-radio
              v-for="{id, label, color} of tag.careLineTagOptions"
              :key="id"
              :value="id"
              :color="color"
            >
              <template #label>
                <v-chip
                  :color="`${color} darken-2`"
                  outlined
                >
                  {{ label }}
                </v-chip>
              </template>
            </v-radio>
          </v-radio-group>
          <div v-if="tag.instruction">
            <v-icon>mdi-alert-circle-outline</v-icon>
            <p class="tw-ml-2">
              Instruções da Tag: {{ tag.instruction }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions class="tw-px-6 tw-pb-4">
          <v-btn
            color="primary"
            block
            :disabled="shouldDisableSaveButton"
            @click="saveCareLineTagPatient"
          >
            {{ cardTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  or,
  equals,
  map,
  not,
  prop,
  find,
  propEq,
  path,
  isEmpty,
  head,
  defaultTo,
} from 'ramda'
import ListCareLineTagOptions from '@/components/CareLineTimeline/ListCareLineTagOptions'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ListCareLineTagOptions,
  },
  data() {
    return {
      isOpen: false,
      tag: {},
      optionId: null,
      isEditing: false,
    }
  },
  computed: {
    ...mapGetters({
      availableTags: 'careLine/careLineAvailableTags',
      patient: 'emr/patient',
    }),
    cardTitle() {
      return this.isEditing
        ? 'Editar tag'
        : 'Adicionar nova tag'
    },
    patientTags() {
      return map(
        tag => ({
          id: tag.id,
          name: tag.careLineTag.name,
          instruction: tag.careLineTag.instruction,
          careLine: tag.careLineTag.careLine,
          options: [tag.careLineTagOption],
        }),
        this.careLineTagsPatient,
      )
    },
    careLineTagsPatient() {
      return defaultTo([], this.patient.careLineTagsPatient)
    },
    isEmptyPatientTags() {
      return isEmpty(this.patientTags)
    },
    patientId() {
      return this.patient.id
    },
    shouldDisableSaveButton() {
      return or(not(this.tag.id), not(this.optionId))
    },
  },
  methods: {
    ...mapActions({
      listCareLineAvailableTags: 'careLine/listCareLineAvailableTags',
      createCareLineTagPatient: 'careLine/createCareLineTagPatient',
      updateCareLineTagPatientOption: 'careLine/updateCareLineTagPatientOption',
      setEmrPatient: 'emr/setEmrPatient',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    setTag(value) {
      this.tag = value
    },
    setOptionId(value) {
      this.optionId = value
    },
    setIsEditing(value) {
      this.isEditing = value
    },
    async openDialog() {
      this.isOpen = true
      return this.getAvailableTags()
    },
    getTagOptions(tagName) {
      return prop(
        'careLineTagOptions',
        find(propEq(tagName, 'name'), this.availableTags),
      )
    },
    async openEditTag(tag) {
      this.setIsEditing(true)
      await this.openDialog()
      this.setTag({
        ...tag,
        careLineTagOptions: this.getTagOptions(tag.name),
      })
      this.setOptionId(prop('id', head(this.tag.options)))
    },
    closeDialog() {
      this.isOpen = false
      this.setOptionId(null)
      this.setTag({})
      this.setIsEditing(false)
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    getAvailableTags() {
      return this.listCareLineAvailableTags(this.patientId)
    },
    getErrorMessage(errorCode) {
      return equals('patient_already_has_care_line_tag', errorCode)
        ? 'Tag já inserida ao paciente'
        : 'Erro ao salvar Tag'
    },
    async saveCareLineTagPatient() {
      try {
        if (this.isEditing) {
          await this.updateCareLineTagPatientOption({
            tagId: this.tag.id,
            careLineTagOptionId: this.optionId,
          })
        } else {
          await this.createCareLineTagPatient({
            patientId: this.patientId,
            careLineTagId: this.tag.id,
            careLineTagOptionId: this.optionId,
          })
        }
        this.closeDialog()
        this.buildSnackbar('success', 'Tag salva com sucesso')
      } catch (error) {
        this.buildSnackbar(
          'error',
          this.getErrorMessage(path(['response', 'data', 'errorCode'], error)),
        )
      }
      this.setEmrPatient(this.patientId)
    },
  },
}
</script>
