<template>
  <div>
    <component-title>
      <template #actions>
        <v-btn
          color="primary"
          class="btn"
          @click="showCreateCareTeamDialog = true"
        >
          Nova Equipe de Saúde
        </v-btn>
      </template>
    </component-title>

    <care-team-list />

    <create-care-team
      v-if="showCreateCareTeamDialog"
      @update="listCareTeam"
      @closeDialog="showCreateCareTeamDialog = false"
    />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import CreateCareTeam from '@/pages/CareTeam/CreateCareTeam'
import { mapActions } from 'vuex'
import CareTeamList from './CareTeamList'

export default {
  components: {
    ComponentTitle,
    CareTeamList,
    CreateCareTeam,
  },
  data() {
    return {
      showCreateCareTeamDialog: false,
    }
  },
  methods: {
    ...mapActions({
      listCareTeam: 'careTeam/listCareTeam',
    }),
  },
}
</script>
