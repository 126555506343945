<template>
  <div class="tw-grid tw-grid-cols-4 tw-gap-3 tw-py-4 tw-px-4 lighter-green">
    <vc-date-picker
      v-model="filters.range"
      is-range
      locale="pt-PT"
      hide-details
      color="green"
    >
      <template v-slot="{ inputEvents }">
        <v-text-field
          :value="dateRangeText"
          readonly
          hide-details
          label="Data"
          append-icon="mdi-calendar"
          filled
          dense
          v-on="inputEvents.start"
        />
      </template>
    </vc-date-picker>

    <search-patient
      ref="patientInput"
      label="Paciente"
      hide-details
      clearable
      filled
      dense
      @input="updatePatientId($event)"
    />

    <v-autocomplete
      v-model="filters.healthMaintenanceOrganizationIds"
      hide-details
      :items="hmos"
      item-text="name"
      item-value="id"
      label="Operadora"
      no-data-text="Digite para buscar"
      clearable
      multiple
      filled
      dense
      hide-select
    />

    <v-autocomplete
      v-model="filters.healthProductIds"
      hide-details
      :items="healthProducts"
      item-text="name"
      item-value="id"
      label="Plano"
      no-data-text="Digite para buscar"
      clearable
      multiple
      filled
      dense
      hide-select
    />

    <v-autocomplete
      v-model="filters.clinicId"
      hide-details
      :items="clinics"
      multiple
      item-text="name"
      item-value="id"
      label="Unidade"
      no-data-text="Digite para buscar"
      clearable
      filled
      dense
    />

    <v-text-field
      v-model="filters.number"
      label="Número da OS"
      hide-details
      no-data-text="Digite para buscar"
      type="number"
      filled
      dense
    />

    <v-autocomplete
      v-model="filters.isInvoiceEmitted"
      hide-details
      :items="invoiceFilterList"
      item-text="name"
      item-value="value"
      filled
      dense
      clearable
      label="Nota Fiscal"
      no-data-text="Digite para buscar"
    />

    <v-autocomplete
      v-model="filters.status"
      hide-details
      :items="orderStatusList"
      item-text="name"
      item-value="value"
      filled
      dense
      multiple
      clearable
      label="Status Da OS"
    />
    <v-autocomplete
      v-model="filters.professionalId"
      :items="professionalsItems"
      hide-details
      :search-input.sync="searchProfessional"
      label="Profissional"
      dense
      item-text="name"
      item-value="id"
      clearable
      filled
      multiple
      hide-select
      return-object
      no-data-text="Digite para buscar"
    />

    <v-autocomplete
      v-model="filters.specialityId"
      hide-details
      label="Especialidade"
      :items="specialities"
      item-text="name"
      item-value="id"
      dense
      clearable
      filled
      multiple
    />

    <v-select
      v-model="filters.appointmentStatus"
      hide-details
      label="Status do Agendamento"
      :items="appointmentStatus"
      item-text="label"
      item-value="value"
      clearable
      dense
      filled
      multiple
    />

    <v-text-field
      v-model="filters.hmoGuideNumber"
      label="Número da Guia"
      hide-details
      no-data-text="Digite para buscar"
      filled
      dense
      clearable
    />

    <v-text-field
      v-model="filters.authorizationCode"
      label="Autorização"
      hide-details
      no-data-text="Digite para buscar"
      filled
      dense
      clearable
    />

    <v-select
      v-model="filters.isReturnal"
      hide-details
      label="Retorno"
      :items="isReturnalValues"
      item-text="label"
      item-value="value"
      clearable
      dense
      filled
    />

    <v-autocomplete
      v-model="filters.billIds"
      item-text="name"
      item-value="id"
      label="Fatura"
      :items="billsFormatted"
      clearable
      multiple
      filled
      dense
      hide-select
      hide-details
    >
      <template v-slot:item="{ item, on }">
        <v-list-item
          v-if="item.id !== 'null'"
          :disabled="shouldDisableBills"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.hmo.name }} - #{{ item.number }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.closeDate | formatDate }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ formatBillStatus(item.status) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-else
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-select
      v-model="filters.eligibility"
      hide-details
      label="Elegibilidade"
      :items="eligibilityValues"
      item-text="label"
      item-value="value"
      clearable
      dense
      filled
    />

    <v-btn
      class="white-text font-weight-lightbold text-body-1"
      color="dark-green"
      block
      x-large
      @click="updateFilters"
    >
      <v-icon>
        mdi-magnify
      </v-icon>
      Filtrar
    </v-btn>
  </div>
</template>

<script>
import {
  flatten,
  includes,
  isEmpty,
  isNil,
  join,
  map,
  mapObjIndexed,
  not,
  omit,
  pluck,
  reject,
  sort,
  toPairs,
  values,
} from 'ramda'
import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment'
import { debounce } from 'lodash'
import SearchPatient from '@/components/Common/SearchPatient'
import {
  APPOINTMENT,
  BILL,
  ELIGIBILITY,
  ORDER,
} from 'amparo-enums'

export default {
  name: 'OrderFilters',
  components: {
    SearchPatient,
  },
  data: () => ({
    filters: {
      billIds: [],
      range: {},
    },
    isInvoiceEmittedValues: {
      true: 'Sim',
      false: 'Não',
    },
    isReturnalValues: [
      {
        label: 'Sim',
        value: true,
      },
      {
        label: 'Não',
        value: false,
      },
    ],
    eligibilityValues: [
      {
        label: 'Elegível',
        value: ELIGIBILITY.status.eligible,
      },
      {
        label: 'Não elegível',
        value: ELIGIBILITY.status.notEligible,
      },
      {
        label: 'Não verificada',
        value: 'other',
      },

    ],
    appointmentStatus: values(APPOINTMENT.status),
    searchProfessional: null,
    professionalsItems: [],
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      hmos: 'healthMaintenanceOrganization/hmos',
      specialities: 'speciality/specialities',
      professionals: 'professional/professionals',
    }),
    ...mapState({
      bills: ({ billing }) => billing.bills,
    }),
    billsFormatted() {
      const billStatusPriority = {
        [BILL.status.toBill.value]: 1,
        [BILL.status.closed.value]: 2,
        [BILL.status.delivered.value]: 3,
        [BILL.status.received.value]: 4,
      }

      const validItems = reject(
        bill => isNil(billStatusPriority[bill.status]),
        this.bills,
      )

      const sortedItems = sort(
        (a, b) => (billStatusPriority[a.status] <= billStatusPriority[b.status] ? -1 : 1),
        validItems,
      )

      return [
        {
          id: 'null',
          name: 'Nenhuma',
        },
        ...map(bill => ({ ...bill, name: `${bill.hmo.name} - #${bill.number}` }), sortedItems),
      ]
    },
    healthProducts() {
      return flatten(pluck('healthProducts', this.hmos))
    },
    invoiceFilterList() {
      return this.getNamesAndValues(this.isInvoiceEmittedValues)
    },
    orderStatusList() {
      return values(ORDER.status)
    },
    dateRangeText() {
      return join(' a ')(values(this.formatedDatesToShow))
    },
    formatedDatesToShow() {
      return mapObjIndexed(
        (date) => {
          if (isNil(date)) return ''
          return moment(date).format('DD/MM/YYYY')
        },
        this.filters.range,
      )
    },
    formatedDatesToSend() {
      return mapObjIndexed(
        date => moment(date).format('YYYY-MM-DD'),
        this.filters.range,
      )
    },
    shouldDisableBills() {
      const billIds = pluck('id', this.filters.billIds)
      return includes(null, billIds)
    },
  },
  watch: {
    searchProfessional: debounce(function searchProfessional(name) {
      if (not(name)) return
      this.listProfessional({ name })
    }, 600),
    'filters.healthMaintenanceOrganizationIds': debounce(function searchBills(hmoIds) {
      this.listBills({ hmoIds })
    }, 1000),
    professionals(professionals) {
      this.professionalsItems = [...professionals, ...this.filters.professionalId || []]
    },
  },
  mounted() {
    this.listClinic()
    this.listHmo()
    this.listProfessional()
    this.listSpeciality()
    this.listBills()
    this.setInitialFilters()
    this.updateFilters()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listClinic: 'clinic/listClinic',
      listHmo: 'healthMaintenanceOrganization/listHmo',
      setOrderFilters: 'billing/setOrderFilters',
      listOrder: 'billing/listOrder',
      listSpeciality: 'speciality/listSpeciality',
      listProfessional: 'professional/listProfessional',
      listBills: 'billing/listBills',
    }),
    setInitialFilters() {
      this.filters.range = {
        start: this.formattedStartFilterDate(),
        end: this.formattedEndFilterDate(),
      }

      if (!isEmpty(this.$route.query)) {
        const { startDate, endDate, billId } = this.$route.query

        this.filters = {
          ...this.filters,
          billIds: billId ? [billId] : [],
          range: {
            start: startDate,
            end: endDate,
          },
        }
      }
    },
    async updateFilters() {
      if (!this.isValidDateRange()) {
        this.setSnackbar({
          status: 'error',
          message: 'Ops! Data inválida. Certifique-se de selecionar um período de até 3 meses a partir da data atual.',
        })
        return
      }

      if (isEmpty(this.filters.number)) this.filters.number = undefined

      const formattedFilters = omit(['range'], this.filters)

      await this.setOrderFilters({
        ...formattedFilters,
        startDate: this.formatedDatesToSend.start,
        endDate: this.formatedDatesToSend.end,
        professionalId: pluck('id', this.filters.professionalId || []),
      })
      this.$emit('resetSelectedOrders')
      try {
        await this.listOrder()
      } catch (err) {
        this.setSnackbar({
          status: 'error',
          message: 'Erro ao buscar por ordens de serviço!',
        })
      }
    },
    getNamesAndValues(array) {
      return map(([key, value]) => ({ value: key, name: value }), toPairs(array))
    },
    updatePatientId(event) {
      if (event) {
        this.filters.patientId = event.id
        return
      }
      this.filters.patientId = undefined
    },
    formattedStartFilterDate() {
      return moment().subtract(1, 'months').toDate()
    },
    formattedEndFilterDate() {
      return moment().toDate()
    },
    isValidDateRange() {
      if (isNil(this.filters.range)) return false

      return moment(this.filters.range.end).diff(this.filters.range.start, 'months', true) <= 3
    },
    formatBillStatus(status) {
      return BILL.status[status].label
    },
  },
}
</script>
