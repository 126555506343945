<template>
  <div class="tw-w-[350px]">
    <emr-generate-report
      class="tw-flex tw-justify-end"
      :patient-id="patient.id"
    />
    <v-select
      v-model="pagination.descending"
      :items="orderByTypes"
      hide-details
      item-text="label"
      item-value="value"
    />
  </div>
</template>

<script>
import { ENCOUNTER } from 'amparo-enums'
import { mapGetters } from 'vuex'
import { values } from 'ramda'
import EmrGenerateReport from './EmrGenerateReport'

export default {
  name: 'EmrEncounterListControls',
  components: {
    EmrGenerateReport,
  },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderByTypes: values(ENCOUNTER.orderBy),
    }
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
    }),
  },
}
</script>
