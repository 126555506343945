<template>
  <div>
    <div class="tw-grid tw-p-4 tw-gap-4 lg:tw-grid-cols-3 tw-bg-green-light">
      <v-autocomplete
        v-model="filters.selectedClinic"
        :items="clinics"
        item-text="name"
        item-value="id"
        label="Unidade"
        hide-details
        no-data-text="Digite para buscar"
        append-icon=""
        clearable
        filled
        return-object
        @change="setFilters(filters)"
      />

      <v-autocomplete
        v-model="filters.selectedSpeciality"
        :items="specialities"
        item-text="name"
        item-value="id"
        filled
        clearable
        hide-details
        label="Especialidade"
        no-data-text="Digite para buscar"
        append-icon=""
        return-object
        @change="setFilters(filters)"
      />

      <search-professional
        :key="`${clinicId + professionalId}`"
        :clinic-id="clinicId"
        :professional-id="professionalId"
        :hide-details="true"
        @input="updateProfessional($event)"
      />
    </div>

    <div
      v-if="!hasFiltersFilled"
      class="tw-m-6 tw-text-2xl tw-font-light"
    >
      Preencha os campos acima para buscar.
    </div>

    <div
      v-else-if="!areFiltersFulfilled"
      class="tw-mt-6"
    >
      <agenda-settings-full-list
        v-if="!loadingSpotlight"
        instance-type="spotlights"
        @setFilters="setFilters"
      />
    </div>

    <div
      v-else
      class="tw-mx-6"
    >
      <div class="tw-flex tw-my-0 tw-justify-center">
        <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/4">
          <v-btn
            class="font-weight-bold my-6"
            color="primary"
            block
            large
            @click="openSpotlightDialog()"
          >
            Novo Destaque
          </v-btn>
        </div>
      </div>

      <loading-agenda-settings
        v-if="loadingSpotlight"
      />

      <v-data-table
        v-else
        :headers="headers"
        :items="availableSpotlights"
        hide-default-footer
        disable-pagination
        class="data-table__header"
        no-data-text="Sem itens para exibir, clique no botão acima para começar a criar"
      >
        <template v-slot:item="agenda">
          <tr>
            <td>{{ agenda.item.startDate | formatDate }}</td>
            <td>{{ agenda.item.endDate | formatDate }}</td>
            <td>{{ agenda.item.startTime | removeSecondsFromTime }}</td>
            <td>{{ agenda.item.endTime | removeSecondsFromTime }}</td>
            <td>{{ agenda.item.reason }}</td>
            <td>{{ agenda.item.slotInterval }}</td>
            <td>
              <v-btn
                text
                icon
                @click="openEditDialog(agenda.item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                text
                icon
                @click="openArchiveDialog(agenda.item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <v-dialog
      v-model="editDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="title-h3">
          Editar razão do destaque
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="itemToEdit.reason"
            label="Razão"
            filled
            @keyup.enter="editSpotlight()"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn-dialog"
            color="green darken-1"
            text
            large
            @click="editDialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            class="btn-dialog"
            color="primary"
            large
            @click="editSpotlight()"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="archiveDialog"
      max-width="460"
    >
      <v-card>
        <div class="tw-flex tw-justify-center">
          <v-card-text>
            Deseja realmente remover este destaque?
          </v-card-text>
        </div>
        <v-card-actions class="tw-justify-end">
          <v-btn
            color="green darken-1"
            text
            @click="archiveDialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="error"
            @click="archiveSpotlight()"
          >
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialogRegisterSpotlightPattern"
      fullscreen
      transition="dialog-bottom-transition"
      @keydown.esc="showDialogRegisterSpotlightPattern = false"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Novo destaque de agenda</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            dark
            @click="showDialogRegisterSpotlightPattern = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <agenda-register-spotlight-pattern
          v-if="showDialogRegisterSpotlightPattern"
          :key="componentKey"
          :clinic="filters.selectedClinic"
          :speciality="filters.selectedSpeciality"
          :professional="filters.selectedProfessional"
          @closeSpotlightDialog="closeSpotlightDialog"
          @getSpotlights="getSpotlights"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  all,
  any,
  clone,
  isEmpty,
  isNil,
  not,
  or,
  values,
  path,
} from 'ramda'
import { mapActions, mapGetters } from 'vuex'
import SearchProfessional from '@/components/Common/SearchProfessional'
import AgendaRegisterSpotlightPattern from './AgendaRegisterSpotlightPattern'

export default {
  name: 'AgendaSettingsSpotlight',
  components: {
    AgendaRegisterSpotlightPattern,
    SearchProfessional,
    LoadingAgendaSettings: () => import('@/pages/AgendaSettings/LoadingAgendaSettings'),
    AgendaSettingsFullList: () => import('@/pages/AgendaSettings/AgendaSettingsFullList'),
  },
  data: () => ({
    componentKey: 0,
    editDialog: false,
    archiveDialog: false,
    itemToEdit: {},
    itemToDelete: {},
    showDialogRegisterSpotlightPattern: false,
    headers: [
      {
        text: 'Início',
        value: 'startDate',
      },
      {
        text: 'Fim',
        value: 'endDate',
      },
      {
        text: 'Hora início',
        value: 'startTime',
      },
      {
        text: 'Hora fim',
        value: 'endTime',
      },
      {
        text: 'Razão',
        value: 'reason',
      },
      {
        text: 'Intervalo',
        value: 'slotInterval',
      },
      {
        text: 'Ação',
        value: 'action',
        sortable: false,
      },
    ],
    filters: {
      selectedClinic: null,
      selectedSpeciality: null,
      selectedProfessional: null,
    },
  }),
  computed: {
    ...mapGetters({
      clinics: 'clinic/clinics',
      specialities: 'speciality/specialities',
      professionals: 'professional/professionals',
      availableSpotlights: 'agenda/availableSpotlights',
      loadingSpotlight: 'agenda/loadingSpotlight',
    }),
    areFiltersFulfilled() {
      return not(any(isNil, values(this.filters)))
    },
    hasFiltersFilled() {
      return not(all(isNil, values(this.filters)))
    },
    clinicId() {
      return path(['selectedClinic', 'id'], this.filters)
    },
    professionalId() {
      return path(['selectedProfessional', 'id'], this.filters)
    },
  },
  methods: {
    ...mapActions({
      updateSpotlight: 'agenda/updateSpotlight',
      listSpotlight: 'agenda/listSpotlight',
      setSpotlightFiltersData: 'agenda/setSpotlightFiltersData',
    }),
    openSpotlightDialog() {
      this.showDialogRegisterSpotlightPattern = true
    },
    closeSpotlightDialog() {
      this.showDialogRegisterSpotlightPattern = false
    },
    isNilOrIsEmpty(value) {
      return or(isNil(value), isEmpty(value))
    },
    async setFilters(filters) {
      this.filters = filters
      this.setSpotlightFiltersData(clone(this.filters))
      await this.getSpotlights()
    },
    async getSpotlights() {
      if (this.hasFiltersFilled) {
        this.listSpotlight()
      }
    },
    openEditDialog(itemToEdit) {
      this.itemToEdit = clone(itemToEdit)
      this.editDialog = true
    },
    openArchiveDialog(itemToDetele) {
      this.itemToDelete = itemToDetele
      this.archiveDialog = true
    },
    async archiveSpotlight() {
      const attributes = { storedAt: moment().format('YYYY-MM-DD HH:mm') }
      await this.updateSpotlight({
        id: this.itemToDelete.id,
        attributes,
      })
      await this.getSpotlights()
      this.archiveDialog = false
    },
    async editSpotlight() {
      const { id, reason } = this.itemToEdit
      await this.updateSpotlight({
        id,
        attributes: { reason },
      })
      await this.getSpotlights()
      this.editDialog = false
    },
    updateProfessional(event) {
      this.filters.selectedProfessional = event
      this.setFilters(this.filters)
      this.listSpotlight()
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../../style/_core/colors.styl'
@import '../../../style/_core/elements.styl'
@import '../../../style/_core/fonts.styl'

.filters-container
  width 100%
  height auto

.v-btn.v-btn--large
  margin-top 0px
  height 56px
  border-radius 8px

.data-spacer
  height 20px
  width 100%

.v-btn.btn-dialog
  padding 15px
  @media all and (min-width: 601px)
    width 190px

.remove_dialog__confirm-alert
  padding 15px
  border-radius 6px

.remove_dialog__confirm-alert-title
  font-size 16px

</style>
