<template>
  <div>
    <div>
      <v-timeline
        align-top
        dense
        class="tw-grid tw-grid-cols-1 tw-mx-8 tw-mt-10"
      >
        <emr-timeline-header
          ref="timelineHeader"
          is-inside-encounter
        />

        <v-timeline-item
          fill-dot
          color="rgba(255,255,255, 0.0)"
        >
          <template v-slot:icon>
            <v-item-group
              v-model="activeTab"
              mandatory
              tag="div"
              class="!tw-fixed tw-top-[350px] tw-flex tw-flex-col"
            >
              <v-item
                v-for="(phase) in struct"
                :key="phase.index"
                :class="{
                  'navbar__fields tw-font-medium': true,
                  'tw-hidden': !hasMoreThanOnePhase(struct),
                }"
                active-class="navbar__fields--activated"
              >
                <div slot-scope="{ active, toggle }">
                  <div
                    :input-value="active"
                    :class="{
                      'tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full': true,
                      'tw-hidden': !hasMoreThanOnePhase(struct),
                    }"
                    @click="goTo(toggle, `phase-${phase.index}`)"
                  >
                    {{ phase.letter }}
                  </div>
                </div>
              </v-item>
            </v-item-group>
          </template>

          <div class="tw-ml-8">
            <v-alert
              v-if="shouldShowAlertAnnotation"
              :value="true"
              type="info"
            >
              Use a anotação para registrar atividades administrativas,
              erratas e monitoramentos que não configuram uma consulta.
            </v-alert>
            <div v-if="hasMoreThanOnePhase(struct)">
              <v-switch
                v-model="singlePageEncounter"
                class="tw-mb-1"
                color="#6DD36E"
                label="Prontuário em página única"
              />
            </div>
            <phase-wrapper
              :type="pageType"
              :active-phase="activeTab"
            >
              <div
                v-for="(phase, index) in struct"
                :key="index"
              >
                <phase-item-wrapper
                  :type="pageType"
                >
                  <div
                    v-if="!phase.hideTitle"
                    :ref="`phase-${phase.index}`"
                    class="tw-text-2xl tw-text-gray-dark tw-scroll-mt-20
                      tw-font-semibold tw-mb-4"
                  >
                    {{ phase.title }}
                  </div>
                  <div
                    v-if="phase.subTitle"
                    class="tw-mb-4 tw-text-2xl tw-text-gray-dark"
                  >
                    {{ phase.subTitle }}
                  </div>
                  <div
                    v-for="(position, positionIndex) in phase.positions"
                    :key="positionIndex"
                    class="tw-container tw-mx-auto"
                  >
                    <template
                      v-for="
                        groupIndex in collapsedStatus[phase.index][positionIndex]['uncollapsed']
                      "
                    >
                      <div
                        v-if="groupIndex !== null"
                        :key="`phase-${phase.index}-group-${groupIndex}`"
                      >
                        <group-wrapper
                          class="tw-mb-4"
                          :group="position[groupIndex]"
                        >
                          <div
                            v-for="(question, questionIndex) in position[groupIndex].questions"
                            :key="question.id || questionIndex"
                            class="tw-flex tw-flex-col tw-mb-2"
                          >
                            <div
                              v-if="question.title"
                              class="tw-mb-4 tw-text-base tw-text-gray"
                            >
                              {{ question.title }}
                            </div>
                            <loading-field-builder
                              v-if="isLoadingSpecificEncounter"
                            />
                            <field-builder
                              v-else
                              :ref="question.id"
                              :question="question"
                              :encounter-data="getQuestionData(question.id)"
                              :should-clean-field="isNewEncounter"
                              :should-show-all-warnings="shouldShowAllWarnings"
                              :professional-preferences="professionalPreferences ?
                                professionalPreferences[question.id] : {}"
                            />
                          </div>
                        </group-wrapper>
                      </div>
                    </template>
                    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mb-6">
                      <template
                        v-for="groupIndex in collapsedStatus
                          [phase.index]
                          [positionIndex]
                          ['floating-button']"
                      >
                        <v-btn
                          v-if="position[groupIndex].collapsed"
                          :key="`group-${groupIndex}-collapsed`"
                          class="!tw-text-xs !tw-text-gray"
                          elevation="0"
                          @click="setGroupCollapsedStatus(
                            [phase.index, positionIndex, groupIndex, 'floating-button'],
                            false,
                          )"
                        >
                          <number-badge
                            :color="getGroupEditedColor(position[groupIndex])"
                            :number="getGroupEditedNumber(position[groupIndex])"
                            :show-when-null="false"
                          />
                          + {{ position[groupIndex].collapsedLabel }}
                        </v-btn>
                      </template>
                    </div>
                    <template
                      v-for="groupIndex in collapsedStatus
                        [phase.index]
                        [positionIndex]
                        ['floating-button']"
                    >
                      <template v-if="!position[groupIndex].collapsed">
                        <div :key="`group-${groupIndex}-uncollapsed-button`">
                          <v-btn
                            :ref="`phase-${phase.index}-group-${groupIndex}`"
                            class="tw-mb-2 !tw-text-xs !tw-text-gray"
                            elevation="0"
                            @click="setGroupCollapsedStatus(
                              [phase.index, positionIndex, groupIndex, 'floating-button'],
                              true,
                            )"
                          >
                            - {{ position[groupIndex].collapsedLabel }}
                          </v-btn>
                        </div>
                        <div :key="`group-${groupIndex}-uncollapsed-questions`">
                          <group-wrapper
                            class="tw-mb-6"
                            :group="position[groupIndex]"
                          >
                            <div
                              v-for="(question, questionIndex) in position[groupIndex].questions"
                              :key="question.id || questionIndex"
                              class="tw-flex tw-flex-col"
                            >
                              <div
                                v-if="question.title"
                                class="tw-mb-4 tw-text-base tw-text-gray"
                              >
                                {{ question.title }}
                              </div>
                              <loading-field-builder
                                v-if="isLoadingSpecificEncounter"
                              />
                              <field-builder
                                v-else
                                :ref="question.id"
                                :question="question"
                                :encounter-data="getQuestionData(question.id)"
                                :should-clean-field="isNewEncounter"
                                :should-show-all-warnings="shouldShowAllWarnings"
                                :professional-preferences="professionalPreferences ?
                                  professionalPreferences[question.id] : {}"
                              />
                            </div>
                          </group-wrapper>
                        </div>
                      </template>
                    </template>
                    <div
                      class="tw-mb-4"
                    >
                      <template
                        v-for="groupIndex in collapsedStatus
                          [phase.index]
                          [positionIndex]
                          ['expansion-panel']"
                      >
                        <div
                          v-if="groupIndex !== null"
                          :key="`group-${groupIndex}-expansion-panel`"
                        >
                          <group-wrapper
                            :group="position[groupIndex]"
                          >
                            <div
                              class="tw-text-base tw-text-gray-dark tw-cursor-pointer
                                tw-flex tw-flex-row tw-my-2"
                              @click="setGroupCollapsedStatus(
                                [phase.index, positionIndex, groupIndex],
                                !position[groupIndex].collapsed,
                              )"
                            >
                              <v-icon
                                v-if="position[groupIndex].collapsed"
                                class="!tw-text-gray-dark"
                              >
                                mdi-chevron-down
                              </v-icon>
                              <v-icon
                                v-else
                                class="!tw-text-gray-dark"
                              >
                                mdi-chevron-up
                              </v-icon>
                              {{ position[groupIndex].collapsedLabel }}
                            </div>
                            <template
                              v-if="!position[groupIndex].collapsed"
                            >
                              <div
                                v-for="(question, questionIndex) in position[groupIndex].questions"
                                :key="question.id || questionIndex"
                                class="tw-flex tw-flex-col tw-mb-4 tw-mx-2"
                              >
                                <div
                                  v-if="question.title"
                                  class="tw-mb-4 tw-text-base tw-text-gray"
                                >
                                  {{ question.title }}
                                </div>
                                <loading-field-builder
                                  v-if="isLoadingSpecificEncounter"
                                />
                                <field-builder
                                  v-else
                                  :ref="question.id"
                                  :question="question"
                                  :encounter-data="getQuestionData(question.id)"
                                  :should-clean-field="isNewEncounter"
                                  :should-show-all-warnings="shouldShowAllWarnings"
                                  :professional-preferences="professionalPreferences ?
                                    professionalPreferences[question.id] : {}"
                                />
                              </div>
                            </template>
                          </group-wrapper>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="tw-my-6">
                    <v-divider />
                  </div>
                </phase-item-wrapper>
              </div>
            </phase-wrapper>
            <div>
              <input-reminder
                :can-add-reminder="true"
                :encounter-data="data"
                :should-clean-field="isNewEncounter"
              />
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
    <button-scroll-top />
    <v-btn
      v-if="hasPrescriptions"
      color="amparo-medium-green"
      dark
      fab
      fixed
      bottom
      right
      @click="setShowPrescriptionsResume(true)"
    >
      <v-icon color="white">
        mdi-clipboard-pulse
      </v-icon>
    </v-btn>
    <prescriptions-resume
      :show-dialog="showPrescriptionsResume"
      @update:show-dialog="setShowPrescriptionsResume"
    />
  </div>
</template>

<script>
import appearConditions from '@/appearConditions'
import FieldBuilder from '@/components/emrFields/FieldBuilder'
import EmrTimelineHeader from '@/pages/Emr/EmrTimelineHeader'
import InputReminder from '@/components/emrFields/InputReminder'
import { mapActions, mapGetters } from 'vuex'
import ButtonScrollTop from '@/components/UI/ButtonScrollTop'
import {
  always,
  append,
  cond,
  defaultTo,
  either,
  equals,
  find,
  groupBy,
  has,
  ifElse,
  isEmpty,
  isNil,
  keys,
  length,
  map,
  not,
  omit,
  path,
  pathEq,
  pipe,
  prepend,
  prop,
  propEq,
  reject,
  T,
  without,
  clone,
  any,
} from 'ramda'
import isNilOrEmpty from '@/utils/dataValidators'

export default {
  components: {
    FieldBuilder,
    EmrTimelineHeader,
    InputReminder,
    ButtonScrollTop,
    LoadingFieldBuilder: () => import('@/components/loaders/LoadingFieldBuilder'),
    GroupWrapper: () => import('@/pages/EmrEncounter/GroupWrapper'),
    NumberBadge: () => import('@/components/emrFields/NumberBadge'),
    PhaseWrapper: () => import('@/pages/EmrEncounter/PhaseWrapper'),
    PhaseItemWrapper: () => import('@/pages/EmrEncounter/PhaseItemWrapper'),
    PrescriptionsResume: () => import('./PrescriptionsResume'),
  },
  props: {
    shouldShowAllWarnings: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      singlePageEncounter: true,
      activeTab: null,
      struct: [],
      data: {},
      professionalPreferences: {},
      collapsedStatus: [],
      showPrescriptionsResume: false,
    }
  },
  provide() {
    return {
      fieldRefs: this.fieldRefs,
    }
  },
  computed: {
    ...mapGetters({
      encounter: 'emr/encounter',
      isLoadingSpecificEncounter: 'emr/isLoadingSpecificEncounter',
      isNewEncounter: 'emr/isNewEncounter',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      orderExamAttachments: 'emr/orderExamAttachments',
      patient: 'emr/patient',
      user: 'authentication/user',
    }),
    pageType() {
      return this.singlePageEncounter ? 'single-page' : 'multiple-pages'
    },
    fieldRefs() {
      return this.$refs
    },
    shouldShowAlertAnnotation() {
      return equals('ANNOTATION', this.encounter.encounterFormName)
    },
    orderExamAttachmentNumber() {
      return length(this.orderExamAttachments)
    },
    hasPrescriptions() {
      if (isNilOrEmpty(path(['encounter', 'data', 'prescriptionsManagement'], this))) return false
      return any(
        propEq('done', 'state'),
      )(path(['encounter', 'data', 'prescriptionsManagement'], this))
    },
    isLoadingPrescription() {
      const prescriptions = this.encounter?.data?.prescriptionsManagement
      if (isNil(prescriptions)) return false

      const isLoading = either(propEq('processing', 'state'), propEq('in progress', 'state'))
      return Boolean(find(isLoading, prescriptions))
    },
  },
  async created() {
    await this.getEncounter()
    this.getStruct()
    this.getProfessionalPreferences()

    window.addEventListener('beforeunload', this.confirmBeforeClosing)
  },
  async activated() {
    await this.getEncounter()
    await this.getPatientExamResult(this.$route.params.id)
    this.getStruct()
    await this.getProfessionalPreferences()
    if (this.isWorkSpaceAmparo) {
      await this.getOrderExamAttachment({ patientId: this.patient.id })
    }
    this.refreshEncounter()
  },
  deactivated() {
    this.clearTimer()
    this.setIsNewEncounter(false)
    this.activeTab = null
  },
  destroyed() {
    this.clearTimer()
    this.setIsNewEncounter(false)
  },
  methods: {
    ...mapActions({
      getOrderExamAttachment: 'emr/getOrderExamAttachment',
      clearTimer: 'emr/clearTimer',
      getSpecificEncounter: 'emr/getSpecificEncounter',
      refreshEncounter: 'emr/refreshEncounter',
      setIsNewEncounter: 'emr/setIsNewEncounter',
      getPatientExamResult: 'emr/getPatientExamResult',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    confirmBeforeClosing(event) {
      // eslint-disable-next-line no-param-reassign
      if (this.isLoadingPrescription) event.returnValue = true

      return event.returnValue
    },
    getQuestionData(questionId) {
      return this.encounter?.data?.[questionId]
    },
    getQuestionRes(questionId) {
      const res = path(['data', questionId], this.encounter) || []
      if (res instanceof Array) return res
      return [res]
    },
    getGroupEditedNumber(group) {
      if (
        pathEq('Laudos Pendentes Para Transcrição', ['collapsedLabel'], group)
      ) {
        return this.orderExamAttachmentNumber
      }
      let total = 0
      for (const question of group.questions) {
        total += this.getQuestionRes(question.id).length
      }
      return total || null
    },
    getGroupEditedColor(group) {
      let newItems = 0
      for (const question of group.questions) {
        newItems += reject(has('startEncounterId'), this.getQuestionRes(question.id)).length
      }
      if (newItems) return 'amparo-medium-green'
      return 'amparo-light-green'
    },
    goTo(toggle, ref) {
      if (toggle) toggle()
      const [el] = this.$refs[ref]

      if (el) {
        if (el.$el) {
          el.$el.scrollIntoView({ behavior: 'smooth' })
          return
        }
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    hasMoreThanOnePhase(struct) {
      return keys(struct).length > 1
    },
    async getEncounter() {
      const { encounterId, id } = this.$route.params
      await this.getSpecificEncounter(encounterId)
      if (!equals('open', this.encounter?.status)) {
        this.setSnackbar({
          message: 'Você não pode editar um atendimento já finalizado ou cancelado!',
          status: 'error',
        })
        this.$router.push(`/emr/${id}`)
      } else {
        this.data = clone(this.encounter.data)
      }
    },
    shouldShowElement(element) {
      if (not(path(['appearConditions'], element))) return true
      const conditions = element.appearConditions
      if (isNil(conditions)) return true
      for (const condition of conditions) {
        if (appearConditions[condition]({
          patient: this.patient,
          professional: this.user,
          encounter: this.encounter,
        })) return true
      }
      return false
    },
    sortPhaseLettersByIndex(struct) {
      const structList = []
      for (const letter in struct) {
        if (has(letter, struct)) {
          structList.push({
            letter,
            ...struct[letter],
          })
        }
      }
      structList.sort((a, b) => ((a.index > b.index) ? 1 : -1))
      return structList
    },
    setGroupCollapsedStatus(groupPath, value) {
      const [phase, position, group, collapsedType] = groupPath
      this.struct[phase].positions[position][group].collapsed = value

      if (!this.collapsedStatus[phase][position][collapsedType]) return
      this.collapsedStatus[phase][position][collapsedType] = pipe(
        without([group]),
        ifElse(always(value), append(group), prepend(group)),
      )(this.collapsedStatus[phase][position][collapsedType])
    },
    setGroupCollapsedType(groupPath) {
      const [phase, position, type, group] = groupPath
      this.collapsedStatus[phase][position][type].push(group)
    },
    hasSuggestedExams(group) {
      const highlightExamsQuestion = find(
        propEq('type', 'InputHighlightExams'),
        group.questions,
      )

      if (isNil(highlightExamsQuestion)) return false
      if (isEmpty(path(['encounter', 'suggestedExams'], this))) return false
      return true
    },
    getGroupCollapsedType(group) {
      return cond([
        [this.hasSuggestedExams, always('uncollapsed')],
        [prop('collapsedType'), prop('collapsedType')],
        [T, always('uncollapsed')],
      ])(group)
    },
    sortGroupCollapsed(phase, position = 0, groups) {
      const collapsedTypes = {
        uncollapsed: [],
        'floating-button': [],
        'expansion-panel': [],
      }
      const pathForPosition = [phase, position]
      this.collapsedStatus[phase][position] = collapsedTypes

      for (const [index, group] of groups.entries()) {
        if (this.shouldShowElement(group)) {
          const groupPath = [
            ...pathForPosition,
            this.getGroupCollapsedType(group),
            index,
          ]
          this.setGroupCollapsedType(
            groupPath,
          )
        }
      }
    },
    mapPositions(phase) {
      const positions = groupBy(pipe(
        prop('position'),
        defaultTo(0),
      ), phase.groups)
      this.collapsedStatus[phase.index] = Array(keys(positions).length).fill([])

      const phasePositions = []
      for (const [position = 0, groups] of Object.entries(positions)) {
        this.sortGroupCollapsed(phase.index, position, groups)
        if (!phasePositions[position]) {
          phasePositions[position] = []
        }
        phasePositions[position] = groups
      }
      return phasePositions
    },
    mapPhases(phases) {
      this.collapsedStatus = Array(phases.length)

      return map(
        phase => ({
          ...omit(['groups'], phase),
          positions: this.mapPositions(phase),
        }),
        phases,
      )
    },
    sortStruct(encounterFormStruct) {
      const sortedStruct = map(
        this.mapPhases,
        this.sortPhaseLettersByIndex,
      )(encounterFormStruct)

      this.struct = clone(sortedStruct)
    },
    async getStruct() {
      const { encounterFormStruct } = this.encounter
      this.sortStruct(encounterFormStruct)
    },
    async getProfessionalPreferences() {
      this.professionalPreferences = await JSON.parse(
        localStorage.getItem('_professionalPreferences'),
      ) || {}
      const encounterSpecialityId = path(['encounter', 'speciality', 'id'], this)
      if (
        isEmpty(this.professionalPreferences)
        || this.professionalPreferences.specialityId !== encounterSpecialityId
      ) {
        try {
          const res = await this.$http.get('/emr/professional_preferences', {
            params: {
              professionalId: this.user.professionalId,
              specialityId: encounterSpecialityId,
            },
          })

          localStorage.setItem(
            '_professionalPreferences',
            JSON.stringify({
              ...res.data.professionalPreferences,
              specialityId: encounterSpecialityId,
            }),
          )
          this.professionalPreferences = res.data.professionalPreferences
        } catch {
          localStorage.setItem('_professionalPreferences', JSON.stringify({}))
          this.professionalPreferences = {}
        }
      }
    },
    setShowPrescriptionsResume(value) {
      this.showPrescriptionsResume = value
    },
  },
}
</script>

<style lang="stylus" scoped>
.navbar__fields
  background-color #4e5559
  font-size 23px
  width 55px
  height 55px
  color #fff
  -webkit-box-shadow inset 0px .5px 1px 0px rgba(240,240,240,0.8)
  box-shadow inset 0px .5px 1px 0px rgba(240,240,240,0.8)

.navbar__fields:first-child
  border-top-left-radius 5px
  border-top-right-radius 5px

.navbar__fields:last-child
  border-bottom-left-radius 5px
  border-bottom-right-radius 5px

.navbar__fields--activated
  background-color #41b943

.v-timeline-item >>> .v-timeline-item__dot
  background-color rgba(0, 0, 0, 0.0) !important
  box-shadow unset
</style>
