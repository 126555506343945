<template>
  <div class="tw-text-white tw-bg-green-deep">
    <span class="tw-text-xs tw-p-3">
      {{ label }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
}
</script>
