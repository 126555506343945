<template>
  <v-card>
    <v-card-title
      :class="{
        'tw-px-6': true,
        'card__header': true,
        'card__header___arrow': showCardArrow
      }"
    >
      <emr-encounter-resume-header
        :emr="emr"
      />
    </v-card-title>

    <v-card-text class="tw-font-bold">
      <emr-encounter-summary
        :emr="emr"
        can-open-resume-modal
        :should-show-section-name="shouldShowSectionName"
      />
      <input-reminder
        v-if="shouldShowReminder(emr.professional.id)"
        :can-add-reminder="false"
        :encounter-reminder="emr.data ? emr.data[reminderEncounterId] : ''"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { equals } from 'ramda'
import { mapGetters } from 'vuex'
import InputReminder from '@/components/emrFields/InputReminder'
import EmrEncounterSummary from './EmrEncounterSummary'
import EmrEncounterResumeHeader from './EmrEncounterResumeHeader'

export default {
  name: 'EmrEncounterCard',
  components: {
    EmrEncounterResumeHeader,
    EmrEncounterSummary,
    InputReminder,
  },
  props: {
    emr: {
      type: Object,
      required: true,
    },
    showCardArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    shouldShowSectionName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      reminderEncounterId: 'emr/reminderEncounterId',
      user: 'authentication/user',
    }),
  },
  methods: {
    shouldShowReminder(professionalId) {
      return equals(professionalId, this.user.professionalId)
    },
  },
}
</script>

<style lang="stylus" scoped>

.card__header
  word-break: break-word
  background-color #eeeeee

.card__header___arrow
  &:after
    content ''
    position absolute
    left 0
    top 25px
    width 0
    height 0
    border 15px solid transparent
    border-right-color #eee
    border-left 0
    margin-top -15px
    margin-left -15px

.v-card.v-sheet.theme--light::before,
.v-card.v-sheet.theme--light::after
  display none

</style>
