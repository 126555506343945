<template>
  <main>
    <schedule-list-loading v-if="isLoading" />

    <v-data-table
      v-else
      class="schedule-table tw-px-12"
      :headers="headers"
      :items="scheduleList.rows"
      :server-items-length="scheduleList.count"
      :items-per-page="30"
      :page.sync="page"
      item-key="id"
      no-data-text="Sem itens para exibir"
      hide-default-footer
    >
      <template #item="schedule">
        <tr>
          <td>
            <span class="tw-font-medium tw-text-lg">
              <router-link
                :to="{
                  name: 'patientProfile',
                  params: { id: schedule.item.patient.id }
                }"
              >
                {{ schedule.item.patient | formatDisplayName }}
              </router-link>
            </span>

            <patient-medical-follow-up-accept-info-chip
              class="tw-my-2"
              :patient="schedule.item.patient"
            />

            <span
              v-if="schedule.item.patient.cpf || schedule.item.patient.foreignDocument"
              class="tw-block tw-text-gray-soft-medium tw-font-medium"
            >
              {{ schedule.item.patient | getIdentifierDocument }}
            </span>

            <span
              v-if="schedule.item.patient.telphone"
              class="tw-block tw-text-gray-soft-medium tw-font-medium"
            >
              {{ schedule.item.patient.telphone | formatPhone }}
            </span>

            <span
              v-if="schedule.item.patient.email"
              class="tw-block tw-text-gray-soft-medium tw-font-medium"
            >
              {{ schedule.item.patient.email }}
            </span>

            <span
              v-if="schedule.item.patient.birthDate"
              class="tw-block tw-text-gray-soft-medium tw-font-medium"
            >
              {{ patientAge(schedule.item.patient) }}
            </span>
            <span
              v-if="schedule.item.patient.healthInsurancePlan"
              class="tw-block tw-text-gray-soft-medium tw-font-medium tw-text-sm"
            >
              {{ schedule.item.patient.healthInsurancePlan }}
            </span>
          </td>

          <td>
            <span :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              {{ schedule.item.patient.careTeam?.clinic?.name || '-' }}
            </span>
          </td>

          <td>
            <span :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              {{ schedule.item.patient.careTeam?.name || '-' }}
            </span>
          </td>

          <td>
            <span :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              {{ schedule.item.date | formatDate }}
            </span>
          </td>

          <td>
            <span
              :class="{
                'tw-text-primary': (schedule.item.priority === 1),
                'tw-text-yellow': (schedule.item.priority === 2),
                'tw-text-danger': (schedule.item.priority === 3),
                'tw-font-semibold tw-text-base': true
              }"
            >
              {{ priorityList[schedule.item.priority].title }}
            </span>
          </td>

          <td>
            <div :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              <span>
                {{ schedule.item | formatScheduleType }}
              </span>
              <span v-if="schedule.item.responsible">
                <br>
                Prof. Sugerido: {{ schedule.item | formatResponsibleType }}
              </span>
            </div>
          </td>

          <td class="tw-w-min">
            <div :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              <span
                v-if="schedule.item.action"
                class="tw-font-bold tw-block"
              >
                {{ schedule.item | customScheduleActionMessage }}
              </span>

              <span
                class="
                    tw-block
                    tw-text-nowrap
                    tw-max-w-56
                    tw-text-ellipsis
                    tw-overflow-hidden
                  "
              >
                {{ schedule.item.message }}
              </span>

              <v-btn
                v-if="schedule.item.message.length > 35"
                text
                plain
                color="primary"
                class="tw-underline tw-capitalize tw-p-0 tw-font-semibold"
                @click="openScheduleMessageModal(schedule.item.message)"
              >
                Ver Tudo
              </v-btn>
            </div>
          </td>

          <td>
            <div class="tw-flex tw-align-center tw-justify-center">
              <v-select
                v-model="schedule.item.status"
                :items="filterStatusList(schedule.item)"
                filled
                hide-details
                :disabled="!shouldUpdateSchedule(schedule.item)"
                @change="updateScheduleStatus(schedule.item)"
              >
                <template #selection="{ item }">
                  <span :class="{ 'tw-text-danger': isScheduleTaskLate(item.status) }">
                    {{ item }}
                  </span>
                </template>
              </v-select>
            </div>
          </td>

          <td>
            <span :class="{ 'tw-text-danger': isScheduleTaskLate(schedule.item.status) }">
              {{ schedule.item.careLineActionPatient?.careLine?.identifier || '-' }}
            </span>
          </td>

          <td>
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openScheduleHistoryDialog(schedule.item)"
                >
                  <v-icon>
                    mdi-history
                  </v-icon>
                </v-btn>
              </template>
              <span>Histórico</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>

    <div
      class="
        tw-px-12
        tw-grid
        tw-grid-cols-3
        tw-my-3
        tw-items-center
      "
    >
      <v-pagination
        v-model="page"
        class="tw-col-start-2"
        :length="scheduleList.numberOfPages"
        :total-visible="10"
        @input="setPagination"
      />

      <span class="tw-justify-self-end tw-pr-4">
        Total: {{ scheduleList.count }}
      </span>
    </div>

    <schedule-message-modal
      :modal-message="scheduleModalMessage"
      :is-modal-open="shouldShowScheduleModal"
      @close="closeScheduleMessageModal"
    />

    <v-dialog
      v-if="showScheduleHistoryDialog"
      :value="true"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title
          class="
            tw-flex
            tw-justify-between
            tw-text-xl
            tw-font-medium
            tw-pb-6
          "
        >
          <h2>
            Histórico de Atribuições
          </h2>
          <v-btn
            icon
            @click="showScheduleHistoryDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <schedule-timeline
            :schedule-activity-log="selectedScheduleLog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import {
  always,
  cond,
  equals,
  gt,
  gte,
  isEmpty,
  isNil,
  path,
  pick,
  reject,
  T,
  values,
} from 'ramda'
import { SCHEDULE } from 'amparo-enums'
import scheduleEnums from '@/enums/schedule'
import {
  isInvalidScheduleStatus,
} from '@/utils/scheduleValidators'

const { PRIORITY_LIST } = scheduleEnums

export default {
  name: 'ScheluleList',
  components: {
    ScheduleTimeline: () => import('@/components/Common/ScheduleTimeline'),
    PatientMedicalFollowUpAcceptInfoChip: () => import('@/components/Common/PatientMedicalFollowUpAcceptInfoChip'),
    ScheduleMessageModal: () => import('./ScheduleMessageModal'),
    ScheduleListLoading: () => import('./ScheduleListLoading'),
  },
  data: () => ({
    page: 1,
    pagination: {},
    headers: [
      {
        text: 'Paciente',
        value: 'patient.name',
        sortable: false,
      },
      {
        text: 'Clínica',
        value: 'patient.clinc.name',
        sortable: false,
      },
      {
        text: 'Equipe',
        value: 'careTeam.name',
        sortable: false,
      },
      {
        text: 'Data',
        value: 'date',
        sortable: false,
      },
      {
        text: 'Prioridade',
        value: 'priority',
        sortable: false,
      },
      {
        text: 'Tipo',
        value: 'type',
        sortable: false,
      },
      {
        text: 'Mensagem',
        value: 'message',
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Linha de cuidado',
        value: 'careLineActionPatient.careLine.identifier',
        sortable: false,
      },
      {
        text: '',
        value: '',
        sortable: false,
      },
    ],
    scheduleModalMessage: '',
    shouldShowScheduleModal: false,
    priorityList: PRIORITY_LIST,
    statusList: values(
      pick(
        [
          'open',
          'inProgress',
          'finished',
          'late',
          'canceled',
          'rejected',
          'canceledByStored',
          'unsuccessfulContact',
          'autoFinished',
        ],
        SCHEDULE.status,
      ),
    ),
    showScheduleHistoryDialog: false,
    selectedScheduleLog: null,
    refreshScheduleInterval: null,
  }),
  computed: {
    ...mapGetters({
      isLoading: 'schedule/isLoading',
      careTeams: 'careTeam/careTeams',
      scheduleList: 'schedule/scheduleList',
      user: 'authentication/user',
      schedulePage: 'schedule/page',
    }),
    userProfessionalId() {
      return path(['professional', 'id'], this.user) || null
    },
  },
  watch: {
    schedulePage: {
      handler(page) {
        this.page = page
      },
    },
  },
  async activated() {
    this.listCareTeam()
  },
  created() {
    this.listCareTeam()
  },
  methods: {
    ...mapActions({
      setEmrPatient: 'emr/setEmrPatient',
      setSchedulePagination: 'schedule/setSchedulePagination',
      getSchedules: 'schedule/getSchedules',
      updateSchedule: 'schedule/updateSchedule',
      listCareTeam: 'careTeam/listCareTeam',
    }),
    closeFullMessage() {
      this.openFullMessage = false
    },
    openScheduleMessageModal(message) {
      this.scheduleModalMessage = message
      this.shouldShowScheduleModal = true
    },
    closeScheduleMessageModal() {
      this.shouldShowScheduleModal = false
    },
    isScheduleTaskLate(scheduleStatus) {
      return equals(scheduleStatus, 'Atrasado')
    },
    shouldUpdateSchedule({ status }) {
      if (isInvalidScheduleStatus(status)) return false
      return true
    },
    shouldShowScheduleHistory({ activityLog }) {
      return !isNil(activityLog)
    },
    openScheduleHistoryDialog({ activityLog }) {
      this.showScheduleHistoryDialog = true
      this.selectedScheduleLog = activityLog
    },
    async updateScheduleStatus({ id, status }) {
      await this.updateSchedule({ id, attributes: { status } })
      await this.getSchedules()
    },
    patientAge(patient) {
      if (isEmpty(patient)) return null
      const {
        birthDate,
      } = patient
      const getDiffOfDate = diffBy => moment().diff(moment(birthDate), diffBy)
      const buildAgePhrase = (number, plural, singular) => `
        ${number}
        ${gt(number, 1) ? plural : singular}
      `
      const days = getDiffOfDate('days')
      return cond([
        [gte(31), always(buildAgePhrase(days, 'dias', 'dia'))],
        [
          gte(366),
          always(buildAgePhrase(getDiffOfDate('months'), 'meses', 'mês')),
        ],
        [T, always(buildAgePhrase(getDiffOfDate('years'), 'anos', 'ano'))],
      ])(days)
    },
    filterStatusList(scheduleData) {
      const statusListWithoutReject = isNil(scheduleData.careLineActionPatient)
        ? reject(equals(SCHEDULE.status.rejected), this.statusList)
        : this.statusList

      const statusListWithoutAutoFinish = !equals(scheduleData.status, SCHEDULE.status.autoFinished)
        ? reject(equals(SCHEDULE.status.autoFinished), statusListWithoutReject)
        : statusListWithoutReject

      return !(equals(SCHEDULE.types.teamMeeting.value, scheduleData.type)
      || equals(SCHEDULE.types.otherAdministrative.value, scheduleData.type))
        ? [...statusListWithoutAutoFinish, SCHEDULE.status.unsuccessfulContact]
        : statusListWithoutAutoFinish
    },
    setPagination() {
      this.setSchedulePagination({ page: this.page })
      this.getSchedules()
    },
  },
}
</script>

<style lang="stylus" scoped>

.schedule-table >>> thead > tr
  background-color #3233380D
  box-shadow 0px 3px 1px 0px #E0E0E0E5
  font-size 0.9rem !important

.schedule-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  padding 16px !important

.schedule-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.schedule-table >>> tbody > tr > td
  color #323338CC
  padding 16px !important
  font-weight 400 !important

</style>
