<template>
  <div>
    <component-title ref="componentTitle">
      <template #actions>
        <exam-collection-notifications
          :parent-ref="componentTitleElement"
        />
      </template>
    </component-title>
    <exam-collection-list-container />
  </div>
</template>

<script>
import ComponentTitle from '@/components/ComponentTitle'
import ExamCollectionNotifications from '@/pages/ExamCollectionList/Notifications'
import ExamCollectionListContainer from './ExamCollectionListContainer'

export default {
  name: 'ExamCollectionListPage',
  components: {
    ComponentTitle,
    ExamCollectionListContainer,
    ExamCollectionNotifications,
  },
  data() {
    return {
      componentTitleElement: null,
    }
  },
  mounted() {
    this.componentTitleElement = this.$refs.componentTitle?.$el
  },
}
</script>
