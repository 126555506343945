<template>
  <v-container
    fluid
    class="filters-container px-12 py-9"
  >
    <v-row>
      <v-col
        cols="8"
      >
        <v-text-field
          label="Nome ou CPF"
          filled
          clearable
          hide-details
          @input="filterPatients"
          @click:clear="clearSearchFilter"
        />
      </v-col>

      <v-col>
        <v-select
          v-model="filters.status"
          label="Status"
          :items="statusItems"
          item-text="title"
          item-value="value"
          filled
          clearable
          hide-details
          @change="setFilters"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from 'lodash'
import { AUDIT } from 'amparo-enums'

export default {
  data: () => ({
    filters: {
      status: AUDIT.status.todo.value,
    },
    statusItems: [
      {
        title: AUDIT.status.approved.label,
        value: AUDIT.status.approved.value,
      },
      {
        title: AUDIT.status.refused.label,
        value: AUDIT.status.refused.value,
      },
      {
        title: AUDIT.status.todo.label,
        value: AUDIT.status.todo.value,
      },
    ],
  }),
  methods: {
    setFilters() {
      this.$emit('filter', { filter: this.filters })
    },
    buildSearchPatientParam(keyword) {
      if (parseInt(keyword, 10)) {
        return { cpf: keyword.replace(/[^0-9]/g, '') }
      }
      return keyword ? { name: keyword } : null
    },
    filterPatients: debounce(function filterPatients(event) {
      this.filters = {
        status: this.filters.status,
        ...this.buildSearchPatientParam(event),
      }
      this.setFilters()
    }, 500),
    clearSearchFilter() {
      this.filters = {
        status: this.filters.status,
      }
      this.setFilters()
    },
  },
}
</script>
