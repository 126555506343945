<template>
  <div class="tw-grid tw-grid-cols-3 tw-mt-3 tw-items-center">
    <v-pagination
      class="tw-col-start-2"
      :value="page"
      :length="pages"
      @input="changePage($event)"
    />
    <span class="tw-justify-self-end tw-pr-1">
      Total: {{ count }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmrHistoryPagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      encounters: 'emr/encounters',
    }),
    pages() {
      return this.encounters.numberOfPages
    },
    count() {
      return this.encounters.count
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
  },
}
</script>
