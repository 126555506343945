<template>
  <div class="tw-flex tw-justify-center tw-items-center">
    <v-pagination
      :value="page"
      :length="numberOfPages"
      :total-visible="5"
      @input="changePage($event)"
    />
    <span class="tw-absolute tw-right-[10%]">
      Total: {{ count }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AgendaSettingsItemsPagination',
  computed: {
    ...mapGetters({
      page: 'agenda/agendaPage',
      numberOfPages: 'agenda/agendaNumberOfPages',
      count: 'agenda/agendaCount',
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
  },
}
</script>
