<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    persistent
    max-width="1400"
  >
    <v-card>
      <v-card-title
        class="card-dialog__title"
      >
        {{ editClinic ? 'Editar Clínica' : 'Cadastrar Clínica' }}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('closeDialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-2 tw-p-6">
        <v-text-field
          v-model="clinic.name"
          class="tw-col-span-full"
          label="Nome"
          clearable
          filled
          :error="$v.clinic.name.$error"
          @blur="$v.clinic.name.$touch()"
        />
        <v-text-field
          v-model="clinic.address.zipCode"
          v-maska="'#####-###'"
          class="tw-col-span-4"
          label="CEP"
          :loading="isLoadingZipCode"
          clearable
          filled
          :error="$v.clinic.address.zipCode.$error"
          :error-messages="zipCodeErrorMessage"
          @blur="getZipCodeAddress(clinic.address.zipCode), $v.clinic.address.zipCode.$touch()"
        />
        <v-text-field
          v-model="clinic.address.street"
          class="tw-col-span-4"
          label="Logradouro"
          clearable
          filled
          :error="$v.clinic.address.street.$error"
          @blur="$v.clinic.address.street.$touch()"
        />
        <v-text-field
          v-model="clinic.address.number"
          class="tw-col-span-2"
          label="Número"
          clearable
          filled
          :error-messages="$v.clinic.address.number.$error ? 'Número inválido' : ''"
          :error="$v.clinic.address.number.$error"
          @blur="$v.clinic.address.number.$touch()"
        />
        <v-text-field
          v-model="clinic.address.complement"
          class="tw-col-span-2"
          label="Complemento"
          hint="Opcional"
          persistent-hint
          clearable
          filled
        />
        <v-text-field
          v-model="clinic.address.neighborhood"
          class="tw-col-span-4"
          label="Bairro"
          clearable
          filled
          :error="$v.clinic.address.neighborhood.$error"
          @blur="$v.clinic.address.neighborhood.$touch()"
        />
        <v-text-field
          v-model="clinic.address.city"
          class="tw-col-span-4"
          label="Cidade"
          clearable
          filled
          :error="$v.clinic.address.city.$error"
          @blur="$v.clinic.address.city.$touch()"
        />
        <v-autocomplete
          v-model="clinic.address.state"
          class="tw-col-span-4"
          label="Estado"
          :items="formOptions.states"
          :no-data-text="notFoundMessage"
          append-icon
          clearable
          filled
          :error="$v.clinic.address.state.$error"
          @blur="$v.clinic.address.state.$touch()"
        />
        <v-text-field
          v-model="clinic.startTime"
          v-maska="'##:##'"
          class="tw-col-span-4"
          label="Horário de abertura"
          clearable
          filled
          :error-messages="$v.clinic.startTime.$error ? 'Horário inválido' : ''"
          :error="$v.clinic.startTime.$error"
          @blur="$v.clinic.startTime.$touch()"
        />
        <v-text-field
          v-model="clinic.endTime"
          v-maska="'##:##'"
          class="tw-col-span-4"
          label="Horário de fechamento"
          clearable
          filled
          :error-messages="$v.clinic.endTime.$error ? 'Horário inválido' : ''"
          :error="$v.clinic.endTime.$error"
          @blur="$v.clinic.endTime.$touch()"
        />
        <v-text-field
          v-model="clinic.telephone"
          v-maska="['(##) ####-####', '(##) #####-####']"
          class="tw-col-span-4"
          filled
          clearable
          label="Telefone"
          :error-messages="$v.clinic.telephone.$error ? 'Número inválido' : ''"
          :error="$v.clinic.telephone.$error"
          @blur="$v.clinic.telephone.$touch()"
        />
        <v-text-field
          v-model="clinic.cnpj"
          v-maska="'##.###.###/####-##'"
          :class="{
            'tw-col-span-3': isWorkSpaceAmparo,
            'tw-col-span-6': !isWorkSpaceAmparo
          }"
          label="CNPJ"
          clearable
          filled
          :error="$v.clinic.cnpj.$error"
          @blur="$v.clinic.cnpj.$touch()"
        />
        <v-text-field
          v-model="clinic.companyName"
          :class="{
            'tw-col-span-3': isWorkSpaceAmparo,
            'tw-col-span-6': !isWorkSpaceAmparo
          }"
          label="Razão Social"
          clearable
          filled
          :error="$v.clinic.companyName.$error"
          @blur="$v.clinic.companyName.$touch()"
        />
        <v-text-field
          v-if="isWorkSpaceAmparo"
          v-model="clinic.eplPrinterAddress"
          class="tw-col-span-3"
          label="IP da impressora EPL"
          clearable
          filled
          :error="$v.clinic.eplPrinterAddress.$error"
          data-testid="clinic__epl-printer-adress--field"
          @blur="$v.clinic.eplPrinterAddress.$touch()"
        />
        <v-text-field
          v-if="isWorkSpaceAmparo"
          v-model="clinic.npsCustomStoreId"
          class="tw-col-span-3"
          label="Código da pesquisa de NPS"
          clearable
          filled
        />
        <v-btn
          v-if="!editClinic"
          class="btn font-weight-bold tw-col-span-full"
          block
          large
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="handleSaveClinic(clinic)"
        >
          Cadastrar Clínica
        </v-btn>
        <v-btn
          v-else
          class="btn tw-col-span-full"
          block
          large
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="handleSaveClinic(clinic)"
        >
          Salvar Alterações
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import cep from 'cep-promise'
import moment from 'moment'
import {
  isEmpty,
  isNil,
  match,
  pick,
  values,
} from 'ramda'
import {
  required,
  minLength,
  integer,
} from 'vuelidate/lib/validators'
import timeWithoutSeconds from '@/utils/timeValidators'
import onlyNumber from '@/utils/onlyNumber'
import { mapActions, mapGetters } from 'vuex'
import PATIENT_ENUM from '@/enums/patient'

const { STATES } = PATIENT_ENUM

export default {
  name: 'CreateClinic',
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
    editClinic: {
      type: Boolean,
      required: false,
      default: false,
    },
    clinicData: {
      type: Object,
      default: () => ({
        name: '',
        startTime: '',
        endTime: '',
        address: {
          zipCode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        telephone: '',
        cnpj: '',
        companyName: '',
        eplPrinterAddress: null,
      }),
    },
  },
  data() {
    return {
      notFoundMessage: 'Não encontrado',
      isLoadingZipCode: false,
      formOptions: {
        states: values(STATES),
      },
      clinic: {
        name: '',
        startTime: '',
        endTime: '',
        address: {
          zipCode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        telephone: '',
        cnpj: '',
        companyName: '',
        eplPrinterAddress: null,
        npsCustomStoreId: null,
      },
    }
  },
  validations() {
    const clinicValidations = {
      clinic: {
        name: { required, minLength: minLength(2) },
        address: {
          street: {
            required,
            minLength: minLength(2),
          },
          number: {
            required,
            integer,
          },
          neighborhood: {
            required,
            minLength: minLength(2),
          },
          city: {
            required,
            minLength: minLength(2),
          },
          state: {
            required,
            minLength: minLength(2),
          },
          zipCode: { isZipCodeValid: this.isZipCodeValid },
        },
        startTime: {
          required,
          timeWithoutSeconds,
        },
        endTime: {
          required,
          timeWithoutSeconds,
          isEndTimeAfterStartTime: () => this.isEndTimeAfterStartTime(),
        },
        telephone: {
          required,
          minLength: minLength(14),
        },
        cnpj: {
          required,
          minLength: minLength(18),
        },
        companyName: {
          required,
          minLength: minLength(2),
        },
        eplPrinterAddress: {
          matchIpv4: () => this.matchIpv4(),
        },
      },
    }
    return { ...clinicValidations }
  },
  computed: {
    ...mapGetters({
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      loading: 'clinic/loadingClinic',
    }),
    zipCodeErrorMessage() {
      if (this.$v.clinic.address.zipCode.$error) return 'CEP não encontrado.'

      return null
    },
  },
  mounted() {
    if (this.editClinic) this.fillClinic()
  },
  methods: {
    ...mapActions({
      createClinic: 'clinic/createClinic',
      listClinic: 'clinic/listClinic',
      setSnackbar: 'snackbar/setSnackbar',
      updateClinic: 'clinic/updateClinic',
    }),
    isEndTimeAfterStartTime() {
      const startTime = moment(this.clinic.startTime, 'HH:mm')
      const endTime = moment(this.clinic.endTime, 'HH:mm')
      return moment(endTime).isAfter(startTime)
    },
    matchIpv4() {
      if (isNil(this.clinic.eplPrinterAddress)
        || isEmpty(this.clinic.eplPrinterAddress)
      ) return true
      const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

      return !(isEmpty(match(ipv4Regex, this.clinic.eplPrinterAddress)))
    },
    showSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    fillClinic() {
      this.clinic = pick([
        'name',
        'address',
        'startTime',
        'endTime',
        'telephone',
        'cnpj',
        'companyName',
        'eplPrinterAddress',
        'npsCustomStoreId',
      ], this.clinicData)
    },
    async handleSaveClinic(attributes) {
      this.$v.$touch()
      if (this.$v.$error) {
        const msg = 'Verifique os campos em vermelho.'
        this.showSnackbar('error', msg)
        return
      }
      try {
        const formattedAttributes = {
          ...attributes,
          cnpj: onlyNumber(attributes.cnpj),
          telephone: onlyNumber(attributes.telephone),
          npsCustomStoreId: isEmpty(attributes.npsCustomStoreId)
            ? null
            : attributes.npsCustomStoreId,
        }
        if (this.editClinic) {
          await this.updateClinic({ id: this.clinicData.id, attributes: formattedAttributes })
        } else {
          await this.createClinic(formattedAttributes)
        }
        this.showSnackbar('success', 'Clínica salva com sucesso!')
        this.$emit('closeCreateClinicDialog')
        this.$emit('closeDialog')
        this.listClinic({ includeStored: true })
      } catch (error) {
        this.showErrorSnackBar()
      }
    },
    showErrorSnackBar() {
      const message = 'Erro ao cadastrar clínica.'
      this.showSnackbar('error', message)
    },
    getZipCodeAddress(postalCode) {
      if (!postalCode) return
      this.isLoadingZipCode = true
      cep(postalCode)
        .then((res) => {
          this.clinic.address.zipCode = res.cep
          this.clinic.address.state = res.state
          this.clinic.address.city = res.city
          this.clinic.address.street = res.street
          this.clinic.address.neighborhood = res.neighborhood
        })
        .finally(() => (this.isLoadingZipCode = false))
    },
    async isZipCodeValid() {
      if (isNil(this.clinic.address.zipCode)) return true
      return cep(this.clinic.address.zipCode)
        .then(() => true)
        .catch(() => false)
    },
  },
}
</script>
