import {
  cond, equals, always, T,
} from 'ramda'

const getAppointmentErrorMessage = errorCode => cond([
  [equals('access_denied'), always('Você não possui permissão para realizar essa ação.')],
  [equals('appointment_restricted_by_hmo'), always('Esta agenda não permite agendamento para este plano de saúde.')],
  [equals('appointment_restricted_by_user'), always('Esta agenda não permite agendamento por esta profissão')],
  [equals('appointment_restricted_by_type'), always('Esta agenda não permite agendamento para esta modalidade de consulta.')],
  [equals('appointment_restricted_by_origin'), always('Esta agenda não permite agendamento por este módulo do sistema.')],
  [equals('care_team_reached_score_limit'), always('O limite de pontos da equipe de saúde foi atingido.')],
  [equals('not_valid_returnal'), always('Não foi possivel marcar a consulta de retorno da Jornada de Ativação pois a primeira consulta ainda não foi atendida!')],
  [equals('not_valid_first_appointment'), always('Não foi possível agendar pois as regras da Jornada de Ativação não estão sendo respeitadas!')],
  [equals('not_valid_related_to_activation_journey'), always('Não foi possível agendar pois o paciente não possui configuração da jornada de ativação!')],
  [equals('not_valid_appointment_after_returnal'), always('Não foi possivel marcar a consulta pois a consulta de retorno ainda não foi atendida!')],
  [equals('slots_with_extended_appointments'), always('O horário selecionado não está disponível devido a um atendimento estendido.')],
  [equals('appointment_extended_conflict'), always('Agendamentos estendidos não podem ser marcados no mesmo horário que agendamentos regulares.')],
  [equals('appointment_exceeds_agenda_slot_limit'), always('O agendamento estendido é maior que o permitido nessa agenda.')],
  [equals('patient_invalid'), always('Paciente com cadastro incompleto.')],
  [equals('invalid_video_room'), always('Apenas consultas remotas podem ter salas de vídeo associadas.')],
  [equals('appointment_slots_blocked'), always('Um ou mais horários estão bloqueados e não podem ser usados para este agendamento.')],
  [equals('appointment_slot_unavailable'), always('Horário não disponível.')],
  [T, always('Erro ao agendar.')],
])(errorCode)

export default getAppointmentErrorMessage
