<template>
  <content-loader
    :width="600"
    :height="600"
    :speed="4"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect
      x="1%"
      y="3%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="8%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />

    <rect
      x="1%"
      y="13%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="18%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="23%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="28%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="33%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
    <rect
      x="1%"
      y="38%"
      rx="0"
      ry="0"
      width="98%"
      height="4%"
    />
  </content-loader>
</template>

<script>
import {
  ContentLoader,
} from 'vue-content-loader'

export default {
  components: {
    ContentLoader,
  },
}
</script>
