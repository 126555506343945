<template>
  <v-dialog
    :value="true"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          {{ hasItemToEdit ? 'Editar' : 'Novo' }} Item
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="emitCloseDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container
        :class="{
          'container--fluid': true,
          'px-7': $vuetify.breakpoint.smAndUp,
          'grid-list-lg': true
        }"
      >
        <v-form @submit.prevent="handleSaveItem">
          <v-row class="mt-2 mb-0">
            <v-col
              class="mt-0 pb-0"
              cols="12"
            >
              <v-text-field
                v-model="item.name"
                label="Nome do item"
                clearable
                filled
                hide-details
                :error="$v.item.name.$error"
                @blur="$v.item.name.$touch()"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-text-field
                v-model="item.tussCode"
                label="TUSS"
                clearable
                filled
                hide-details
                :error="$v.item.tussCode.$error"
                @blur="$v.item.tussCode.$touch()"
              />
            </v-col>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-text-field
                :value="itemToEdit.amparoCode"
                label="Código Amparo"
                clearable
                filled
                hide-details
                disabled
              />
            </v-col>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-autocomplete
                v-model="item.type"
                label="Tipo"
                :items="itemTypeValues"
                item-text="label"
                item-value="value"
                clearable
                filled
                hide-details
                :error="$v.item.type.$error"
                @blur="$v.item.type.$touch()"
              />
            </v-col>
          </v-row>
          <v-row
            class="mb-4"
          >
            <v-col
              v-if="isExamItem"
              cols="6"
            >
              <v-autocomplete
                v-model="item.exams"
                label="Exame"
                :items="examsList"
                :search-input.sync="searchExam"
                :loading="isLoadingExams"
                item-text="name"
                return-object
                :no-data-text="noDataExamListMessage"
                clearable
                no-filter
                multiple
                chips
                deletable-chips
                filled
                hide-details
              >
                <template #item="{ item }">
                  <div>
                    {{ item.name }}
                    <span
                      v-if="item.code"
                    >
                      - {{ item.code }}
                    </span>
                  </div>
                </template>
                <template #append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mt-1"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Os exames relacionados ao item impactarão na ordem de serviço na sessão de
                      "Prescrições" do paciente. Os mnemônicos que aparecerão para vincular a
                      prescrição dependerão da devida configuração deste campo.
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="6"
            >
              <v-autocomplete
                v-model="item.specialityIds"
                :items="sortedSpecialities"
                label="Especialidades"
                item-text="name"
                item-value="id"
                multiple
                chips
                deletable-chips
                filled
                hide-details
              >
                <template
                  #selection="{ index }"
                >
                  <v-row v-if="index === 0">
                    <v-col>
                      <span
                        v-if="shouldShowAllSelected(
                          item.specialityIds, specialities
                        )"
                      >
                        Todas
                      </span>
                      <span v-else>
                        {{ item.specialityIds.length }} especialidade(s) selecionada(s)
                      </span>
                    </v-col>
                  </v-row>
                </template>
                <template
                  #prepend-item
                >
                  <v-row
                    class="ml-1 mb-1"
                  >
                    <v-col>
                      <v-btn
                        class="mr-2"
                        depressed
                        small
                        color="primary"
                        @click="setItemSpecialityIds(specialities)"
                      >
                        Todas
                      </v-btn>
                      <v-btn
                        depressed
                        small
                        @click="setItemSpecialityIds([])"
                      >
                        Nenhuma
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template #append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        class="mt-1"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Novas agendas das especialidades selecionadas virão com o item adicionado
                      por padrão na tabela de cobertura.
                    </span>
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            v-if="isExamItem"
            class="mb-2"
          >
            <v-col
              class="py-0"
              cols="12"
            >
              <span>Mnemonicos</span>
            </v-col>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-text-field
                v-model="itemMnemonic.mnemonic"
                label="Mnemonico"
                clearable
                filled
                hide-details
              />
            </v-col>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-text-field
                v-model="itemMnemonic.material"
                label="Material"
                clearable
                filled
                hide-details
              />
            </v-col>
            <v-col
              class="py-2"
              cols="4"
            >
              <v-text-field
                v-model="itemMnemonic.location"
                label="Localização"
                clearable
                filled
                hide-details
              />
            </v-col>
            <v-col
              cols="10"
            >
              <v-text-field
                v-model="itemMnemonic.observation"
                label="Observação"
                clearable
                filled
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                class="btn-filter--height font-weight-lightbold white--text"
                color="dark-green"
                block
                large
                :disabled="!isItemMnemonicFullyFilled"
                @click="addNewItemMnemonic"
              >
                Adicionar
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="isExamItem && itemMnemonics?.length"
          >
            <v-col>
              <v-data-table
                class="mnemonics-list__table"
                :items="itemMnemonics"
                :headers="mnemonicsTableHeaders"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-body-2 dark-gray-text">
                      {{ item.mnemonic }}
                    </td>
                    <td class="text-body-2 dark-gray-text">
                      {{ item.material }}
                    </td>
                    <td class="text-body-2 dark-gray-text ">
                      {{ item.location }}
                    </td>
                    <td class="text-body-2 dark-gray-text">
                      {{ item.observation }}
                    </td>
                    <td class="text-body-2 dark-gray-text">
                      <v-btn
                        icon
                        text
                        small
                        color="error"
                        @click="removeItemMnemonic(item)"
                      >
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-tooltip right>
                <template #activator="{ on }">
                  <span
                    v-on="on"
                  >
                    <span>Gerenciamento de Cobertura de Agendas</span>
                  </span>
                </template>
                <span>
                  Está sessão permite que agendas sejam editadas em massa, possibilitando
                  adicionar ou remover o item da tabela de cobertura das agendas inclusas nos
                  filtros selecionados.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <create-agenda-item-bulk-update
            :item-id="itemToEdit?.id"
          />
          <v-row
            class="justify-end mt-4"
          >
            <v-col
              cols="2"
            >
              <v-btn
                type="submit"
                class="btn btn-primary"
                block
                large
                :loading="isLoading"
              >
                Salvar Item
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  required,
  minLength,
} from 'vuelidate/lib/validators'
import {
  assoc,
  equals,
  findIndex,
  isEmpty,
  isNil,
  not,
  pick,
  pluck,
  remove,
  values,
  __,
  includes,
  all,
  length,
  any,
  propEq,
  partition,
  defaultTo,
  uniq,
  map,
} from 'ramda'
import { ITEM } from 'amparo-enums'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'CreateItem',
  components: {
    CreateAgendaItemBulkUpdate: () => import('./CreateAgendaItemBulkUpdate'),
  },
  props: {
    itemToEdit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      item: {
        name: '',
        tussCode: '',
        type: '',
        specialityIds: [],
        exams: null,
      },
      itemMnemonic: {
        mnemonic: null,
        material: null,
        location: null,
        observation: null,
      },
      itemMnemonics: [],
      itemMnemonicsToCreate: [],
      itemMnemonicsToDelete: [],
      reminderCommunications: [],
      showDialog: true,
      itemTypeValues: values(ITEM.types),
      searchExam: null,
      noDataExamListMessage: 'Digite para buscar',
      examsList: [],
      mnemonicsTableHeaders: [
        {
          text: 'Mnemonico',
          width: '24%',
          sortable: false,
          class: 'text-body-1 font-weight-medium mnemonics-list__header-item',
        },
        {
          text: 'Material',
          width: '24%',
          sortable: false,
          class: 'text-body-1 font-weight-medium mnemonics-list__header-item',
        },
        {
          text: 'Localização',
          width: '24%',
          sortable: false,
          class: 'text-body-1 font-weight-medium mnemonics-list__header-item',
        },
        {
          text: 'Observação',
          width: '24%',
          sortable: false,
          class: 'text-body-1 font-weight-medium mnemonics-list__header-item',
        },
        {
          text: '',
          width: '4%',
          sortable: false,
          class: 'text-body-1 font-weight-medium mnemonics-list__header-item',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      specialities: 'speciality/specialities',
      isLoading: 'billing/isLoadingItem',
      isLoadingExams: 'exam/loading',
    }),
    ...mapState({
      user: ({ authentication }) => authentication.user,
    }),
    isItemMnemonicFullyFilled() {
      return this.itemMnemonic.mnemonic
        && this.itemMnemonic.material
        && this.itemMnemonic.location
    },
    isExamItem() {
      return equals(ITEM.types.labExam.value, this.item.type)
    },
    hasItemToEdit() {
      return not(isEmpty(this.itemToEdit))
    },
    sortedSpecialities() {
      const [selectedSpecialities, notSelectedSpecialities] = partition(
        ({ id }) => includes(id, defaultTo([], this.item.specialityIds)),
        this.specialities,
      )

      return [
        ...selectedSpecialities,
        ...notSelectedSpecialities,
      ]
    },
  },
  watch: {
    searchExam: debounce(async function search(searchExamValue) {
      const defaultText = 'Digite para buscar'
      this.noDataExamListMessage = defaultText

      if (
        !this.searchExam
        || !this.searchExam.length >= 1
      ) {
        this.noDataExamListMessage = defaultText
        return
      }

      await this.setExamsList(searchExamValue)

      if (this.examsList.length === 0) {
        this.noDataExamListMessage = 'Nenhum exame encontrado'
      }
    }, 1000),
    'item.type': function handleUpdateItemType(newType) {
      if (!equals(newType, ITEM.types.labExam.value)) {
        this.item.exams = []
      }
    },
  },
  validations() {
    const itemValidations = {
      item: {
        name: {
          required,
          minLength: minLength(2),
        },
        tussCode: {
          required,
        },
        type: {
          required,
        },
      },
    }

    return { ...itemValidations }
  },
  mounted() {
    if (this.hasItemToEdit) {
      this.fillFieldsToEdit()
    }
    this.listSpeciality({ returnAll: true })
  },
  methods: {
    ...mapActions({
      createItem: 'billing/createItem',
      createItemMnemonic: 'billing/createItemMnemonic',
      updateItem: 'billing/updateItem',
      deleteItemMnemonic: 'billing/removeItemMnemonic',
      listSpeciality: 'speciality/listSpeciality',
      listExam: 'exam/listExam',
      setSnackbar: 'snackbar/setSnackbar',
    }),
    async setExamsList(search) {
      const examsList = await this.listExam({
        search,
      })

      this.examsList = uniq([
        ...examsList,
        ...this.examsList,
      ])
    },
    shouldShowAllSelected(selectedItems, selectedItemsList) {
      return equals(length(selectedItems), length(selectedItemsList))
    },
    emitCloseDialog() {
      this.$emit('closeDialog')
    },
    async setItemExams() {
      if (!isEmpty(this.itemToEdit.itemExams)) {
        const examsData = map(
          itemExam => itemExam.exam || itemExam.examGroup,
          this.itemToEdit.itemExams,
        )
        this.examsList = examsData
        this.item.exams = examsData
      }
    },
    async fillFieldsToEdit() {
      this.item = pick(
        [
          'name',
          'tussCode',
          'type',
        ],
        this.itemToEdit,
      )
      await this.setItemExams()
      this.itemMnemonics = this.itemToEdit.itemMnemonics
      this.isLoadingItemSpecialities = true
      this.item.specialityIds = any(propEq(null, 'specialityId'), this.itemToEdit.specialityItems)
        ? pluck('id', this.specialities)
        : pluck('specialityId', this.itemToEdit.specialityItems)
      this.isLoadingItemSpecialities = false
    },
    addNewItemMnemonic() {
      this.itemMnemonicsToCreate.push(this.itemMnemonic)
      this.itemMnemonics.push(this.itemMnemonic)
      this.itemMnemonic = {
        mnemonic: null,
        material: null,
        location: null,
        observation: null,
      }
    },
    removeItemMnemonic(item) {
      const indexOnItemMnemonics = findIndex(equals(item), this.itemMnemonics)
      this.itemMnemonics = remove(indexOnItemMnemonics, 1, this.itemMnemonics)

      if (item.id) {
        this.itemMnemonicsToDelete.push(item.id)
      } else {
        const indexOnItemMnemonicsToCreate = findIndex(
          equals(item),
          this.itemMnemonicsToCreate,
        )

        this.itemMnemonicsToCreate = remove(
          indexOnItemMnemonicsToCreate,
          1,
          this.itemMnemonicsToCreate,
        )
      }
    },
    async handleItemMnemonics(itemId) {
      if (this.itemMnemonicsToCreate.length) {
        await this.createItemMnemonic({
          itemId,
          itemMnemonics: this.itemMnemonicsToCreate,
        })
      }

      if (this.itemMnemonicsToDelete.length) {
        await this.deleteItemMnemonic({
          ids: this.itemMnemonicsToDelete,
        })
      }
    },
    arraysHaveTheSameValues(array1, array2) {
      return all(includes(__, array2), array1)
        && all(includes(__, array1), array2)
    },
    getSpecialityFieldsFormatted() {
      const specialityIdsHasChanged = !isEmpty(this.itemToEdit)
        ? !this.arraysHaveTheSameValues(
          this.item.specialityIds,
          pluck('specialityId', this.itemToEdit.specialityItems),
        )
        : true
      const selectedAllSpecialities = this.shouldShowAllSelected(
        this.item.specialityIds,
        this.specialities,
      )

      return {
        specialityIds: specialityIdsHasChanged && !selectedAllSpecialities
          ? this.item.specialityIds
          : null,
        allSpecialities: selectedAllSpecialities,
      }
    },
    async setItemSpecialityIds(selectedValues) {
      this.item = assoc('specialityIds', pluck('id', selectedValues), this.item)
    },
    formatItem() {
      const item = {
        ...pick(
          [
            'name',
            'tussCode',
            'type',
          ],
          this.item,
        ),
        ...this.getSpecialityFieldsFormatted(),
      }

      const itemExamIds = this.item.exams

      const examIds = isNil(itemExamIds) || isEmpty(itemExamIds)
        ? []
        : pluck('id', itemExamIds)

      return {
        ...item,
        examIds,
      }
    },
    async handleSaveItem() {
      const feedback = {}
      this.$v.$touch()
      if (this.$v.$error) {
        feedback.status = 'error'
        feedback.message = 'Verifique os campos em vermelho'
        this.setSnackbar(feedback)
        return
      }
      try {
        if (this.hasItemToEdit) {
          await this.updateItem({
            id: this.itemToEdit.id,
            attributes: this.formatItem(),
          })
          if (this.isExamItem) {
            await this.handleItemMnemonics(this.itemToEdit.id)
          }
        } else {
          const item = await this.createItem(this.formatItem())
          if (this.isExamItem) {
            await this.handleItemMnemonics(item.id)
          }
        }
        this.emitCloseDialog()
        feedback.status = 'success'
        feedback.message = 'Item salvo com sucesso!'
      } catch (error) {
        feedback.status = 'error'
        feedback.message = 'Erro ao salvar item'
      }
      this.setSnackbar(feedback)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.v-btn.v-btn--text
  border solid 1px thin-gray

.mnemonics-list__table
  box-shadow 0px 3px 1px 0px #E0E0E0E5

.mnemonics-list__header-item
  color amparo-gray !important

.list-item-content
  border-bottom 1px solid thin-gray
</style>
