<template>
  <v-dialog
    :value="true"
    max-width="800"
    persistent
  >
    <v-card class="tw-p-4">
      <v-card-title class="tw-flex tw-justify-between tw-text-2xl tw-font-bold tw-mb-4">
        Adicionar à Linha de Cuidado

        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="tw-pb-0">
        <div
          v-if="showNewCareLinePatientModalAfterEncounter"
          class="tw-text-base tw-mb-6"
        >
          <p>
            O Paciente não foi inserido em <strong>nenhuma</strong> linha de cuidado.
          </p>
          <p>
            Insira o paciente em uma linha de cuidado. Dessa forma poderemos ajudá-lo
            a manter o paciente engajado no tratamento.
          </p>
        </div>

        <div>
          <v-autocomplete
            v-model="careLineId"
            class="tw-mb-6"
            label="Linha de Cuidado"
            item-text="name"
            item-value="id"
            hide-details
            :items="careLines"
            filled
          />

          <v-select
            v-model="status"
            class="tw-mb-6"
            label="Status na Linha de Cuidado"
            :items="conditionStatus"
            item-text="label"
            item-value="value"
            hide-details
            :disabled="shouldDisableStatusSearch"
            filled
          />
        </div>

        <div
          v-for="(careLineTag, index) in careLineTagsSelected"
          :key="index"
          class="tw-flex tw-gap-4 tw-items-center tw-mb-6"
        >
          <v-select
            v-model="careLineTagsSelected[index]"
            label="Tag"
            :items="getNotAlreadyUsedCareLineTagsList(index)"
            item-text="name"
            item-value="id"
            hide-details
            :disabled="shouldDisableStatusSearch"
            return-object
            filled
          />

          <v-select
            v-model="careLineTagsSelected[index].option"
            label="Opção"
            :items="careLineTagsSelected[index]?.careLineTagOptions"
            item-text="label"
            item-value="value"
            :disabled="!careLineTagsSelected[index]?.careLineTagOptions"
            return-object
            hide-details
            filled
          />

          <v-btn
            text
            icon
            color="error"
            @click="removeCareLineTagSelected(index)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>

        <div
          v-if="careLineId"
          class="tw-mb-6"
        >
          <v-btn
            class="tw-pl-0"
            color="primary"
            text
            :disabled="shouldDisableAddNewCareLineTagSelected"
            @click="addNewCareLineTagSelected"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            <span>
              Adicionar {{ careLineTagsSelected.length ? 'outra tag' : 'tag' }}
            </span>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="tw-px-6 tw-pb-2 tw-flex tw-justify-end">
        <v-btn
          color="primary"
          large
          :disabled="shouldDisableCreateButton"
          @click="handleCreateCareLinePatient"
        >
          Salvar Linha de cuidado
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  any,
  always,
  cond,
  equals,
  includes,
  isEmpty,
  isNil,
  pluck,
  reject,
  remove,
  T,
  values,
} from 'ramda'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CARE_LINE_PATIENT } from 'amparo-enums'

export default {
  name: 'EmrNewCareLinePatientDialog',
  data() {
    return {
      careLineId: null,
      status: null,
      careLineTagsSelected: [],
      conditionStatus: values(CARE_LINE_PATIENT.status),
      careLineTagsList: [],
    }
  },
  computed: {
    ...mapGetters({
      careLines: 'careLine/careLines',
      patient: 'emr/patient',
    }),
    ...mapState({
      showNewCareLinePatientModalAfterEncounter:
        ({ emr }) => emr.showNewCareLinePatientModalAfterEncounter,
    }),
    patientId() {
      return this.patient.id
    },
    shouldDisableCreateButton() {
      return isNil(this.careLineId)
        || isNil(this.status)
        || any(
          careLineTag => isNil(careLineTag) || isEmpty(careLineTag) || isNil(careLineTag.option),
          this.careLineTagsSelected,
        )
    },
    shouldDisableStatusSearch() {
      return isNil(this.careLineId)
    },
    shouldDisableAddNewCareLineTagSelected() {
      return this.careLineTagsList.length === this.careLineTagsSelected.length
    },
  },
  watch: {
    async careLineId(newCareLineId) {
      if (!isEmpty(newCareLineId) || !isNil(newCareLineId)) {
        this.status = null
        this.careLineTagsList = []
        this.careLineTagsList = await this.listCareLineTag({
          careLineId: newCareLineId,
          status: 'active',
        })
        this.careLineTagsSelected = this.careLineTagsList.length
          ? [{}]
          : []
      }
    },
  },
  async mounted() {
    await this.listCareLine({ status: 'active' })
  },
  methods: {
    ...mapActions({
      createCareLinePatient: 'careLine/createCareLinePatient',
      createCareLineTagPatient: 'careLine/createCareLineTagPatient',
      listCareLine: 'careLine/listCareLine',
      listCareLinePatient: 'careLine/listCareLinePatient',
      setEmrPatient: 'emr/setEmrPatient',
      setShowNewCareLinePatientModalAfterEncounter: 'emr/setShowNewCareLinePatientModalAfterEncounter',
      setSnackbar: 'snackbar/setSnackbar',
      listCareLineTag: 'careLine/listCareLineTag',
    }),
    getNotAlreadyUsedCareLineTagsList(careLineTagIndex) {
      const careLineTagsSelectedListWithoutSelectedIndex = remove(
        careLineTagIndex,
        1,
        this.careLineTagsSelected,
      )

      const careLineTagsListWithoutPreviousSelected = reject(
        careLineTag => includes(
          careLineTag.id,
          pluck('id', careLineTagsSelectedListWithoutSelectedIndex),
        ),
        this.careLineTagsList,
      )

      return careLineTagsListWithoutPreviousSelected
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    addNewCareLineTagSelected() {
      this.careLineTagsSelected.push({})
    },
    removeCareLineTagSelected(careLineTagIndex) {
      this.careLineTagsSelected = remove(
        careLineTagIndex,
        1,
        this.careLineTagsSelected,
      )
    },
    handleError(error) {
      const errorMessage = cond([
        [
          equals('care_line_has_already_been_added_to_patient'),
          always('O paciente já foi adicionado na linha de cuidado!'),
        ],
        [
          equals('patient_already_has_primary_care_line'),
          always('O paciente não pode ser adicionado em diferentes linhas primárias!'),
        ],
        [
          equals('hmo_of_patient_does_not_allow_this_care_line'),
          always('Este paciente faz parte do Amparo Conectado, portanto só poderá ser adicionado na linha de cuidado do Amparo Conectado'),
        ],
        [
          equals('hmo_of_patient_does_not_allow_care_lines'),
          always('O Plano desse paciente não permite que ele seja adicionado a nenhuma linha de cuidado!'),
        ],
        [
          T,
          always('Erro interno'),
        ],
      ])(error?.data?.errorCode)
      this.buildSnackbar('error', errorMessage)
    },
    async createCareLineTags() {
      for (const careLineTag of this.careLineTagsSelected) {
        // eslint-disable-next-line no-await-in-loop
        await this.createCareLineTagPatient({
          patientId: this.patientId,
          careLineTagId: careLineTag.id,
          careLineTagOptionId: careLineTag.option.id,
        })
      }
    },
    async handleCreateCareLinePatient() {
      try {
        await this.createCareLinePatient({
          careLineId: this.careLineId,
          status: this.status,
          patientId: this.patientId,
        })
        await this.listCareLinePatient({
          params: {
            patientId: this.patientId,
          },
        })
        await this.createCareLineTags()
        this.buildSnackbar('success', 'Paciente atribuído à linha de cuidado com sucesso!')
        await this.setEmrPatient(this.patientId)
        this.close()
      } catch ({ response }) {
        this.handleError(response)
      }
    },
    close() {
      this.setShowNewCareLinePatientModalAfterEncounter(false)
      this.$emit('closeDialog')
    },
  },
}
</script>
