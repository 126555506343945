<template>
  <div
    class="
      tw-grid
      tw-grid-cols-1
      sm:tw-grid-cols-2
      xl:tw-grid-cols-3
      tw-gap-x-6
      tw-gap-y-4
    "
  >
    <div
      v-for="filter in filters"
      :key="filter.key"
      class="filters"
    >
      <slot
        v-if="filter.type === 'custom'"
        name="custom"
      />

      <amparo-calendar
        v-else-if="filter.type === 'calendar'"
        :filter-object="filter"
        :data-testid="filter.testId"
      />

      <search-patient
        v-else-if="filter.type === 'search-patient'"
        ref="patientInput"
        hide-details
        label="Paciente"
        clearable
        filled
        :data-testid="filter.testId"
        @input="filter.model = $event"
      />

      <v-text-field
        v-else-if="filter.type === 'text-field'"
        v-model="filter.model"
        hide-details
        label="Ticket"
        clearable
        filled
        :data-testid="filter.testId"
      />

      <amparo-auto-complete
        v-else
        :filter-object="filter"
        :data-testid="filter.testId"
      />
    </div>

    <div
      v-if="disabledFilters.length"
      class="filters"
    >
      <v-autocomplete
        filled
        hide-details
        :items="disabledFilters"
        item-text="label"
        no-data-text="Não há mais filtros"
        item-value="key"
        label="Novo Filtro"
        @change="handleNewFilter($event)"
      />
    </div>

    <slot name="append" />
  </div>
</template>

<script>
import {
  prop,
  filter,
  values,
} from 'ramda'
import AmparoAutoComplete from './AmparoAutoComplete'
import AmparoCalendar from './AmparoCalendar'

export default {
  name: 'DinamicFilters',
  components: {
    AmparoAutoComplete,
    AmparoCalendar,
    SearchPatient: () => import('@/components/Common/SearchPatient'),
  },
  props: {
    filtersObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filters: [],
      disabledFilters: [],
    }
  },
  mounted() {
    this.filters = filter(prop('isActive'), values(this.filtersObject))
    this.disabledFilters = filter(
      ({ isActive, isListed }) => !isActive && isListed,
      values(this.filtersObject),
    )
  },
  methods: {
    handleNewFilter(filterKey) {
      this.filters.push(this.filtersObject[filterKey])
      this.disabledFilters = filter(({ key }) => key !== filterKey, this.disabledFilters)
    },
  },
}
</script>

<style lang="stylus" scoped>
.filters > .v-autocomplete >>> .v-input__control > .v-input__slot:before
  border none !important

.filters > .v-autocomplete
  border-bottom 2px solid rgba(0,0,0,.42)

</style>
