<template>
  <div>
    <nav>
      <v-tabs
        v-model="tab"
        grow
        height="55"
        background-color="light-green"
        hide-slider
        class="tw-w-full"
      >
        <v-tab active-class="background-color: primary">
          <span class="tw-text-white tw-font-bold">
            Prontuário
          </span>
        </v-tab>

        <v-tab active-class="color: primary">
          <span class="tw-text-white tw-font-bold">
            Exames
          </span>
        </v-tab>

        <v-tab
          v-if="userHasAccessToEmrForm"
          active-class="color: primary"
        >
          <span class="tw-text-white tw-font-bold tw-mr-2">
            Formulários
          </span>
          <v-badge
            v-if="uncompletedFormsLength > 0"
            inline
            color="danger"
            :content="uncompletedFormsLength"
          />
        </v-tab>

        <v-tab
          v-if="isWorkSpaceAmparo && !isPatientHmoPackageTypeNone"
          active-class="color: primary"
        >
          <span class="tw-text-white tw-font-bold">
            Tarefas
          </span>
        </v-tab>
      </v-tabs>
    </nav>

    <main
      v-if="shouldLoadComponents"
      class="tw-grid tw-grid-cols-12"
    >
      <div class="tw-col-span-9">
        <v-tabs-items
          v-model="tab"
          class="tw-w-full"
        >
          <v-tab-item>
            <emr-history />
          </v-tab-item>

          <v-tab-item>
            <emr-exams />
          </v-tab-item>

          <v-tab-item v-if="userHasAccessToEmrForm">
            <patient-forms-list />
          </v-tab-item>

          <v-tab-item v-if="isWorkSpaceAmparo">
            <emr-schedule />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <aside class="tw-col-span-3">
        <emr-resume />
      </aside>
    </main>

    <emr-duplicated-encounter-warning-modal
      v-if="shouldShowDuplicatedEncounterWarningModal"
    />
  </div>
</template>

<script>
import EmrResume from '@/components/Common/EmrResume'
import { mapActions, mapGetters } from 'vuex'
import {
  not,
  isEmpty,
} from 'ramda'
import EmrHistory from './EmrHistory'

export default {
  components: {
    EmrHistory,
    EmrResume,
    EmrExams: () => import('./EmrExams'),
    EmrSchedule: () => import('./EmrSchedule'),
    PatientFormsList: () => import('./PatientFormsList'),
    EmrDuplicatedEncounterWarningModal: () => import('./EmrDuplicatedEncounterWarningModal'),
  },
  data() {
    return {
      tab: null,
      shouldLoadComponents: false,
    }
  },
  computed: {
    ...mapGetters({
      patient: 'emr/patient',
      isPatientHmoPackageTypeNone: 'emr/isPatientHmoPackageTypeNone',
      shouldShowDuplicatedEncounterWarningModal: 'emr/shouldShowDuplicatedEncounterWarningModal',
      uncompletedFormsLength: 'patientForm/uncompletedFormsLength',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
      userHasAccessToFunctionality: 'authentication/userHasAccessToFunctionality',
    }),
    userHasAccessToEmrForm() {
      return this.userHasAccessToFunctionality.emrForm
    },
  },
  watch: {
    '$route.params.id': function clearOldEncounter() {
      this.clearEncounter()
    },
  },
  async activated() {
    if (isEmpty(this.patient)) {
      await this.setEmrPatient(this.$route.params.id)
    }
    if (not(isEmpty(this.patient))) {
      this.shouldLoadComponents = true
    }
    this.patientForms = this.getPatientForms({
      patientId: this.patient.id,
      destiny: ['emr', 'patientAndEmr'],
    })
  },
  methods: {
    ...mapActions({
      getPatientForms: 'patientForm/getPatientForms',
      setEmrPatient: 'emr/setEmrPatient',
      clearEncounter: 'emr/clearEncounter',
    }),
  },
}
</script>
