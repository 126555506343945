<template>
  <div>
    <div class="tw-w-full tw-bg-green-frostee tw-px-12 tw-py-6">
      <div class="tw-grid tw-gap-4 md:tw-grid-cols-5">
        <v-text-field
          v-model="filters.name"
          filled
          label="Buscar por nome"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listUserResults()"
          @click:clear="listUserResults()"
        />
        <v-text-field
          v-model="filters.email"
          filled
          label="Buscar por E-mail"
          clearable
          no-data-text="Digite para buscar"
          hide-details
          @change="listUserResults()"
          @click:clear="listUserResults()"
        />
        <v-autocomplete
          v-model="filters.professionId"
          :items="professions"
          item-text="name"
          item-value="id"
          clearable
          filled
          label="Profissão"
          hide-details
          @change="listUserResults()"
          @click:clear="listUserResults()"
        />
        <v-select
          v-model="filters.isActive"
          :items="isActiveItems"
          item-text="label"
          item-value="value"
          clearable
          filled
          label="Status"
          hide-details
          @change="listUserResults()"
          @click:clear="listUserResults()"
        />
        <v-btn
          class="btn-filter--height tw-font-semibold"
          color="dark-green"
          dark
          block
          large
        >
          <v-icon left>
            mdi-magnify
          </v-icon>
          Buscar
        </v-btn>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="tw-mt-12 tw-text-center"
    >
      <circular-loader />
    </div>

    <div
      v-else
      class="tw-my-12 tw-px-12"
    >
      <div
        v-if="isUsersEmpty"
      >
        <h2 class="tw-tracking-tighter tw-text-6xl tw-font-extralight tw-text-[#00000061]">
          Nenhum resultado encontrado.
        </h2>
      </div>

      <div
        v-for="(userWorkSpace, index) in users"
        v-else
        :key="index"
        class="
          tw-grid
          tw-grid-cols-12
          tw-p-5
          tw-mb-4
          tw-rounded-lg
          tw-border-solid
          tw-border-[1px]
          tw-border-gray-thin
        "
      >
        <div class="tw-col-span-6 tw-pa-0">
          <p class="tw-text-[#000000de] tw-text-2xl tw-mb-1">
            {{ userWorkSpace.user.name }}
          </p>

          <p class="tw-text-base tw-leading-8 tw-tracking-wider tw-text-[#00000099] tw-mb-0">
            E-mail: {{ userWorkSpace.user.email }}
          </p>
        </div>

        <div class="tw-col-span-3 tw-pa-0">
          <p class="tw-text-base tw-leading-8 tw-tracking-wider tw-text-[#00000099] tw-mb-0">
            Profissão: {{ userWorkSpace.user.profession.name }}
          </p>
        </div>

        <div class="tw-col-span-3">
          <div
            v-if="!userWorkSpace.deletedAt"
            class="tw-flex tw-flex-row tw-justify-end tw-gap-x-2"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="editDialog(userWorkSpace)"
                  v-on="on"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  icon
                  @click="openDeleteDialog(userWorkSpace)"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Inativar</span>
            </v-tooltip>
          </div>

          <div
            v-else
          >
            <div class="tw-flex tw-flex-row tw-justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    @click="openRestoreDialog(userWorkSpace)"
                    v-on="on"
                  >
                    <v-icon>mdi-recycle</v-icon>
                  </v-btn>
                </template>
                <span>Ativar</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="userNumberOfPages > 1"
        class="tw-grid tw-grid-cols-3 tw-justify-center tw-items-center tw-my-4"
      >
        <v-pagination
          v-model="page"
          class="tw-col-start-2"
          :length="userNumberOfPages"
          :total-visible="11"
          @input="listUserResults(page)"
        />

        <span class="tw-text-right">
          {{ userCount }} profissionais
        </span>
      </div>
    </div>

    <create-user
      v-if="showEditUserDialog"
      edit-user
      :user-data="editedUser"
      @closeDialog="closeCreateUserDialog"
    />

    <delete-user-dialog
      v-if="showDeleteUserDialog"
      :user-data="deletedUser"
      @listUserResults="listUserResults"
      @closeDialog="closeDeleteDialog"
    />

    <restore-user-dialog
      v-if="showRestoreUserDialog"
      :user-data="restoredUser"
      @listUserResults="listUserResults"
      @closeDialog="closeRestoreDialog"
    />
  </div>
</template>

<script>
import {
  isEmpty,
  values,
} from 'ramda'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UserList',
  components: {
    CreateUser: () => import('./CreateUser'),
    DeleteUserDialog: () => import('./DeleteUserDialog'),
    RestoreUserDialog: () => import('./RestoreUserDialog'),
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  data() {
    return {
      showEditUserDialog: false,
      editedUser: {},
      filters: {
        isActive: true,
      },
      showDeleteUserDialog: false,
      showRestoreUserDialog: false,
      restoredUser: {},
      deletedUser: {},
      page: 1,
      isActiveItems: values({
        TRUE: {
          label: 'Ativo',
          value: true,
        },
        FALSE: {
          label: 'Inativo',
          value: false,
        },
      }),
    }
  },
  computed: {
    ...mapGetters({
      professions: 'profession/professions',
      isLoading: 'authentication/loadingUser',
      users: 'authentication/users',
      userCount: 'authentication/userCount',
      userNumberOfPages: 'authentication/userNumberOfPages',
    }),
    isUsersEmpty() {
      return isEmpty(this.users)
    },
  },
  async mounted() {
    await this.listProfession()
    this.listUserResults()
  },
  methods: {
    ...mapActions({
      listProfession: 'profession/listProfession',
      listUser: 'authentication/listUser',
    }),
    listUserResults(page = 1) {
      return this.listUser({
        ...this.filters,
        page,
      })
    },
    openDeleteDialog(userData) {
      this.deletedUser = userData.user
      this.showDeleteUserDialog = true
    },
    closeDeleteDialog() {
      this.deletedUser = {}
      this.showDeleteUserDialog = false
    },
    openRestoreDialog(userData) {
      this.restoredUser = userData.user
      this.showRestoreUserDialog = true
    },
    closeRestoreDialog() {
      this.restoredUser = {}
      this.showRestoreUserDialog = false
    },
    editDialog(userData) {
      this.editedUser = userData
      this.showEditUserDialog = true
    },
    closeCreateUserDialog() {
      this.editedUser = {}
      this.showEditUserDialog = false
    },
  },
}
</script>

<style lang='stylus' scoped>
@import '../../style/_core/colors.styl'
.v-btn.v-btn--text
  border solid 1px thin-gray
</style>
