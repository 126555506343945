<template>
  <div>
    <div class="filters-container tw-py-7">
      <div class="tw-grid tw-grid-cols-12 tw-px-11 tw-gap-6">
        <dinamic-filters
          class="tw-col-span-10"
          :filters-object="scheduleFilters"
          :filters-size="scheduleFiltersSize"
        />

        <div class="tw-col-span-2 tw-flex">
          <v-btn
            class="tw-text-white tw-font-bold tw-h-14"
            color="dark-green"
            block
            large
            :disabled="!hasCareTeam"
            @click="updateSchedulesList"
          >
            Filtrar
          </v-btn>
        </div>
      </div>
    </div>

    <div class="quick-filters__container tw-py-4 tw-px-11">
      <v-chip-group
        v-model="scheduleFilters.status.model"
        multiple
        active-class="quick-filters--active"
        filter
        @change="applyStatusFilter()"
      >
        <v-chip
          class="quick-filters"
          value="Todos"
          :class="{'quick-filters--active': isAllFilterButtonActive}"
          :disabled="!hasCareTeam"
        >
          <span>Todas</span>
        </v-chip>
        <v-chip
          v-for="filter in quickFilters.status"
          :key="filter"
          class="quick-filters"
          :value="filter"
          :disabled="!hasCareTeam"
        >
          <span>{{ filter }}</span>
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
import {
  equals,
  filter,
  hasPath,
  includes,
  isNil,
  map,
  path,
  pick,
  pluck,
  prop,
  reject,
  values,
} from 'ramda'
import moment from 'moment'
import { SCHEDULE } from 'amparo-enums'
import DinamicFilters from '@/components/Common/DinamicFilters'
import { mapGetters, mapActions, mapState } from 'vuex'
import removeNilOrEmpty from '@/utils/removeNilOrEmpty'

export default {
  name: 'ScheduleFilters',
  components: {
    DinamicFilters,
  },
  data: () => ({
    scheduleFilters: {
      patient: {
        isActive: true,
        key: 'patient',
        type: 'search-patient',
        label: 'Paciente',
        model: null,
        isListed: false,
      },
      careTeam: {
        isActive: true,
        key: 'careTeam',
        label: 'Equipe',
        multiple: true,
        model: [],
        items: [],
        isListed: false,
      },
      status: {
        isActive: false,
        key: 'status',
        label: 'Status',
        multiple: true,
        model: [],
        items: values(
          pick(
            [
              'autoFinished',
              'canceled',
              'canceledByStored',
              'finished',
              'inProgress',
              'late',
              'open',
              'unsuccessfulContact',
            ],
            SCHEDULE.status,
          ),
        ),
        isListed: false,
      },
      date: {
        isActive: false,
        key: 'date',
        type: 'calendar',
        isRange: true,
        label: 'Data',
        model: {},
        isListed: true,
      },
      priorities: {
        isActive: false,
        key: 'priorities',
        label: 'Prioridade',
        multiple: true,
        itemText: 'title',
        itemValue: 'number',
        model: [],
        items: [
          {
            number: 1,
            title: 'Verde',
          },
          {
            number: 2,
            title: 'Amarela',
          },
          {
            number: 3,
            title: 'Vermelha',
          },
        ],
        searchProfessional: null,
        isListed: true,
      },
      types: {
        isActive: false,
        key: 'types',
        label: 'Tipo',
        itemValue: 'value',
        itemText: 'title',
        model: '',
        items: [],
        multiple: true,
        isListed: true,
      },
      ticketNumber: {
        isActive: false,
        key: 'ticketNumber',
        type: 'text-field',
        label: 'Ticket',
        model: '',
        isListed: false,
      },
    },
    isAllFilterButtonActive: false,
    quickFilters: {
      status: values(
        pick(
          [
            'canceled',
            'canceledByStored',
            'finished',
            'inProgress',
            'late',
            'open',
            'unsuccessfulContact',
          ],
          SCHEDULE.status,
        ),
      ),
    },
  }),
  computed: {
    ...mapGetters({
      careTeams: 'careTeam/careTeamsWithNone',
    }),
    ...mapState({
      user: ({ authentication }) => authentication.user,
    }),
    userProfessionalId() {
      return path(['professional', 'id'], this.user) || null
    },
    hasCareTeam() {
      return this.scheduleFilters.careTeam?.model?.length > 0
    },
  },
  watch: {
    careTeams(careTeams) {
      this.scheduleFilters.careTeam.items = careTeams
    },
  },
  async mounted() {
    await this.setCareTeamFilter()
    this.fillInitialFilterValues()
  },
  methods: {
    ...mapActions({
      getSchedules: 'schedule/getSchedules',
      listCareTeam: 'careTeam/listCareTeam',
      getProfessional: 'professional/getProfessional',
      setScheduleFilters: 'schedule/setScheduleFilters',
      setSchedulePagination: 'schedule/setSchedulePagination',
    }),
    applyStatusFilter() {
      if (includes('Todos', this.scheduleFilters.status.model)) {
        this.scheduleFilters.status.model = []
        this.isAllFilterButtonActive = true
      } else {
        this.scheduleFilters.status.model = reject(equals('Todos'), this.scheduleFilters.status.model)
        this.isAllFilterButtonActive = this.scheduleFilters.status.model.length === 0
      }
      this.updateSchedulesList()
    },
    updateSchedulesList() {
      const {
        careTeam,
        status,
        date,
        priorities,
        types,
        ticketNumber,
        patient,
      } = this.scheduleFilters
      const { start, end } = this.formatDateParams(date.model)


      const filters = {
        start,
        end,
        careTeamIds: careTeam.model.map(prop('id')),
        priorities: priorities.model.map(prop('number')),
        types: pluck('value', types.model),
        status: status.model,
        ticketNumber: ticketNumber.model,
        isAllCareTeamsFilter: equals(careTeam.model.length, careTeam.items.length),
        patientId: path(['model', 'id'], patient),
      }
      this.setScheduleFilters(removeNilOrEmpty(filters))
      this.setSchedulePagination({ page: 1 })
      this.getSchedules()
    },
    fillInitialFilterValues() {
      const professionalId = this.userProfessionalId
      this.scheduleFilters.status.model.push('Aberto')
      this.scheduleFilters.types.items = filter(
        ({ value }) => includes(value, path(['user', 'permission', 'policy', 'dataAccess', 'scheduleType'], this)),
        values(SCHEDULE.types),
      )
      if (professionalId) {
        this.listCareTeam()
      }

      if (this.hasCareTeam) {
        this.updateSchedulesList()
      }
    },
    formatDateParams(date) {
      if (!date) return { start: null, end: null }
      return {
        start: moment(date.start).format('YYYY-MM-DD'),
        end: moment(date.end).format('YYYY-MM-DD'),
      }
    },
    isFilteringByAllTeams() {
      return isNil(this.filters.careTeamIds)
    },
    async setCareTeamFilter() {
      if (!this.user.professional?.id) {
        return
      }

      const { data } = await this.getProfessional(this.user.professional.id)
      if (hasPath(['careTeams'], data)) {
        const careTeamIds = map(prop('id'), data.careTeams)
        const careTeamToSelect = filter(
          ({ id }) => includes(id, careTeamIds),
          this.scheduleFilters.careTeam.items,
        )
        this.scheduleFilters.careTeam.model = careTeamToSelect
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'
.quick-filters
  background transparent !important
  border 1px solid amparo-green-tech !important
  border-radius 2rem !important
  color amparo-green-tech !important
  font-size 1.1rem !important
  font-weight 500 !important
  padding 1.2rem !important

.quick-filters--active
  background amparo-green-tech !important
  color #FFF !important

.quick-filters__container > .v-chip-group >>> *
  justify-content space-between

</style>
