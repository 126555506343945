<template>
  <div class="audit-list pa-12">
    <div
      v-if="hasPatient"
    >
      <v-card
        v-for="patient in patientRegistrations.patientRegistrations"
        :key="patient.id"
        class="audit-list__card mb-4"
      >
        <v-card-title class="card__title">
          {{ patient | formatDisplayName }}
        </v-card-title>
        <v-card-text class="d-flex">
          <v-layout
            v-for="(column, indexColumn) in cardStructure"
            :key="indexColumn"
            column
          >
            <v-flex
              v-for="(item, indexRow) of column"
              :key="indexRow"
              class="columns__info"
              column
            >
              <span class="info__title">{{ item.label }}</span>
              {{ formatedPatientData(patient[item.key], item.key) }}
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex column>
              <div class="mb-12">
                <span class="info__title">STATUS: </span>{{ translateStatus[patient.status] }}
              </div>
              <v-flex
                v-if="statusIsTodo(patient.status)"
              >
                <v-btn
                  class="btn btn-primary"
                  @click="auditPatient(patient, auditStatus.approved.value)"
                >
                  APROVAR
                </v-btn>
                <v-btn
                  class="btn btn-danger"
                  @click="auditPatient(patient, auditStatus.refused.value)"
                >
                  RECUSAR
                </v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
      <div class="text-center">
        <v-pagination
          :length="totalPages"
          :total-visible="itemsPerPage"
          @input="changePage($event)"
        />
      </div>
    </div>
    <div
      v-else
    >
      <v-card>
        <v-card-text class="text-center">
          Não há registros de paciente
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import {
  always,
  cond,
  defaultTo,
  equals,
  T,
} from 'ramda'
import { AUDIT } from 'amparo-enums'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      itemsPerPage: 30,
      cardStructure: {
        firstColumn: [
          {
            key: 'name',
            label: 'NOME',
          },
          {
            key: 'cpf',
            label: 'CPF',
          },
          {
            key: 'healthInsurancePlanNumber',
            label: 'CARTEIRINHA',
          },
          {
            label: 'CARTEIRA DO RESPONSÁVEL',
            key: 'responsibleHealthInsurancePlanNumber',
          },
        ],
        secondColumn: [
          {
            key: 'email',
            label: 'E-MAIL',
          },
          {
            key: 'telphone',
            label: 'TELEFONE',
          },
          {
            key: 'birthDate',
            label: 'NASCIMENTO',
          },
          {
            key: 'nationality',
            label: 'NACIONALIDADE',
          },
          {
            key: 'biologicalSex',
            label: 'SEXO',
          },
        ],
      },
      translateStatus: {
        [AUDIT.status.approved.value]: AUDIT.status.approved.label,
        [AUDIT.status.refused.value]: AUDIT.status.refused.label,
        [AUDIT.status.todo.value]: AUDIT.status.todo.label,
      },
    }
  },
  computed: {
    ...mapGetters({
      patientRegistrations: 'patientRegistration/patientRegistrations',
    }),
    auditStatus() {
      return AUDIT.status
    },
    hasPatient() {
      return this.patientRegistrations.count
    },
    totalPages() {
      return Math.ceil(this.patientRegistrations.count / this.itemsPerPage)
    },
  },
  methods: {
    formatedPatientData(value, key) {
      return cond([
        [equals('biologicalSex'), () => this.$filters.biologicalSex(value)],
        [equals('telphone'), () => this.$filters.formatPhone(value)],
        [equals('birthDate'), () => this.$filters.formatDate(value)],
        [equals('cpf'), () => this.$filters.formatCpf(value)],
        [equals('responsibleHealthInsurancePlanNumber'), always(defaultTo('Não possui', value))],
        [T, always(value)],
      ])(key)
    },
    changePage(page) {
      this.$emit('update:page', { page })
    },
    upList() {
      document.getElementById('audit__field-name').focus()
    },
    auditPatient(patient, status) {
      this.$emit('audit', { patient, status })
    },
    statusIsTodo(status) {
      return equals(status, this.auditStatus.todo.value)
    },
  },
}
</script>
<style scoped lang="stylus">
.audit-list__card
  border-radius 7px
.columns__info
  margin-bottom .7rem
  flex-direction column
.info__title
  text-transform uppercase
  letter-spacing .1rem
  font-size .8rem
  color secondary-color
.card__title
  font-size 1.5rem
  padding-bottom 0
</style>
