<template>
  <v-dialog
    :value="true"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
          <v-toolbar-title class="tw-ml-6">
            Pacientes Importados
          </v-toolbar-title>

          <v-btn
            icon
            dark
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>

      <section class="tw-px-10 tw-py-6">
        <v-alert
          v-if="shouldShowNewDownloadOption"
          color="#FF5C5C"
          type="error"
          icon="mdi-alert-circle"
          class="alert alert-error tw-py-4 tw-px-6"
        >
          <div class="tw-flex tw-items-center tw-justify-between">
            <span class="tw-font-semibold"> {{ errorMessage }}</span>

            <v-btn
              v-if="shouldShowDownloadOption"
              outlined
              @click="openDownloadLogError(patientImportData.donwloadLogLink)"
            >
              baixar arquivo de log
            </v-btn>

            <v-btn
              v-else
              outlined
              :disabled="loadingErrors"
            >
              <json-csv
                v-bind="$props"
                :data="jsonErrors"
              >
                baixar arquivo de log
              </json-csv>
            </v-btn>
          </div>
        </v-alert>

        <section
          v-if="isLoadingPatientList || isLoadingListErrors"
          class="tw-flex tw-justify-center"
        >
          <circular-loader />
        </section>

        <section v-else-if="!patientImportData.importSuccessCount">
          <h2 class="info-text tw-font-extralight">
            Nenhum paciente importado.
          </h2>
        </section>

        <section v-else>
          <div v-if="isInsertImport">
            <v-alert
              v-if="patientImportData.importCreateSuccessCount"
              class="alert alert-success tw-py-4 tw-px-6"
              type="success"
              color="light-green"
            >
              <span class="tw-font-semibold">
                Inserção | {{ patientImportData.importCreateSuccessCount }}
                paciente(s) importado(s) com sucesso
              </span>
            </v-alert>

            <v-alert
              v-if="patientImportData.importUpdateSuccessCount"
              class="alert alert-success py-4 px-6"
              type="success"
              color="light-green"
            >
              <span class="tw-font-semibold">
                Atualização | {{ patientImportData.importUpdateSuccessCount }}
                paciente(s) atualizado(s) com sucesso
              </span>
            </v-alert>
          </div>

          <div v-else>
            <v-alert
              class="alert alert-success tw-py-4 tw-px-6"
              type="success"
              color="light-green"
            >
              <span class="tw-font-semibold">
                Arquivamento | {{ patientImportData.importSuccessCount }} paciente(s)
                arquivado(s) com sucesso
              </span>
            </v-alert>
          </div>

          <v-data-table
            :headers="headers"
            :items="patientList"
            class="patients-table table--striped"
            no-data-text="Não há pacientes importados para serem exibidos"
            disable-sort
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item="patient">
              <tr>
                <td>
                  {{ patient.item.name }}
                </td>
                <td>
                  {{ patient.item.cpf | formatCpf }}
                </td>
                <td>
                  {{ patient.item.email }} <br>
                  {{ patient.item.telphone }}
                </td>
                <td>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        text
                        icon
                        :to="{ name: 'patientProfile', params: { id: patient.item.id}}"
                        v-on="on"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>

          <section
            v-if="numberOfPages > 1"
            class="tw-grid tw-grid-cols-3 tw-mt-3 tw-items-center"
          >
            <v-pagination
              v-model="page"
              class="tw-col-start-2"
              :length="numberOfPages"
              :total-visible="11"
              @input="listPatientsResults(page)"
              @next="listPatientsResults(page + 1)"
              @previous="listPatientsResults(page - 1)"
            />

            <span class="tw-justify-self-end">
              {{ patientImportData.importSuccessCount }} pacientes
            </span>
          </section>
        </section>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  equals,
  isEmpty,
  map,
} from 'ramda'
import SearchPatient from '@/mixins/searchers/patient'
import { mapActions, mapGetters } from 'vuex'
import JsonCsv from 'vue-json-csv'

export default {
  name: 'PatientList',
  components: {
    CircularLoader: () => import('@/components/UI/CircularLoader'),
    JsonCsv,
  },
  mixins: [SearchPatient],
  props: {
    patientImportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoadingPatientList: false,
      isLoadingListErrors: false,
      numberOfPages: null,
      page: 1,
      dialog: false,
      jsonErrors: null,
      headers: [
        {
          text: 'Nome',
        },
        {
          text: 'CPF',
        },
        {
          text: 'Contato',
        },
        {
          text: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      loadingErrors: 'importFileQueue/loadingErrors',
    }),
    shouldShowDownloadOption() {
      return this.patientImportData.logFileKey && this.patientImportData.importErrorCount > 0
    },
    shouldShowNewDownloadOption() {
      return this.patientImportData.importErrorCount > 0
        && this.patientImportData.importTotalCount && this.jsonErrors
    },
    isInsertImport() {
      return equals('insert', this.patientImportData.operation)
    },
    errorMessage() {
      if (equals('insert', this.patientImportData.operation)) {
        return `Inserção | ${this.patientImportData.importErrorCount} pacientes não importados`
      }
      return `Arquivamento | ${this.patientImportData.importErrorCount} pacientes não arquivados`
    },
    isPatientListEmpty() {
      return isEmpty(this.patientList) && !equals('delete', this.patientImportData.operation)
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'snackbar/setSnackbar',
      listErrors: 'importFileQueue/listErrors',
    }),
    listPatientsResults(page) {
      this.isLoadingPatientList = true

      this.$http.get('patient', { params: { page, importFileQueueId: this.patientImportData.id } })
        .then((res) => {
          this.patientList = res.data.patients
          this.numberOfPages = res.data.numberOfPages
        })
        .catch(() => this.buildSnackbar('error', 'Falha ao carregar pacientes.'))
        .finally(() => {
          this.isLoadingPatientList = false
        })
    },
    buildSnackbar(status, message) {
      this.setSnackbar({ status, message })
    },
    getFormattedJsonErrors(errors) {
      return map(
        erro => ({
          NOME_COMPLETO: erro.NOME_COMPLETO,
          CPF: erro.CPF,
          DATA_NASCIMENTO: erro.DATA_NASCIMENTO,
          SEXO: erro.SEXO,
          EMAIL: erro.EMAIL,
          TELEFONE: erro.TELEFONE,
          CEP: erro.CEP,
          LOGRADOURO: erro.LOGRADOURO,
          NUMERO: erro.NUMERO,
          BAIRRO: erro.BAIRRO,
          CIDADE: erro.CIDADE,
          ESTADO: erro.ESTADO,
          CARTEIRA: erro.CARTEIRA,
          CPF_TITULAR: erro.CPF_TITULAR,
          POSSUI_DEFICIENCIA: erro.POSSUI_DEFICIENCIA,
          ERRO: erro.ERRO,
        }),
        errors,
      )
    },
    async loadData() {
      this.listPatientsResults(1)
      this.isLoadingListErrors = true
      const errors = await this.listErrors({ importFileQueueId: this.patientImportData.id })
      this.isLoadingListErrors = false
      this.jsonErrors = this.getFormattedJsonErrors(errors)
    },
    openDownloadLogError(link) {
      window.open(link)
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../../style/_core/colors.styl'

.info-text
  letter-spacing -0.5px
  color rgba(0, 0, 0, 0.38)
  @media only screen and (min-width: 601px)
    font-size 32px
  @media only screen and (min-width: 1201px)
    font-size 48px
  @media only screen and (min-width: 1601px)
    font-size 64px

.alert
  >>> div > i
    font-size 2rem

.alert-success
  >>> div > i
    color #00AF66

.alert-error
  >>> div > i
    padding-top 10px

.patients-table >>> thead > tr
  background-color #3233380D
  box-shadow 0px 3px 1px 0px #E0E0E0E5
  font-size 0.9rem !important

.patients-table >>> thead > tr > th
  color #707372 !important
  font-size 0.9rem !important
  padding 16px !important

.patients-table >>> tbody > tr
  box-shadow 0px 0.5px 2px 0px #DBDBDB

.patients-table >>> tbody > tr > td
  color #323338CC !important
  padding 16px !important
  font-weight 400

</style>
