import { render, staticRenderFns } from "./CreatePermission.vue?vue&type=template&id=294a7686&scoped=true&"
import script from "./CreatePermission.vue?vue&type=script&lang=js&"
export * from "./CreatePermission.vue?vue&type=script&lang=js&"
import style0 from "./CreatePermission.vue?vue&type=style&index=0&id=294a7686&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294a7686",
  null
  
)

export default component.exports