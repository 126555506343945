<template>
  <v-dialog
    v-if="isOpen"
    :value="true"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>Editar Usuário</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="$emit('closeDialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="isLoading"
        class="tw-mt-12 tw-text-center"
      >
        <circular-loader />
      </div>
      <div
        v-else
        fluid
        class="tw-px-12 tw-py-6"
      >
        <div class="tw-grid tw-grid-cols-12 tw-gap-x-6">
          <h2 class="tw-text-2xl tw-text-[#000000de] tw-mb-4 tw-col-span-full">
            Dados Pessoais
          </h2>
          <v-text-field
            v-model="user.name"
            class="tw-col-span-6"
            label="Nome Completo"
            clearable
            filled
            :error-messages="$v.user.name.$error ? 'Nome e sobrenome necessários' : ''"
            :error="$v.user.name.$error"
            @blur="$v.user.name.$touch()"
          />
          <v-text-field
            v-model="user.email"
            class="tw-col-span-6"
            label="E-mail"
            clearable
            filled
            :error="$v.user.email.$error"
            @blur="$v.user.email.$touch()"
          />
          <v-autocomplete
            v-model="user.professionId"
            class="tw-col-span-4"
            label="Profissão"
            :items="professions"
            item-text="name"
            item-value="id"
            filled
            :error="$v.user.professionId.$error"
            @blur="$v.user.professionId.$touch()"
          />
          <v-autocomplete
            v-model="user.permissionId"
            class="tw-col-span-4"
            label="Nível de Permissão"
            :items="formattedPermissions"
            item-text="name"
            item-value="id"
            filled
            :error="$v.user.permissionId.$error"
            @blur="$v.user.permissionId.$touch()"
          />
          <v-autocomplete
            v-model="user.enterpriseId"
            class="tw-col-span-4"
            label="Empresa"
            :items="formattedEnterprises"
            item-text="name"
            item-value="id"
            filled
            :error="$v.user.enterpriseId.$error"
            @blur="$v.user.enterpriseId.$touch()"
          />
          <div class="tw-col-span-full tw-flex tw-flex-row tw-items-center tw-gap-x-6 tw-mb-6">
            <v-btn
              fab
              dark
              small
              color="primary"
              @click="changeClinicalProfessional"
            >
              <v-icon
                v-if="!hasClinicalProfessional || editUser"
                dark
              >
                mdi-plus
              </v-icon>
              <v-icon
                v-else
                dark
              >
                mdi-minus
              </v-icon>
            </v-btn>
            <p class="tw-text-2xl tw-text-[#000000de] tw-mb-0">
              Profissional Clínico
            </p>
          </div>
        </div>
        <div
          v-if="hasClinicalProfessional"
          class="tw-grid tw-grid-cols-12 tw-gap-x-6"
        >
          <v-text-field
            v-model="user.startedAtClinic"
            v-maska="masks.date"
            class="tw-col-span-4"
            label="Começou na Clínica em"
            hint="DD/MM/AAAA"
            append-icon="mdi-calendar"
            return-masked-value
            clearable
            filled
            :error-messages="$v.user.startedAtClinic.$error ? 'Data inválida' : ''"
            :error="$v.user.startedAtClinic.$error"
            @blur="$v.user.startedAtClinic.$touch()"
          />
          <v-select
            v-model="user.biologicalSex"
            class="tw-col-span-4"
            label="Sexo biológico"
            filled
            :items="formOptions.biologicalSexItems"
            item-text="title"
            item-value="value"
            :error="$v.user.biologicalSex.$error"
          />
          <v-text-field
            v-model="user.telphone"
            v-maska="masks.telphone"
            class="tw-col-span-4"
            label="Telefone"
            clearable
            filled
            :error-messages="$v.user.telphone.$error ? 'Número inválido' : ''"
            :error="$v.user.telphone.$error"
            @blur="$v.user.telphone.$touch()"
          />
          <v-autocomplete
            v-model="user.documentType"
            class="tw-col-span-4"
            label="Tipo do Documento"
            filled
            :items="formOptions.documentsTypes"
            :error="$v.user.documentType.$error"
            @blur="$v.user.documentType.$touch()"
          />
          <v-text-field
            v-model="user.documentNumber"
            class="tw-col-span-4"
            label="Número do Documento"
            clearable
            filled
            :error="$v.user.documentNumber.$error"
            @blur="$v.user.documentNumber.$touch()"
          />
          <v-autocomplete
            v-model="user.documentState"
            class="tw-col-span-4"
            label="Estado do Documento"
            :items="formOptions.states"
            :no-data-text="notFoundMessage"
            append-icon
            clearable
            filled
            :error="$v.user.documentState.$error"
            @blur="$v.user.documentState.$touch()"
          />
          <v-text-field
            v-maska="masks.cpf"
            :value="mask(user.cpf, masks.cpf)"
            class="tw-col-span-4"
            label="CPF"
            clearable
            filled
            :error-messages="customCpfError"
            :error="$v.user.cpf.$error"
            @blur="$v.user.cpf.$touch()"
            @maska="user.cpf = $event.target.dataset.maskRawValue"
          />
          <v-text-field
            v-maska="masks.cnpj"
            :value="mask(user.cnpj, masks.cnpj)"
            class="tw-col-span-4"
            label="CNPJ"
            clearable
            filled
            hint="Opcional"
            persistent-hint
            :error-messages="customCnpjError"
            @maska="isCnpjValid($event.target.dataset.maskRawValue)"
          />
          <v-text-field
            v-model="user.rg"
            class="tw-col-span-4"
            filled
            label="RG"
            clearable
            :error="$v.user.rg.$error"
            @blur="$v.user.rg.$touch()"
          />
          <v-text-field
            v-model="user.nationality"
            class="tw-col-span-4"
            label="Nacionalidade"
            clearable
            filled
            :error="$v.user.nationality.$error"
            @blur="$v.user.nationality.$touch()"
          />
          <v-text-field
            v-model="user.birthDate"
            v-maska="masks.date"
            class="tw-col-span-4"
            label="Data de nascimento"
            hint="DD/MM/AAAA"
            append-icon="mdi-calendar"
            return-masked-value
            clearable
            filled
            :error-messages="$v.user.birthDate.$error ? 'Data inválida' : ''"
            :error="$v.user.birthDate.$error"
            @blur="$v.user.birthDate.$touch()"
          />
          <v-select
            v-model="user.isRqe"
            class="tw-col-span-4"
            :items="formOptions.isRqeItems"
            label="RQE"
            item-text="LABEL"
            item-value="VALUE"
            filled
            color="green"
            :error="$v.user.isRqe.$error"
            @blur="$v.user.isRqe.$touch()"
          />
          <v-autocomplete
            v-model="user.clinics"
            class="tw-col-span-6"
            :items="formattedClinics"
            label="Clínica"
            item-text="name"
            item-value="id"
            multiple
            chips
            deletable-chips
            :no-data-text="notFoundMessage"
            clearable
            filled
            :error="$v.user.clinics.$error"
            @blur="$v.user.clinics.$touch()"
          />
          <v-autocomplete
            v-model="user.specialities"
            class="tw-col-span-6"
            :items="formattedSpecialities"
            label="Especialidade"
            item-text="name"
            item-value="id"
            multiple
            chips
            deletable-chips
            :no-data-text="notFoundMessage"
            clearable
            filled
            :error="$v.user.specialities.$error"
            @blur="$v.user.specialities.$touch()"
          />
        </div>
        <v-btn
          block
          large
          color="primary"
          class="btn tw-font-bold"
          :loading="isSaving"
          :disabled="isSaving"
          @click="handleSaveUser(user)"
        >
          {{ saveButtonLabel }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { isValid as isValidCPF } from '@fnando/cpf'
import { isValid as isValidCnpj } from '@fnando/cnpj'
import {
  dateFormat,
} from '@/utils/dateFormat'
import { mapGetters, mapActions } from 'vuex'
import {
  always,
  both,
  equals,
  evolve,
  head,
  ifElse,
  keys,
  map,
  path,
  pick,
  pipe,
  prop,
  replace,
  T,
  values,
  includes,
} from 'ramda'
import {
  required,
  requiredUnless,
  requiredIf,
  minLength,
  email,
  maxLength,
} from 'vuelidate/lib/validators'
import { isDateAfter1900 } from '@/utils/dateValidators'
import isNilOrEmpty from '@/utils/dataValidators'
import userEnum from '@/enums/user'
import { mask } from 'maska'

const {
  BIOLOGICAL_SEX,
  DOCUMENT_TYPES,
  STATES,
  IS_RQE,
} = userEnum

export default {
  name: 'CreateUser',
  components: {
    CircularLoader: () => import('@/components/UI/CircularLoader'),
  },
  props: {
    editUser: Boolean,
    isOpen: {
      type: Boolean,
      required: false,
      default: true,
    },
    userData: {
      type: Object,
      required: false,
      default: () => ({
        user: {},
        professional: {},
      }),
    },
  },
  data() {
    return {
      isLoading: true,
      hasClinicalProfessional: false,
      customCpfError: null,
      customCnpjError: null,
      professional: {},
      notFoundMessage: 'Não encontrado',
      isSaving: false,
      masks: {
        cpf: '###.###.###-###',
        cnpj: '##.###.###/####-##',
        date: '##/##/####',
        telphone: ['(##) ####-####', '(##) #####-####'],
      },
      user: {
        name: '',
        email: '',
        professionId: '',
        permissionName: 'ADM',
        permissionId: null,
        biologicalSex: '',
        telphone: '',
        documentType: '',
        documentNumber: '',
        documentState: '',
        rg: '',
        cpf: '',
        birthDate: '',
        nationality: '',
        clinics: [],
        specialities: [],
        startedAtClinic: '',
        enterpriseId: null,
        isRqe: false,
      },
      formOptions: {
        biologicalSexItems: values(BIOLOGICAL_SEX),
        professions: [],
        documentsTypes: values(DOCUMENT_TYPES),
        states: values(STATES),
        isRqeItems: values(IS_RQE),
      },
    }
  },
  validations() {
    const hasSurname = value => value.split(' ').length > 1
    const dateValidation = ifElse(
      always(this.isOnlyUser()),
      T,
      both(dateFormat, isDateAfter1900),
    )
    const cpfValidation = ifElse(
      always(this.isOnlyUser()),
      T,
      isValidCPF,
    )
    const userValidations = {
      user: {
        name: {
          required,
          minLength: minLength(2),
          hasSurname,
        },
        email: {
          required,
          email,
        },
        professionId: {
          required,
        },
        permissionId: {
          required,
        },
        cpf: {
          required: requiredUnless(this.isOnlyUser),
          cpfValidation,
        },
        birthDate: {
          required: requiredUnless(this.isOnlyUser),
          minLength: minLength(10),
          dateValidation,
        },
        startedAtClinic: {
          minLength: minLength(10),
          dateValidation,
        },
        telphone: {
          required: requiredUnless(this.isOnlyUser),
          minLength: minLength(14),
          maxLength: maxLength(15),
        },
        nationality: { required: requiredUnless(this.isOnlyUser) },
        rg: { required: requiredUnless(this.isOnlyUser) },
        biologicalSex: { required: requiredUnless(this.isOnlyUser) },
        documentNumber: { required: requiredUnless(this.isOnlyUser) },
        documentType: { required: requiredUnless(this.isOnlyUser) },
        documentState: { required: requiredUnless(this.isOnlyUser) },
        clinics: { required: requiredUnless(this.isOnlyUser) },
        specialities: { required: requiredUnless(this.isOnlyUser) },
        enterpriseId: { required: requiredIf(this.isModelA) },
        isRqe: { required: requiredUnless(this.isOnlyUser) },
      },
    }
    return { ...userValidations }
  },
  computed: {
    ...mapGetters({
      enterprises: 'enterprise/enterprises',
      professions: 'profession/professions',
      clinics: 'clinic/clinics',
      userClinics: 'authentication/userClinics',
      userSpecialities: 'authentication/userSpecialities',
      specialities: 'speciality/specialities',
      permissions: 'authentication/permissions',
      authUser: 'authentication/user',
      isWorkSpaceAmparo: 'authentication/isWorkSpaceAmparo',
    }),
    formattedPermissions() {
      return this.setDisableOnItemsList(this.permissions, 'permissionId')
    },
    formattedEnterprises() {
      return this.setDisableOnItemsList(this.enterprises, 'enterpriseId')
    },
    formattedClinics() {
      return this.setDisableOnItemsList(this.clinics, 'clinicId')
    },
    formattedSpecialities() {
      return this.setDisableOnItemsList(this.specialities, 'specialityId')
    },
    saveButtonLabel() {
      return this.editUser ? 'Salvar Alterações' : 'Cadastrar Usuário'
    },
  },
  async mounted() {
    this.isLoading = true
    if (this.userData.id) {
      await this.getUserById(this.userData.id)
    }
    if (this.editUser) this.fillUser(this.userData)
    await this.loadLists()
    this.isLoading = false
  },
  deactivated() {
    this.resetFields()
  },
  methods: {
    ...mapActions({
      createUser: 'authentication/createUser',
      getProfessional: 'professional/getProfessional',
      getUserById: 'authentication/getUserById',
      listClinic: 'clinic/listClinic',
      listEnterprise: 'enterprise/listEnterprise',
      listPermission: 'authentication/listPermission',
      listProfession: 'profession/listProfession',
      listProfessional: 'professional/listProfessional',
      listSpeciality: 'speciality/listSpeciality',
      listUser: 'authentication/listUser',
      setSnackbar: 'snackbar/setSnackbar',
      updateUser: 'authentication/updateUser',
    }),
    setDisableOnItemsList(list, dataAccessName) {
      const userDataAccess = path(['permission', 'policy', 'dataAccess', dataAccessName], this.authUser)
      return map(
        item => ({
          ...item,
          disabled: !includes(item.id, userDataAccess),
        }),
        list,
      )
    },
    loadLists() {
      this.listProfession()
      this.listEnterprise({ returnAll: true })
      this.listClinic({ returnAll: true })
      this.listSpeciality({ returnAll: true })
      this.listPermission({ returnAll: true })
    },
    mask(value, maskValue) {
      return value ? mask(value, maskValue) : ''
    },
    changeClinicalProfessional() {
      this.hasClinicalProfessional = this.editUser ? true : !this.hasClinicalProfessional
    },
    isOnlyUser() {
      return !this.hasClinicalProfessional
    },
    isModelA() {
      return this.isWorkSpaceAmparo
    },
    showSnackbar(status, message) {
      this.setSnackbar({
        status,
        message,
      })
    },
    async fillUser(attributes) {
      const formatReferenceList = map(prop('id'))
      const formatDate = date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')

      this.user = evolve(
        {
          startedAtClinic: formatDate,
          birthDate: formatDate,
          clinics: formatReferenceList,
          specialities: formatReferenceList,
        },
        {
          ...(attributes.professional || {}),
          ...attributes.user,
          ...attributes,
          clinics: this.userClinics,
          specialities: this.userSpecialities,
          professionId: attributes.user.profession.id,
        },
      )

      if (attributes.professional) {
        try {
          this.professional = await this.getProfessional(attributes.professional.id)
        } catch (error) {
          this.showSnackbar('error', 'Erro ao carregar profissional.')
        }
        this.hasClinicalProfessional = true
      }
    },
    handleError(error) {
      const errorCode = path(['response', 'data', 'errorCode'], error)
      const attributeErrors = keys(path(['response', 'data', 'errors'], error) || {})
      if (equals(errorCode, 'instance_already_exists_but_is_deleted')) {
        this.showSnackbar('error', 'Este usuário está inativado')
      }
      if (equals(errorCode, 'instance_already_exists')) {
        const attribute = head(attributeErrors)
        this.showSnackbar('error', `O ${attribute} já está em uso.`)
      }
      if (equals(errorCode, 'profession_invalid')) {
        this.showSnackbar('error', 'Usuários clínicos necessitam de profissões clínicas')
      }
      if (includes(errorCode, [
        'invalid_permission_id',
        'invalid_clinic_id',
        'invalid_speciality_id',
        'invalid_enterprise_id',
      ])) {
        this.showSnackbar('error', 'Não foi possível concluir a solicitação, verifique suas permissões')
      }
    },
    formatUserAttributes(attributes) {
      const userWorkSpace = {
        permissionId: attributes.permissionId,
        user: pick([
          'name',
          'email',
          'professionId',
          'enterpriseId',
        ], attributes),
      }
      if (this.hasClinicalProfessional) {
        const onlyNumber = (value = '') => replace(/[\D]/g, '', value)
        const formatDate = date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const formatCnpj = value => (isNilOrEmpty(value) ? null : value)
        userWorkSpace.professional = pipe(
          pick([
            'name',
            'biologicalSex',
            'telphone',
            'documentType',
            'documentNumber',
            'documentState',
            'cpf',
            'cnpj',
            'rg',
            'birthDate',
            'nationality',
            'email',
            'startedAtClinic',
            'clinics',
            'specialities',
            'professionId',
            'enterpriseId',
            'isRqe',
          ]),
          evolve({
            telphone: onlyNumber,
            startedAtClinic: formatDate,
            birthDate: formatDate,
            cnpj: formatCnpj,
          }),
        )(attributes)
      }

      return userWorkSpace
    },
    async areFieldsInvalid() {
      await this.$v.$touch()
      if (this.$v.$error) {
        const msg = 'Verifique os campos em vermelho.'
        this.showSnackbar('error', msg)
        this.isSaving = false
        return true
      }
      return false
    },
    async handleSaveUser(attributes) {
      this.isSaving = true
      const user = this.formatUserAttributes(attributes)
      if (await this.areFieldsInvalid()) return

      try {
        if (this.editUser) {
          await this.updateUser({
            id: this.userData.user.id,
            attributes: user,
          })

          this.showSnackbar('success', 'Usuário editado com sucesso!')
        } else {
          await this.createUser(user)
          this.showSnackbar('success', 'Usuário cadastrado com sucesso!')
        }

        this.$emit('closeDialog')
        this.listUser()
      } catch (error) {
        this.handleError(error)
      } finally {
        this.isSaving = false
      }
    },
    showErrorSnackBar() {
      const message = 'Erro ao cadastrar usuário.'
      this.showSnackbar('error', message)
      this.isSaving = false
    },
    resetFields() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.$v.$reset()
    },
    async isCnpjValid(cnpj) {
      this.user.cnpj = cnpj
      this.customCnpjError = ''
      if (isNilOrEmpty(cnpj)) return
      if (!isValidCnpj(cnpj)) {
        this.customCnpjError = 'CNPJ inválido'
        return
      }
      if (await this.isProfessionalCnpjInUse(cnpj)) {
        this.customCnpjError = 'CNPJ já cadastrado'
      }
    },
    async isProfessionalCnpjInUse(cnpj) {
      const professional = head(await this.listProfessional({ cnpj }))
      if (!prop('id', professional)) return false
      if (equals(this.user.cpf, professional.cpf)) return false
      return true
    },
  },
}
</script>
